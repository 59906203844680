import { useMemo } from "react";
import linkResolver from "../../prismic/linkResolver";
import { NavTypes } from "./menuRenderHelpers";

export const useHeaderData = headerData => {
  const services = useMemo(
    () => ({
      id: "services-list",
      label: headerData.services_title || "Our Services",
      type: NavTypes.dropdown,
      children: headerData.services.map((item, i) => ({
        id: `services-link-${i}`,
        label: item.label,
        type: NavTypes.dropdownTextLink,
        url: item.link.url,
        externalLink: Boolean(item.link.target),
        category: "Our Services",
      })),
    }),
    [headerData.services, headerData.services_title]
  );

  const team = useMemo(
    () => ({
      id: "meet-the-team",
      label: headerData.meet_the_team_title || "Meet the Team",
      type: NavTypes.dropdown,
      children: headerData.meet_the_team.map((item, i) => ({
        id: `team-link-${i}`,
        label: item.label,
        type: NavTypes.dropdownTextLink,
        url: linkResolver(item.link),
        externalLink: Boolean(item.link.target),
        category: "directory",
      })),
    }),
    [headerData.meet_the_team, headerData.meet_the_team_title]
  );

  const locations = useMemo(
    () => ({
      id: "locations-dropdown",
      label: headerData.location_title || "Locations",
      type: NavTypes.dropdown,
      seeMoreUrl: headerData.all_locations_link?.url,
      children: headerData.locations.map((item, i) => ({
        id: `locations-link-${i}`,
        label: item.label,
        type: NavTypes.dropdownTextLink,
        url: linkResolver(item.link),
        externalLink: Boolean(item.link.target),
        category: "Find a Therapist",
      })),
    }),
    [
      headerData.locations,
      headerData.location_title,
      headerData.all_locations_link?.url,
    ]
  );

  const remainingLinks = useMemo(
    () =>
      headerData.nav_links.map((item, i) => ({
        id: `nav-link-${i}`,
        label: item.label,
        type: NavTypes.textLink,
        url: linkResolver(item.link),
        externalLink: Boolean(item.link.target),
        category: item.label,
      })),
    [headerData.nav_links]
  );

  const NavLinksModel = [services, team, ...remainingLinks, locations];

  return NavLinksModel;
};
