import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Button from "../Button/Button";
import Reveal from "../Reveal/Reveal";
import styles from "./split-ctas.module.scss";

const SplitCtas = ({ ctas }) => (
  <div className={styles.root}>
    <div className={styles.inner}>
      {ctas.map((column, index) => {
        const { items } = column;
        const { cta_body, cta_heading } = column.primary;
        return (
          <Reveal className={styles.section} key={`section-${index}`}>
            <div className={styles.content}>
              {cta_heading && <h3>{cta_heading.text}</h3>}
              {cta_body && (
                <div dangerouslySetInnerHTML={{ __html: cta_body.html }} />
              )}
              {items.map((item, itemIndex) => {
                const { cta_link, cta_text } = item;
                return (
                  <Button
                    text={cta_text}
                    url={`${cta_link.url}`}
                    target={cta_link.target}
                    key={`cta-${itemIndex}`}
                  />
                );
              })}
            </div>
          </Reveal>
        );
      })}
    </div>
  </div>
);

SplitCtas.propTypes = {
  ctas: PropTypes.array.isRequired,
};

export default SplitCtas;

// Prismic "Section Split CTA" slice.
export const fragment = graphql`
  fragment SliceCtaBlock on PrismicPageContainerDataBodyCtaBlock {
    slice_type
    primary {
      cta_heading {
        text
      }
      cta_body {
        html
      }
    }
    items {
      cta_link {
        url
        target
      }
      cta_text
    }
  }
`;
