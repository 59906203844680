import React from "react";
import PropTypes from "prop-types";
import GoogleMapReact from "google-map-react";

const apiKey = process.env.GATSBY_GOOGLE_MAPS_API_KEY;

// NOTE: Must create a custom marker component
const CustomMarker = () => (
  <img src="/favicon-32x32.png" height="30" width="30" alt="" />
);

const GoogleMap = ({ locations }) => {
  const defaultCenter = {
    lat: locations[0].latitude,
    lng: locations[0].longitude,
  };

  return (
    // NOTE: Always set the container height explicitly
    <div style={{ height: "480px", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: apiKey }}
        defaultCenter={defaultCenter}
        defaultZoom={15}
        layerTypes={["TransitLayer"]}
        options={{
          gestureHandling: "none",
          zoomControl: false,
        }}
      >
        {locations &&
          locations.map((loc, i) => (
            <CustomMarker lat={loc.latitude} lng={loc.longitude} key={i} />
          ))}
      </GoogleMapReact>
    </div>
  );
};

GoogleMap.propTypes = {
  locations: PropTypes.array.isRequired,
};

export default GoogleMap;
