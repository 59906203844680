import React, { useEffect, useMemo, useState } from "react";
import { PrismicRichText } from "@prismicio/react";
import { Link } from "gatsby";
import Image from "../Image/Image";
import SlideController from "../SlideController/SlideController";
import SlideIndicators from "../SlideIndicators/SlideIndicators";
import styles from "./providers-alt.module.scss";

// Used to splice n amount of providers from full array
const NUM_OF_PROVIDERS_SHOWING = 5;

const ProvidersDisplay = ({ shuffledProviders }) => {
  const [slide, setSlide] = useState(0);
  const updateSlide = slide => setSlide(slide);

  const [providers, setProviders] = useState([]);

  // To avoid running this slice function multiple times, we useMemo.
  const slicedProvidersArr = useMemo(
    () => providers.slice(0, NUM_OF_PROVIDERS_SHOWING),
    [providers]
  );

  // TODO: Figure out root cause of incorrect providers displaying.
  // setTimeout was used as a hack to what seemed to be a hydration issue.
  // Everytime the page loads, the wrong array of providers shows briefly,
  // and the wrong image ends up staying. With the below, we force a rerender,
  // to ensure final data is clean and showing the correct shuffled providers.
  // Please note: shuffledProviders data is logging the correct data,
  // but somehow is bringing in the wrong data on first load/mount.
  useEffect(() => {
    setTimeout(() => {
      const providersToShow = shuffledProviders || [];
      setProviders(providersToShow);
    }, 0);
  }, [shuffledProviders]);

  return (
    <SlideController
      onUpdate={updateSlide}
      currentSlide={slide}
      count={NUM_OF_PROVIDERS_SHOWING}
      className={styles.providers_wrapper}
    >
      <div>
        <div className={styles.providers}>
          {slicedProvidersArr?.map((provider, index) => {
            const profileData = provider.node.data;
            const profilePhoto = profileData.profile_photo;
            const location = `${profileData.office_locations[0].name}, ${profileData.state_location}`;

            return (
              <Link
                key={index}
                to={`/providers/${provider.node.uid}`}
                className={`${styles.provider} ${
                  slide === index ? styles.active : ""
                }`}
              >
                <Image
                  imageObj={profilePhoto}
                  className={styles.provider_img}
                />
                <div className={styles.provider_details}>
                  <h1 className={styles.provider_name}>
                    {profileData.name.text}
                  </h1>
                  <div className={styles.provider_location}>{location}</div>
                  {profileData.quote.text && (
                    <p className={styles.provider_quote}>
                      &ldquo;{profileData.quote.text}&rdquo;
                    </p>
                  )}
                </div>
              </Link>
            );
          })}
        </div>
        <div className={styles.indicators}>
          <SlideIndicators
            currentSlide={slide}
            count={NUM_OF_PROVIDERS_SHOWING}
            updateSlide={updateSlide}
          />
        </div>
      </div>
    </SlideController>
  );
};

export default ProvidersDisplay;
