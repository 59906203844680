import React, { useEffect, useState } from "react";
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import Button from "../Button/Button";
import ParallaxImages from "../ParallaxImages/ParallaxImages";
import Reveal from "../Reveal/Reveal";
import SlideController from "../SlideController/SlideController";
import SlideIndicators from "../SlideIndicators/SlideIndicators";
import styles from "./home-details.module.scss";

const HomeDetails = ({ stacked_image_back, stacked_image_front, items }) => {
  const [slide, setSlide] = useState(0);
  const [height, setHeight] = useState(90);

  useEffect(() => {
    // TODO: need to investigate the purpose of this line
    // window.removeEventListener("resize", resize)
    window.addEventListener("resize", resize);
    resize();
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  const resize = () => {
    // Set height of the slider
    const slides = document.querySelectorAll(`.${styles.content}`);
    let height = 0;
    slides.forEach(slide => {
      const slideheight = slide.getBoundingClientRect().height;
      if (slideheight > height) {
        height = slideheight;
      }
    });
    setHeight(height + 60);
  };

  const updateSlide = slide => setSlide(slide);
  const images = [stacked_image_back, stacked_image_front];

  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <SlideController
          onUpdate={updateSlide}
          currentSlide={slide}
          count={items.length}
        >
          <div>
            <div className={styles.slide}>
              <div className={styles.slideImage}>
                <Reveal className={styles.images}>
                  <ParallaxImages images={images} />
                </Reveal>
              </div>
              <div className={styles.slideContent} style={{ height }}>
                {items.map((text, i) => {
                  const { cta_text, cta_link, detail_text, detail_title } =
                    text;
                  return (
                    <div
                      className={`${styles.content} ${
                        slide === i && styles.active
                      }`}
                      key={i}
                    >
                      <div className={styles.copy}>
                        <h3>{detail_title.text}</h3>
                        <div
                          dangerouslySetInnerHTML={{ __html: detail_text.html }}
                        />
                        <Button text={cta_text} url={`${cta_link.url}`} />
                      </div>
                    </div>
                  );
                })}
                <div className={styles.indicators}>
                  <SlideIndicators
                    currentSlide={slide}
                    count={items.length}
                    updateSlide={updateSlide}
                  />
                </div>
                {items.map((text, i) => {
                  const { detail_title, cta_link } = text;
                  const title = detail_title.text;

                  return (
                    <div
                      className={`${styles.learnMore} ${
                        slide === i && styles.active
                      }`}
                      key={title}
                    >
                      <Link to={cta_link.url}>{`Learn About ${title}`}</Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </SlideController>
      </div>
    </div>
  );
};

HomeDetails.propTypes = {
  image_collage: PropTypes.array,
  text_carousel: PropTypes.array,
};

HomeDetails.defaultProps = {
  image_collage: [],
  text_carousel: [],
};

export default HomeDetails;

// Prismic "Section Detail" slice
export const fragment = graphql`
  fragment SliceDetail on PrismicPageContainerDataBodySectionDetailCarousel {
    slice_type
    slice_label
    primary {
      stacked_image_back {
        url
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
      stacked_image_front {
        url
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    items {
      detail_title {
        text
      }
      detail_text {
        html
      }
      cta_text
      cta_link {
        url
      }
    }
  }
`;
