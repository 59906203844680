import React from "react";
import { graphql, PageProps } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import get from "lodash/get";
import GroupDetail from "../components/GroupDetail/GroupDetail";
import Layout from "../components/Layout/Layout";
import SeoUtil from "../components/SeoUtil/SeoUtil";
import { getMetadata } from "../prismic/util";

// settings
const backgroundColor = "#FAF9F0";

const GroupTemplate = ({ data }: PageProps<Queries.GroupPageQuery>) => {
  const groupData = get(data, "prismicGroup.data");
  const groupLeadersData = get(data, "allAirtable.edges");  

  const meta_title = groupData.meta_title.text || groupData.heading;
  const meta_description =
    groupData.meta_description || groupData.description?.text;
  const metadata = getMetadata(groupData, meta_title, meta_description);

  return (
    <Layout pageUid="post" backgroundColor={backgroundColor}>
      <SeoUtil {...metadata} />
      <GroupDetail groupData={groupData} groupLeadersData={groupLeadersData}/>
    </Layout>
  );
};

export default withPrismicPreview(GroupTemplate);

export const query = graphql`
  query GroupPage($groupId: String, $group_leaders: [String]) {
    allAirtable(
      filter: {
        data: {
          Status: { eq: "Active" }
          Publish_to_Website: { eq: true }
          Green_Light_Done: { eq: "checked" }
          NPI_Number: { in: $group_leaders }
        }
      }
      sort: { data: { Last_Name: ASC } }
    ) {
      edges {
        node {
          ...ProviderDetailsDef
        }
      }
    }
    prismicGroup(uid: { eq: $groupId }) {
      prismicId
      uid
      _previewable
      data {
        meta_title {
          text
        }
        meta_description
        og_image_overwrite {
          url
        }
        is_active
        heading {
          text
        }
        description {
          html
          text
        }
        group_type
        location_city
        location_text {
          text
          html
        }
        category
        category_short
        schedule_text
        duration_text
        price_text
        start_date
        enroll_link_url {
          url
          target
        }
        thumbnails {
          group_thumb {
            gatsbyImageData(width: 500, layout: CONSTRAINED)
            localFile {
              childImageSharp {
                gatsbyImageData(width: 500, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`;
