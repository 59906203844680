import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import * as Select from "@radix-ui/react-select";
import DropdownArrowIcon from "../../../../images/select_dropdown_arrow.inline.svg";
import { InputStyling } from "../InputStyling/InputStyling";
import { ValidationIcon } from "../ValidationIcon/ValidationIcon";
import { ValidationText } from "../ValidationText/ValidationText";
import { SelectContent } from "./SelectContent";
import { useInputValidation, useToggleInputLabel } from "../../../../lib/hooks";
import styles from "./select-input.module.scss";

/**
 * To use SelectInput in form, wrap this input in a Controller component
 * via react-hook-forms. Refer to ContactForm for example. This is required
 * to pass form props.
 */
export const SelectInput = forwardRef(
  (
    {
      variant,
      label,
      showLabel,
      name,
      data,
      disableFirstOption,
      onChange,
      onBlur,
      formBag,
      ...props
    },
    ref
  ) => {
    const isFormVariant = variant === "form";

    const [isFocused, setIsFocused] = useState(false);

    // Use 'hideLabel' variable in InputStyling component
    // if you want component to have the label displayed
    const { hideLabel, setHideLabel } = useToggleInputLabel();

    const { errorMessage, isTouched, isValid, validityStyles, focusStyles } =
      useInputValidation(variant, name, formBag, isFocused);

    const hasValue =
      (!isFormVariant && Boolean(props.value)) ||
      (isFormVariant && Boolean(formBag?.getValues(name)));

    return (
      <div className={styles.root}>
        <div className={styles.select_group}>
          <Select.Root
            defaultValue=""
            name={name}
            onValueChange={onChange}
            onOpenChange={open => {
              setHideLabel(open);
            }}
            autoComplete="off"
          >
            <Select.Trigger
              aria-label={label}
              className={`${styles.select_trigger} ${
                isFormVariant
                  ? styles[validityStyles]
                  : hasValue
                  ? styles.valid
                  : ""
              }`}
              onBlur={e => {
                if (onBlur) onBlur(e);

                if (!hasValue) {
                  setHideLabel(true);
                  setIsFocused(false);
                }
              }}
              onFocus={() => {
                if (!hasValue) {
                  setIsFocused(true);
                }
              }}
              ref={ref}
            >
              <Select.Value />
              <Select.Icon>
                <DropdownArrowIcon />
              </Select.Icon>

              <InputStyling
                label={label}
                validityStyles={
                  isFormVariant ? validityStyles : hasValue ? "valid" : ""
                }
                focusStyles={focusStyles}
                hideLabel={!showLabel ? true : !hasValue && hideLabel}
              />
              {isFormVariant && (
                <ValidationIcon
                  isValid={isValid}
                  isTouched={isTouched}
                  hasDropdown
                />
              )}
            </Select.Trigger>

            <SelectContent>
              <Select.ScrollUpButton
                className={`${styles.select_scroll_btn} ${styles.select_scroll_btn__up}`}
              >
                <DropdownArrowIcon />
              </Select.ScrollUpButton>
              <Select.Viewport className={styles.select_viewport}>
                {data.map(({ label: field_label, value }, index) => {
                  const isFirstItem = index === 0;
                  const firstItemProps = {
                    disabled: true,
                    unselectable: "on",
                  };
                  const isSelected = props.fields?.value === value;
                  const isSelectedClassName = isSelected ? styles.selected : "";

                  return (
                    <Select.Item
                      key={value}
                      value={value}
                      className={`${styles.select_item} ${isSelectedClassName}`}
                      {...(isFirstItem & disableFirstOption
                        ? firstItemProps
                        : "")}
                    >
                      <Select.ItemText>{field_label}</Select.ItemText>
                      <Select.ItemIndicator
                        className={styles.select_indicator}
                      />
                    </Select.Item>
                  );
                })}
              </Select.Viewport>
              <Select.ScrollDownButton className={styles.select_scroll_btn}>
                <DropdownArrowIcon />
              </Select.ScrollDownButton>
            </SelectContent>
          </Select.Root>
        </div>
        {isFormVariant && <ValidationText errorMessage={errorMessage} />}
      </div>
    );
  }
);

SelectInput.propTypes = {
  variant: PropTypes.oneOf(["default", "form"]),
  label: PropTypes.string.isRequired,
  showLabel: PropTypes.bool,
  name: PropTypes.string.isRequired,
  formBag: PropTypes.shape({
    getValues: PropTypes.func.isRequired, // used for 'form' variant
    errors: PropTypes.object, // used for 'form' variant
    touchedFields: PropTypes.object, // used for 'form' variant
  }),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  disableFirstOption: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

SelectInput.defaultProps = {
  variant: "default",
  label: "",
  showLabel: true,
  name: "",
  formBag: {
    getValues: () => {},
    errors: {},
    touchedFields: {},
  },
  onChange: () => {},
  onBlur: () => {},
  disableFirstOption: true,
  data: [],
};
