import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";
import { get } from "lodash";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

function SeoUtil({ meta_title, meta_description, redirectUrl, ogImage }) {
  const staticData = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            language
            siteUrl
          }
        }
      }
    `
  );

  const link = [
    {
      rel: "preload",
      href: "/fonts/apercu-light.woff2",
      as: "font",
      type: "font/woff2",
      crossOrigin: "anonymous",
    },
    {
      rel: "preload",
      href: "/fonts/apercu-medium-pro.woff2",
      as: "font",
      type: "font/woff2",
      crossOrigin: "anonymous",
    },
    {
      rel: "preload",
      href: "/fonts/apercu-regular-pro.woff2",
      as: "font",
      type: "font/woff2",
      crossOrigin: "anonymous",
    },
    {
      rel: "preload",
      href: "/fonts/esteban.woff2",
      as: "font",
      type: "font/woff2",
      crossOrigin: "anonymous",
    },
  ];

  const { site } = useMergePrismicPreviewData(staticData);

  const author = get(site, "siteMetadata.author", "");
  const lang = get(site, "sitemetadata.language", "en");

  const title =
    meta_title && typeof meta_title === "string" ? meta_title : meta_title.text;

  // Error handling catch all for title and description
  const metaTitle = title || get(site, "siteMetadata.title", "");
  const metaDescription =
    meta_description || get(site, "siteMetadata.description", "");

  let _meta = [
    {
      name: `viewport`,
      content: "initial-scale=1, width=device-width",
    },
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: metaTitle,
    },
    {
      property: `og:image`,
      content:
        ogImage ||
        `${site?.siteMetadata.siteUrl}/images/octave-default-share.png`,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
    {
      name: `twitter:creator`,
      content: author,
    },
    {
      name: `twitter:title`,
      content: metaTitle,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    // Manually set Safari 15+ tab color-theme
    // https://developer.apple.com/forums/thread/683403
    {
      name: "theme-color",
      content: "#fff",
    },
  ];
  if (redirectUrl) {
    _meta.push({
      "http-equiv": "refresh",
      content: `0;url=${redirectUrl}`,
    });
  }

  return (
    <Helmet
      defer={false}
      htmlAttributes={{ lang }}
      title={metaTitle}
      meta={_meta}
      link={link}
    />
  );
}

SeoUtil.defaultProps = {
  meta_title: "",
  meta_description: "",
  href: "",
  redirectUrl: null,
  ogImage: null,
};

SeoUtil.propTypes = {
  meta_title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  meta_description: PropTypes.string,
  href: PropTypes.string,
  redirectUrl: PropTypes.string,
  ogImage: PropTypes.string,
};

export default SeoUtil;

export const fragmentPage = graphql`
  fragment MetadataDef on PrismicPageContainerData {
    meta_title {
      text
    }
    meta_description
    og_image_overwrite {
      url
    }
  }
`;
