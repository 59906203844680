import React from "react";
import Button from "../../components/Button/Button";
import CarouselInterestedList from "../../components/CarouselInterestedList/CarouselInterestedList";
import GroupDetailContent from "../../components/GroupDetailContent/GroupDetailContent";
import GroupDetailInfo from "../../components/GroupDetailInfo/GroupDetailInfo";
import GroupDetailLeadersList from "../../components/GroupDetailLeadersList/GroupDetailLeadersList";
import styles from "./group-detail.module.scss";

const GroupDetail = ({ groupData, groupLeadersData }) => {
  const { enroll_link_url, group_type } = groupData;

  let enrollButtonText = "Schedule a Consultation";
  if (group_type === "Workshop") {
    enrollButtonText = "Enroll";
  }
  groupData["enroll_button_text"] = enrollButtonText;

  return (
    <div className={styles.root}>
      <GroupDetailInfo {...groupData} />
      <div className={styles.content}>
        <GroupDetailContent {...groupData} />
        <GroupDetailLeadersList groupLeadersData={groupLeadersData} />
      </div>
      <div className={styles.ctaBtn}>
        <Button
          text={enrollButtonText}
          url={enroll_link_url?.url}
          target={enroll_link_url?.target}
        />
      </div>
      <CarouselInterestedList {...groupData} />
    </div>
  );
};

export default GroupDetail;
