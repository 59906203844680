import { ProvidersWithAvailability } from "../types";
import { useMemo } from "react";

export const useSortProvidersByAvailability = (
  providers: ProvidersWithAvailability[]
) => {
  const { available, unavailable } = useMemo(() => {
    return providers.reduce(
      (acc, provider) => {
        const isAvailable =
          provider.node.data?.availabilityInfo?.availability.length > 0;
        if (isAvailable) {
          acc.available.push(provider);
        } else {
          acc.unavailable.push(provider);
        }
        return acc;
      },
      { available: [], unavailable: [] }
    );
  }, [providers]);

  return { available, unavailable };
};
