import React from "react";
import PropTypes from "prop-types";
import AnchorSection from "../AnchorSection/AnchorSection";
import ArticleFooter from "../ArticleFooter/ArticleFooter";
import ArticleHeader from "../ArticleHeader/ArticleHeader";
import ArticleImages from "../ArticleImages/ArticleImages";
import ArticleResources from "../ArticleResources/ArticleResources";
import ArticleSectionFull from "../ArticleSectionFull/ArticleSectionFull";
import Layout from "../Layout/Layout";
import { flattenSlice } from "../../prismic/util";
import SeoUtil from "../SeoUtil/SeoUtil";
import styles from "./article.module.scss";

// settings
const backgroundColor = "#FAF9F0";

const Article = ({ renderData, metadata, pdfUrlPath }) => {
  const pdfLink = pdfUrlPath;

  const ArticleSlices = () => {
    return (
      <>
        {renderData.body &&
          renderData.body.map((slice, index) => {
            const sliceFields = flattenSlice(slice);
            const { slice_type } = slice;
            switch (slice_type) {
              case "article_header":
                return (
                  <ArticleHeader
                    {...sliceFields}
                    pdfLink={pdfLink}
                    key={index}
                  />
                );
              case "article_section_full":
                return <ArticleSectionFull {...sliceFields} key={index} />;
              case "article_image_columns":
                return <ArticleImages {...sliceFields} key={index} />;
              case "resource_list":
                return (
                  <ArticleResources
                    {...sliceFields}
                    pdfLink={pdfLink}
                    key={index}
                  />
                );
              case "article_footer":
                return <ArticleFooter {...sliceFields} key={index} />;
              case "section_anchor":
                return <AnchorSection {...sliceFields} key={index} />;
              default:
                return true;
            }
          })}
      </>
    );
  };

  return (
    <Layout page="article" backgroundColor={backgroundColor}>
      <SeoUtil {...metadata} />
      <div className={styles.root}>
        <ArticleSlices />
      </div>
    </Layout>
  );
};

Article.propTypes = {
  renderData: PropTypes.object,
  metadata: PropTypes.object,
  pdfUrlPath: PropTypes.string,
};

export default Article;
