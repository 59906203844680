import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import useIntersectionObserver from "../../lib/hooks/useIntersectionObserver";
import styles from "./reveal.module.scss";

const PERCENT_SHOWN = 0.15; // percent shown to reveal

const Reveal = ({ className, freezeOnceVisible, threshold, children }) => {
  const ref = useRef(null);
  const entry = useIntersectionObserver(ref, {
    threshold,
    freezeOnceVisible,
  });
  const isVisible = !!entry?.isIntersecting;

  const [style, setStyle] = useState({
    opacity: 0,
    transform: "translateY(20px)",
  });

  useEffect(() => {
    if (isVisible) {
      setStyle({
        opacity: 1,
        transform: "translateY(0)",
      });
    } else {
      setStyle({
        opacity: 0,
        transform: "translateY(20px)",
      });
    }
  }, [isVisible]);

  return (
    <div className={`${styles.root} ${className}`} style={style} ref={ref}>
      {children}
    </div>
  );
};

Reveal.propTypes = {
  className: PropTypes.string,
  threshold: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

Reveal.defaultProps = {
  className: "",
  threshold: PERCENT_SHOWN,
  freezeOnceVisible: true,
};

export default Reveal;
