import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { stripHtml } from "string-strip-html";
import AnimatedLine from "../AnimatedLine/AnimatedLine";
import Button from "../Button/Button";
import GoogleMap from "../GoogleMap/GoogleMap";
import Reveal from "../Reveal/Reveal";
import SplitBlockDefault from "../SplitBlockDefault/SplitBlockDefault";
import { SUPPORT_EMAIL } from "../../lib/constants";
import styles from "./location-details.module.scss";

const LocationDetails = ({
  body,
  thumbnail_tagline,
  contact,
  hours,
  transit,
  address,
  geo,
}) => {
  // Store in CMS?
  const heading = "Where To Find Us";

  const directionsText = "Get Directions";
  // See https://developers.google.com/maps/documentation/urls/get-started#directions-action
  const googBase = `https://www.google.com/maps/dir/`;
  // We need to use address.html and strip tags because
  // address.text removes <br> tags, collapsing whitespace.
  const inlineAddress = stripHtml(address.html).result;
  const encodedAddress = encodeURIComponent(inlineAddress);
  const directionsUrl = `${googBase}?api=1&destination=${encodedAddress}&travelmode=transit`;

  const hasTransit = transit.html && Boolean(stripHtml(transit.html).result);

  const contactText = "Contact Us";
  const contactUrl = `mailto:${SUPPORT_EMAIL}`;

  // Used as a "Coming soon" alternative
  // Connected to slice in locations template
  if (body.length) {
    const sliceProps = {
      section_text: body[0].primary.section_text,
      image_collage: body[0].items,
      is_inverted: true,
    };

    return <SplitBlockDefault {...sliceProps} />;
  }

  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <Reveal className={styles.header}>
          <h2>{heading}</h2>
        </Reveal>
        <div className={styles.sections}>
          <Reveal className={styles.sectionCallout}>
            {thumbnail_tagline?.text && (
              <p className={styles.callout}>{thumbnail_tagline.text}</p>
            )}
            <div className={styles.lineWrapper}>
              <AnimatedLine
                linePath={"M 0 0 Q 0 80, 80 80 Q 160 80, 160 160"}
                lineLength={160 * 1.6}
                height={162}
                width={162}
              />
            </div>
          </Reveal>
          <Reveal className={styles.sectionDetails}>
            {address?.text && (
              <div className={styles.details}>
                <h4>Address</h4>
                <div dangerouslySetInnerHTML={{ __html: address.html }} />
              </div>
            )}
            {hasTransit && (
              <div className={styles.details}>
                <h4>Public Transportation</h4>
                <div dangerouslySetInnerHTML={{ __html: transit.html }} />
              </div>
            )}
            {(geo?.latitude || geo?.longitude) && (
              <Button
                text={directionsText}
                url={directionsUrl}
                target="_blank"
                style={{ marginTop: "auto" }}
              />
            )}
          </Reveal>
          <Reveal className={styles.sectionDetails}>
            {hours?.text && (
              <div className={styles.details}>
                <h4>Hours</h4>
                <div dangerouslySetInnerHTML={{ __html: hours.html }} />
              </div>
            )}
            {contact?.text && (
              <>
                <div className={styles.details}>
                  <h4>Contact</h4>
                  <div dangerouslySetInnerHTML={{ __html: contact.html }} />
                </div>
                <Button
                  text={contactText}
                  url={contactUrl}
                  style={{ marginTop: "auto" }}
                />
              </>
            )}
          </Reveal>
        </div>
        {(geo?.latitude || geo?.longitude) && (
          <Reveal className={styles.mapWrapper}>
            <div className={styles.map}>
              <GoogleMap locations={[geo]} />
            </div>
          </Reveal>
        )}
      </div>
    </div>
  );
};

LocationDetails.propTypes = {
  thumbnail_tagline: PropTypes.object,
  contact: PropTypes.object,
  hours: PropTypes.object,
  transit: PropTypes.object,
  address: PropTypes.object,
  geo: PropTypes.object,
};

LocationDetails.defaultProps = {
  thumbnail_tagline: null,
  contact: null,
  hours: null,
  transit: null,
  address: null,
  geo: null,
};

export default LocationDetails;

export const query = graphql`
  fragment LocationDetailsDef on PrismicOfficeLocation {
    uid
    prismicId
    _previewable
    data {
      meta_title {
        text
      }
      meta_description
      og_image_overwrite {
        url
      }
      office
      show_contact_form
      address {
        text
        html
        richText
      }
      contact {
        text
        html
        richText
      }
      description {
        html
        richText
        text
      }
      footnote {
        richText
        text
        html
      }
      geo {
        latitude
        longitude
      }
      hours {
        text
        richText
        html
      }
      transit {
        text
        richText
        html
      }
      state {
        uid
        document {
          ... on PrismicStateLocation {
            id
            data {
              name
            }
          }
        }
      }
      thumbnail_tagline {
        text
      }
      thumbnail_image {
        url
      }
      photo_carousel {
        photo {
          url
          gatsbyImageData(width: 480, layout: CONSTRAINED)
          localFile {
            childImageSharp {
              gatsbyImageData(width: 480, layout: CONSTRAINED)
            }
          }
        }
      }
      body {
        ... on PrismicOfficeLocationDataBodySectionIframe {
          ...OfficeLocationSliceIframe
        }
        ... on PrismicOfficeLocationDataBodySectionHowItWorks {
          ...OfficeLocationStateDetailSliceNumbered
        }
        ... on PrismicOfficeLocationDataBodySectionAnchor {
          ...OfficeLocationSliceAnchor
        }
        ... on PrismicOfficeLocationDataBodySectionSplit {
          slice_type
          slice_label
          primary {
            section_text {
              html
            }
            title
          }
          items {
            split_panel_image {
              url
              gatsbyImageData(height: 800, layout: CONSTRAINED)
              localFile {
                childImageSharp {
                  gatsbyImageData(height: 800, layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
  }
`;
