import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import ButtonPdf from "../ButtonPdf/ButtonPdf";
import Image from "../Image/Image";
import styles from "./article-resources.module.scss";

const ArticleResources = ({ resource_text, items, pdfLink }) => {
  return (
    <div className={styles.root}>
      <div className={styles.resourceTextContain}>
        <h4>Resources</h4>
        <div
          className={styles.resourceText}
          dangerouslySetInnerHTML={{ __html: resource_text.html }}
        />
        {pdfLink && <ButtonPdf url={pdfLink} />}
      </div>

      {items[0] && items[0].resource_item && (
        <div className={styles.resourceListSection}>
          <h2>Other resources you might like</h2>

          {items.map((r, index) => (
            <div
              className={styles.resourceListContain}
              key={`resource-${index}`}
            >
              <div className={styles.resourceThumbnail}>
                <Image imageObj={r.resource_item.document.data.thumbnail} />
              </div>
              <div className={styles.resourceExcerpt}>
                <h4>
                  {
                    r.resource_item.document.data.body[0].primary.article_title
                      .text
                  }
                </h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: r.resource_item.document.data.excerpt.html,
                  }}
                />
                <p>
                  <Link
                    to={`/provider-resources/${r.resource_item.document.uid}`}
                  >
                    Learn more
                  </Link>
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

ArticleResources.propTypes = {
  resource_text: PropTypes.object,
  items: PropTypes.array,
  pdfLink: PropTypes.string,
};

ArticleResources.defaultProps = {
  resource_text: null,
  items: null,
  pdfLink: null,
};

export default ArticleResources;
