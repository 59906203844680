import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Aside from "../Aside/Aside";
import Button from "../Button/Button";
import Reveal from "../Reveal/Reveal";
import styles from "./condensed-hero.module.scss";

const CondensedHero = ({
  page_heading,
  heading_subtext,
  cta_link,
  cta_text,
  cta_subtext,
  icon,
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <Reveal className={styles.blockCopy}>
          {page_heading && <h1>{page_heading.text}</h1>}
          {heading_subtext && (
            <div dangerouslySetInnerHTML={{ __html: heading_subtext.html }} />
          )}
        </Reveal>
        <div className={styles.blockCta}>
          <Reveal className={styles.ctaWrapper}>
            {cta_link && cta_text && (
              <Button text={cta_text} url={`${cta_link.url}`} />
            )}
            {cta_subtext && <Aside icon={icon}>{cta_subtext}</Aside>}
          </Reveal>
        </div>
      </div>
    </div>
  );
};

CondensedHero.propTypes = {
  page_heading: PropTypes.object,
  heading_subtext: PropTypes.object,
  cta_link: PropTypes.object,
  cta_text: PropTypes.string,
  cta_subtext: PropTypes.object,
  icon: PropTypes.string,
};

CondensedHero.defaultProps = {
  page_heading: null,
  heading_subtext: null,
  cta_link: null,
  cta_text: null,
  cta_subtext: null,
  icon: null,
};

export default CondensedHero;

// Prismic "Section Hero Condensed" slice
export const fragment = graphql`
  fragment SliceHeroCondensed on PrismicPageContainerDataBodySectionHeroCondensed {
    slice_type
    slice_label
    primary {
      page_heading {
        text
      }
      heading_subtext {
        html
      }
      cta_text
      cta_link {
        url
      }
      cta_subtext {
        text
        html
      }
    }
  }
`;
