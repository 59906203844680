import { useMemo } from "react";
import { orderBy, drop, take } from "lodash";
import { FILTER_MENU_NUM_OPTIONS_COLLAPSED } from "../../../lib/constants";

/**
 * Used to get a list of filtered out inactive tags used for filter options.
 * When adding/removing new filters, ensure to update hook with addition/removal
 * @param {*} data -- List of providersData (pass through providersData.edges)
 * @returns
 */
export const useFilterListData = data => {
  const activeFilterOptions = useMemo(
    () =>
      data?.reduce(
        (acc, { node }) => {
          // Get active "Insurance" val
          if (node.data.insurances?.length > 0) {
            node.data.insurances?.forEach(({ insurance }) => {
              if (!acc["insurancesByKey"][insurance.uid] && insurance.uid) {
                acc["insurancesByKey"][insurance.uid] = {
                  insurance: { ...insurance },
                };
              }
            });
          }
          // Get active "Specialty" tags
          if (node.data.specialty_tags?.length > 0) {
            node.data.specialty_tags?.forEach(({ tag }) => {
              if (!acc["specialtyTagsByKey"][tag.uid] && tag.uid) {
                acc["specialtyTagsByKey"][tag.uid] = {
                  tag: { ...tag },
                };
              }
            });
          }
          // Get active "Modality" tags
          if (node.data.treatment_modality_tags?.length > 0) {
            node.data.treatment_modality_tags?.forEach(({ tag }) => {
              if (!acc["modalityTagsByKey"][tag.uid] && tag.uid) {
                acc["modalityTagsByKey"][tag.uid] = {
                  tag: { ...tag },
                };
              }
            });
          }
          // Get active "Language" tags
          if (node.data.languages?.length > 0) {
            node.data.languages?.forEach(({ language }) => {
              if (!acc["languagesByKey"][language.uid] && language.uid) {
                acc["languagesByKey"][language.uid] = {
                  language: {
                    ...language,
                    totalCount: 1,
                  },
                };
              } else {
                acc["languagesByKey"][language.uid].language.totalCount += 1;
              }
            });
          }
          // Get active "Licenses" val
          if (node.data.license_groups?.length > 0) {
            node.data.license_groups?.forEach(({ license }) => {
              if (!acc["licensesByKey"][license.uid] && license.uid) {
                acc["licensesByKey"][license.uid] = {
                  license: {
                    ...license,
                    totalCount: 1,
                  },
                };
              } else {
                if (acc["licensesByKey"][license.uid]) {
                  acc["licensesByKey"][license.uid].license.totalCount += 1;
                }
              }
            });
          }
          return acc;
        },
        {
          insurancesByKey: {},
          specialtyTagsByKey: {},
          modalityTagsByKey: {},
          languagesByKey: {},
          licensesByKey: {},
        }
      ),
    [data]
  );


  const orderedInsurances = orderBy(
    Object.values(activeFilterOptions["insurancesByKey"]),
    "tag.uid",
    "asc"
  );

  const orderedSpecialtyTags = orderBy(
    Object.values(activeFilterOptions["specialtyTagsByKey"]),
    "tag.uid",
    "asc"
  );
  const orderedModalityTags = orderBy(
    Object.values(activeFilterOptions["modalityTagsByKey"]),
    "tag.uid",
    "asc"
  );
  const orderedLanguagesByCount = orderBy(
    Object.values(activeFilterOptions["languagesByKey"]),
    "language.totalCount",
    "desc"
  );
  const topLanguages = take(
    orderedLanguagesByCount,
    FILTER_MENU_NUM_OPTIONS_COLLAPSED
  );
  const otherLanguages = drop(
    orderedLanguagesByCount,
    FILTER_MENU_NUM_OPTIONS_COLLAPSED
  );
  const orderedLicensesByCount = orderBy(
    Object.values(activeFilterOptions["licensesByKey"]),
    "license.totalCount",
    "desc"
  );

  const activeFilterOptionsList = {
    availableInsurances: orderedInsurances,
    availableSpecialtyTags: orderedSpecialtyTags,
    availableModalityTags: orderedModalityTags,
    availableLanguages: [
      ...topLanguages,
      ...orderBy(otherLanguages, "language.uid", "asc"),
    ],
    availableLicenses: orderedLicensesByCount,
  };

  return activeFilterOptionsList;
};
