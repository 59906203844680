import React from "react";
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import { stripHtml } from "string-strip-html";
import Image from "../Image/Image";
import styles from "./location-card.module.scss";

const LocationCard = ({
  uid,
  office,
  state,
  address,
  thumbnail_tagline,
  hours,
  hours_short,
  thumbnail_image,
}) => {
  const disabled = uid?.length < 1;
  const inlineAddress = stripHtml(address.html).result;

  return (
    <div className={styles.root}>
      <Link to={`/${uid}`} className={styles.image} disabled={disabled}>
        {thumbnail_image && (
          <Image
            imageObj={thumbnail_image}
            style={{
              width: "100%",
              height: "100%",
            }}
            imgStyle={{
              height: "100%",
              objectFit: "cover",
              width: "100%",
            }}
          />
        )}
      </Link>
      <div className={styles.content}>
        <h3>{office}</h3>
        <div
          className={styles.tagLine}
          dangerouslySetInnerHTML={{ __html: thumbnail_tagline.html }}
        />
        <div className={styles.details}>
          <div className={styles.detailsItem}>
            <h4 className={styles.detailsItemHeader}>Address</h4>
            <p>{inlineAddress}</p>
          </div>
          <div className={styles.detailsItem}>
            <h4 className={styles.detailsItemHeader}>Hours</h4>
            {hours_short.html ? (
              <div dangerouslySetInnerHTML={{ __html: hours_short.html }} />
            ) : (
              <div dangerouslySetInnerHTML={{ __html: hours.html }} />
            )}
          </div>
        </div>
      </div>
      {state.uid && uid && (
        <Link
          to={`/locations/${state?.uid}/${uid}`}
          className={styles.button}
          disabled={disabled}
        >
          Learn More
        </Link>
      )}
    </div>
  );
};

LocationCard.propTypes = {
  uid: PropTypes.string,
  office: PropTypes.string,
  address: PropTypes.object,
  contact: PropTypes.object,
  description: PropTypes.object,
  geo: PropTypes.object,
  hours: PropTypes.object,
  hours_short: PropTypes.object,
  thumbnail_image: PropTypes.object,
};

LocationCard.defaultProps = {
  uid: null,
  office: "",
  address: {},
  contact: {},
  description: {},
  geo: {},
  hours: {},
  hours_short: {},
  thumbnail_image: {},
};

export default LocationCard;

export const fragment = graphql`
  fragment LocationCardDef on PrismicOfficeLocation {
    uid
    _previewable
    data {
      office
      show_in_location_page
      state {
        uid
        document {
          ... on PrismicStateLocation {
            id
            data {
              name
            }
          }
        }
      }
      address {
        text
        html
      }
      contact {
        text
        html
      }
      description {
        html
      }
      geo {
        latitude
        longitude
      }
      hours {
        text
        html
      }
      hours_short {
        text
        html
      }
      thumbnail_tagline {
        text
        html
      }
      thumbnail_image {
        url
        gatsbyImageData(width: 400, layout: CONSTRAINED)
        localFile {
          childImageSharp {
            gatsbyImageData(width: 400, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;
