import React, { useCallback, useEffect, useState } from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import MiniProfile from "../MiniProfile/MiniProfile";
import Modal from "../Modal/Modal";
import Profile from "../Profile/Profile";
import Reveal from "../Reveal/Reveal";
import styles from "./team.module.scss";

// TODO: share more code with Provider.js
const Team = ({ heading, items }) => {
  const [showModal, setShowModal] = useState(null);

  const closeModal = useCallback(event => {
    if (event) {
      event.preventDefault();
    }
    setShowModal(null);
  }, []);

  useEffect(() => {
    const onKeydown = event => {
      switch (event.keyCode) {
        case 27: // Left
          closeModal();
          break;
        default:
          break;
      }
    };

    // define the root where to place the modal:
    // https://github.com/reactjs/react-modal/issues/133#issuecomment-194034344
    ReactModal.setAppElement("body");

    // TODO: need to find the purpose of this line
    // document.removeEventListener("keydown", onKeydown)
    document.addEventListener("keydown", onKeydown);
    return () => {
      document.removeEventListener("keydown", onKeydown);
    };
  }, [closeModal]);

  const handleClick = (event, index) => {
    event.preventDefault();
    setShowModal(index);
  };

  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <Reveal>{<h2>{heading.text}</h2>}</Reveal>
        <div className={styles.list}>
          {items.map((member, i) => {
            if (member?.profile?.document?.data) {
              const { name, job_title, profile_photo } =
                member.profile.document.data;
              return (
                <Reveal className={styles.item} key={name.text}>
                  <MiniProfile
                    header={name}
                    subheader={job_title}
                    image={profile_photo}
                    ctaAction={e => handleClick(e, i)}
                    cardColor="#fff"
                  />
                </Reveal>
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
      {items && (
        <Modal isOpen={showModal !== null} closeModal={closeModal}>
          <Profile
            profile={get(items, `[${showModal}].profile.document.data`, {})}
            title={"Our Team"}
            modal
          />
        </Modal>
      )}
    </div>
  );
};

export default Team;

Team.propTypes = {
  heading: PropTypes.object,
  ordered_team: PropTypes.array,
};

Team.defaultProps = {
  heading: {},
  ordered_team: [],
};

// Prismic "Team" content type.
export const fragment = graphql`
  fragment SliceTeam on PrismicPageContainerDataBodySectionTeam {
    slice_type
    primary {
      heading {
        text
      }
    }
    items {
      profile {
        uid
        document {
          ... on PrismicTeam {
            data {
              last_name
              job_title
              is_active
              name {
                text
              }
              profile_body {
                html
              }
              profile_photo {
                url
                gatsbyImageData(width: 200, layout: CONSTRAINED)
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 200, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
