import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { groupBy, orderBy } from "lodash";
import PropTypes from "prop-types";
import LinkButton from "../LinkButton/LinkButton";
import LocationCard from "../LocationCard/LocationCard";
import Reveal from "../Reveal/Reveal";
import { flattenNode, slugify } from "../../prismic/util";
import styles from "./sortable-location.module.scss";

const SortableLocations = ({ heading, display_limit }) => {
  const [showAll, setShowAll] = useState(false);
  const [filter, setFilter] = useState("");

  const sectionHeading = heading.text || "Our Locations";

  const staticData = useStaticQuery(locationsQuery);

  const locations = staticData.allPrismicOfficeLocation.edges.reduce(
    (acc, loc) => {
      // utilize show_in_location_page to show only requested locations
      if (loc.node.data.show_in_location_page) {
        acc.push(flattenNode(loc.node));
      }
      return acc;
    },
    []
  );

  const groups = groupBy(locations, location => location.state.uid);

  // Sort locations by city, then office DESC
  const allLocations = orderBy(
    locations.map(item => item),
    ["state.uid", "office"],
    ["desc", "desc"]
  );
  const handleShowAll = () => {
    setShowAll(true);
  };

  const updateFilter = event => {
    setFilter(event.target.value);
  };

  const locationGroup = allLocations.filter(
    location => location.state.document.uid === filter
  );
  const listLocation = locationGroup.length > 0 ? locationGroup : allLocations;
  const count = locations.length;
  const limit = display_limit || 2;
  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <Reveal className={styles.header}>
          <h2>{sectionHeading}</h2>
          <div className={styles.filter}>
            {/* eslint-disable jsx-a11y/no-onchange */}
            <select onChange={updateFilter}>
              <option value="">All Locations</option>
              {Object.entries(groups).map(group => {
                return (
                  <option value={slugify(group[0])} key={slugify(group[0])}>
                    {group[1][0].state.document.data.name}
                  </option>
                );
              })}
            </select>
          </div>
        </Reveal>
        {listLocation.length > 0 && (
          <div className={styles.list}>
            {listLocation.map((location, i) => (
              <Reveal
                className={`${styles.item} ${
                  limit && i > limit - 1 && !showAll && styles.hidden
                }`}
                key={i}
              >
                <LocationCard {...location} />
              </Reveal>
            ))}
          </div>
        )}
        {limit && count > limit && !showAll && (
          <div className={styles.loadMore}>
            <LinkButton onClick={handleShowAll}>
              See all {count} locations
            </LinkButton>
          </div>
        )}
      </div>
    </div>
  );
};

SortableLocations.propTypes = {
  heading: PropTypes.object,
  display_limit: PropTypes.number,
};

SortableLocations.defaultProps = {
  heading: {},
  display_limit: null,
};

export default SortableLocations;

const locationsQuery = graphql`
  query {
    allPrismicOfficeLocation {
      edges {
        node {
          ...LocationCardDef
        }
      }
    }
  }
`;

export const fragment = graphql`
  fragment SliceLocationCards on PrismicPageContainerDataBodySectionLocationCards {
    slice_type
    primary {
      heading {
        text
      }
      display_limit
    }
  }
`;
