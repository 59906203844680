import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import Button from "../Button/Button";
import ProvidersDisplay from "./ProvidersDisplay";
import SideDecoration from "../../images/side-blobs-dots.inline.svg";
import linkResolver from "../../prismic/linkResolver";
import { useShuffledProvidersData } from "../ProvidersList/hooks";
import styles from "./providers-alt.module.scss";
import { mapAirtableProviderNodesToPrismic } from "../../prismic/util";
import { useMixpanel } from "../../lib/mixpanel/tracking";
import { MIXPANEL_LINK_CLICK_EVENT_NAME } from "../../lib/constants";

const ProvidersAlt = ({
  cta_link,
  cta_link_label,
  section_description,
  section_title,
}) => {
  const staticData = useStaticQuery(providersQuery);
  const providersData = staticData?.allAirtable;
  const remapProviders = {
    edges: mapAirtableProviderNodesToPrismic(providersData.edges),
  };
  // raw unfiltered list of shuffled providers
  const allShuffledProviders = useShuffledProvidersData(
    remapProviders.edges,
    "providers-alt-seed"
  );

  const mixpanel = useMixpanel();

  return (
    <section className={styles.root}>
      <div className={styles.inner}>
        <div className={styles.copy}>
          <PrismicRichText
            field={section_title.richText}
            components={{
              heading2: ({ children }) => (
                <h2 className={styles.section_title}>{children}</h2>
              ),
              heading3: ({ children }) => (
                <h3 className={styles.section_title}>{children}</h3>
              ),
              heading4: ({ children }) => (
                <h4 className={styles.section_title}>{children}</h4>
              ),
              heading5: ({ children }) => (
                <h5 className={styles.section_title}>{children}</h5>
              ),
              heading6: ({ children }) => (
                <h6 className={styles.section_title}>{children}</h6>
              ),
            }}
          />
          <PrismicRichText
            field={section_description.richText}
            components={{
              paragraph: ({ children }) => (
                <p className={styles.section_description}>{children}</p>
              ),
            }}
          />
          <Button
            internalLink
            url={linkResolver(cta_link)}
            text={cta_link_label}
            className={styles.section_button}
            onClick={() => {
              mixpanel.track(MIXPANEL_LINK_CLICK_EVENT_NAME, {
                destination_url: cta_link.url,
                origin_url: window?.location.href,
                Section: "Meet the team",
                cta_copy: cta_link_label,
                cta_type: "Button",
                category: "directory",
              });
            }}
          />
        </div>

        <ProvidersDisplay shuffledProviders={allShuffledProviders} />
      </div>
      <div className={styles.decoration_wrapper}>
        <SideDecoration className={styles.decoration} />
      </div>
    </section>
  );
};

export default ProvidersAlt;

const providersQuery = graphql`
  query ActiveProvidersAltQuery {
    allAirtable(
      filter: {
        data: { Status: { eq: "Active" }, Publish_to_Website: { eq: true }, Green_Light_Done: { eq: "checked" } }
      }
    ) {
      totalCount
      edges {
        node {
          ...ProviderDetailsDef
        }
      }
    }
  }
`;

export const fragment = graphql`
  fragment SliceProvidersAlt on PrismicPageContainerDataBodySectionProvidersAlt {
    id
    slice_type
    slice_label
    primary {
      cta_link {
        link_type
        url
      }
      cta_link_label
      section_description {
        richText
      }
      section_title {
        richText
      }
    }
  }
`;
