import React from "react";
import ContactForm from "../ContactForm";
import styles from "./contact-footer.module.scss";

const ContactFormFooter = () => {
  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <div className={styles.copy}>
          <h1>Stay in touch.</h1>
          <p>
            Sign up to receive Octave updates and information about mental
            health topics.
          </p>
        </div>
        <div className={styles.form_container}>
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default ContactFormFooter;
