import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Reveal from "../Reveal/Reveal";
import styles from "./iframe-embed.module.scss";
import { headerHeight } from "../../lib/constants";

const IframeEmbed = ({
  iframe_heading,
  iframe_url,
  iframe_height,
  full_screen = false,
}) => {
  const minHeight = iframe_height ? `${iframe_height}px` : "725px";

  const rootStyle = full_screen ? { margin: "0" } : {};
  const innerStyle = full_screen ? { width: "100%" } : {};
  const iframeHeight = full_screen ? `calc(100dvh - ${headerHeight}px)` : minHeight;

  return (
    <div className={styles.root} style={rootStyle}>
      <Reveal>
        <div className={styles.inner} style={innerStyle}>
          {iframe_heading?.text && (
            <h2 className={styles.header}>{iframe_heading.text}</h2>
          )}
          <iframe
            src={iframe_url.url}
            title={iframe_heading.text}
            style={{ minHeight: iframeHeight }}
          />
        </div>
      </Reveal>
    </div>
  );
};

IframeEmbed.propTypes = {
  title: PropTypes.object,
  iframe_url: PropTypes.object.isRequired,
};

export default IframeEmbed;

// Prismic "Section IFrame" slice
export const fragment = graphql`
  fragment SliceIframe on PrismicPageContainerDataBodySectionIframe {
    slice_type
    slice_label
    primary {
      iframe_heading {
        text
      }
      iframe_url {
        url
      }
      iframe_height
      full_screen
    }
  }
  fragment LocationSliceIframe on PrismicStateLocationDataBodySectionIframe {
    slice_type
    slice_label
    primary {
      iframe_heading {
        text
      }
      iframe_url {
        url
      }
      iframe_height
    }
  }
  fragment OfficeLocationSliceIframe on PrismicOfficeLocationDataBodySectionIframe {
    slice_type
    slice_label
    primary {
      iframe_heading {
        text
      }
      iframe_url {
        url
      }
      iframe_height
    }
  }
`;
