export const AFTERNOON_HOUR = 17;

export const AVAILABILITY_WIDGET_BREAKPOINT = 960;

export const blogUrl = "https://blog.findoctave.com/";

export const CAREERS_PATH = `/careers/`;

export const defaultBackgroundColor = "var(--light-brown-color)";

export const defaultBannerHeight = 64;

export const EARLY_HOUR = 10;

export const EMAIL_VALIDATION_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const FILTER_MENU_NUM_OPTIONS_COLLAPSED = 7;

export const FORM_REQ_URL =
  "//links.iterable.com/lists/publicAddSubscriberForm?publicIdString=bc41e813-2c83-40fc-bb7d-a0cd247538b0";

export const GH_BOARD_TOKEN = `octave`;

export const headerHeight = 80;

export const HOT_LINES_PATH = "/hotlines";

export const initialLayoutPaddingTop = "var(--header-height)";

export const IS_PROD_ENV = process.env.NODE_ENV === "production";

export const locationModel = {
  virtualCA: "Virtual CA",
  virtualNY: "Virtual NY",
};

export const MAD_LIBS_ERROR_MESSAGES = {
  location: "Please select a state to continue.",
  locationFirst:
    "Please choose your state first to see the insurance plans that apply to you.",
};


export const MIXPANEL_LINK_CLICK_EVENT_NAME = "Link Click";

export const MIXPANEL_CTA_EVENT_PATHS = ["uclahealth", "stanford"];

export const MORNING_HOUR = 12;

export const OFFICE_FILTER_KEY = "office";
// create a constant for the url params to be store in session storage
export const octExternalUrlParams = "octInboundQsParams";

export const PINNED_JOB = {
  Clinicians: 6824074002,
};

export const providerRole = {
  therapist: "therapist",
  coach: "coach",
  lead: "lead",
  therapistLead: "therapist lead",
  coachLead: "coach lead",
};

export const PROVIDER_BIO_AVAILABILITY_WIDGET_BASE_URL =
  "https://my.findoctave.com/availability-widget?marketing_iframe=true";

export const SCHEDULE_CONSULT_URL = "https://my.findoctave.com/onboarding";

export const SCREEN_SIZE = {
  MOBILE_LANDSCAPE: 540,
  TABLET_PORTRAIT: 768,
};

// Pages to apply smooth scroll to
export const SCROLL_PAGES = ["locations", "groups", "careers-for-providers"];

export const SEARCH_IS_TESTING_SLOTS_KEY = "testing_slots";

export const SEARCH_SHOW_AVAILABILITY_WIDGET_KEY = "show_widget";

export const SHOW_AVAILABILITY_FILTER = true;

export const SHOW_GET_IN_TOUCH_BTN = false;

export const SITE_URL = new URL("https://www.findoctave.com");

export const SUPPORT_EMAIL = "support@findoctave.com";

export const TEAM_ANCHOR_ID = "providers-display";

export const TEAM_PATHNAME = "team";

export const WEEKDAYS = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

export const ZOHO_CONTACT_FORM_BASE_URL =
  "https://forms.zohopublic.com/octave/form/ContactUs/formperma/rsOsX2ISR-ok4QBizyg-cDax5qhn3Fid3fJ9DRs4TAY";

export const ZOHO_CONTACT_FORM_PROVIDER_ALIAS = "provider";

export const ZOHO_CONTACT_FORM_STATE_ALIAS = "state";



