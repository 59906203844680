import React from "react";
import PropTypes from "prop-types";
import styles from "./input-styling.module.scss";

/**
 * ***Ensure the parent element is position relative as this component
 * uses absolute positioning to style itself.***
 *
 * This component allows for the border styling with label in top
 * left corner of the input. Fieldset w/ legend allows for desired style,
 * similar to Material UI's implementation.
 * @param {*} params
 * @returns
 */
export const InputStyling = ({
  validityStyles,
  label,
  hideLabel,
  focusStyles,
}) => {
  return (
    <fieldset
      className={`${styles.form_fieldset} ${styles[validityStyles]} ${styles[focusStyles]}`}
    >
      <legend>
        <span className={hideLabel ? styles.visually_hidden : ""}>{label}</span>
      </legend>
    </fieldset>
  );
};

InputStyling.propTypes = {
  validityStyles: PropTypes.string,
  label: PropTypes.string.isRequired,
  hideLabel: PropTypes.bool.isRequired,
  focusStyles: PropTypes.string.isRequired,
};

InputStyling.defaultProps = {
  validityStyles: "",
  label: "",
  hideLabel: true,
  focusStyles: "",
};
