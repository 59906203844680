import React from "react";
import PropTypes from "prop-types";
import ParallaxImages from "../../components/ParallaxImages/ParallaxImages";
import { isEmpty } from "../../prismic/util";
import styles from "./post-header.module.scss";

const PostHeader = ({ heading, header_text, topImages }) => {
  let imageList = null;
  if (topImages) {
    imageList = topImages.map(img => {
      if (isEmpty(img)) {
        return false;
      }
      return img;
    });
  }

  // TODO: remove when new stacked background images are available.
  const sfHack = heading.text.includes("San Francisco");

  return (
    <div className={`${styles.root} ${sfHack ? styles.sfHack : ""}`}>
      <div className={styles.imageContain}>
        {imageList && <ParallaxImages images={imageList} />}
      </div>

      <div className={styles.sectionHeader}>
        <h2>{heading.text}</h2>
        <div
          className={styles.subtext}
          dangerouslySetInnerHTML={{ __html: header_text.html }}
        />
      </div>
    </div>
  );
};

PostHeader.propTypes = {
  heading: PropTypes.object,
  header_text: PropTypes.object,
  topImages: PropTypes.array,
};

PostHeader.defaultProps = {
  heading: null,
  header_text: null,
  topImages: null,
};

export default PostHeader;
