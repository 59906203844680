import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import AnimatedLine from "../AnimatedLine/AnimatedLine";
import Reveal from "../Reveal/Reveal";
import styles from "./lists-block.module.scss";

const List = ({ list, list_heading }) => (
  <Reveal className={styles.list}>
    <ul>
      <h4>{list_heading && list_heading.text}</h4>
      {list && <div dangerouslySetInnerHTML={{ __html: list.html }} />}
    </ul>
  </Reveal>
);

const ListsBlock = ({ heading, section_text, items }) => (
  <div className={styles.root}>
    <div className={styles.inner}>
      <div className={styles.main}>
        <Reveal className={styles.copy}>
          {heading && <h2>{heading.text}</h2>}
          {section_text && (
            <div dangerouslySetInnerHTML={{ __html: section_text.html }} />
          )}
          <div className={styles.lineWrapper}>
            <AnimatedLine
              linePath={"M 0 0 Q 0 120, 120 120"}
              lineLength={122 * 1.6}
              height={122}
              width={122}
            />
          </div>
        </Reveal>
      </div>
      {items &&
        items.map((list_fields, i) => <List {...list_fields} key={i} />)}
    </div>
  </div>
);

ListsBlock.propTypes = {
  heading: PropTypes.object,
  section_text: PropTypes.object,
  items: PropTypes.array.isRequired,
};

export default ListsBlock;

// Prismic "Section Lists" slice
export const fragment = graphql`
  fragment SliceLists on PrismicPageContainerDataBodySectionSplitList {
    slice_type
    primary {
      heading {
        text
      }
      section_text {
        html
      }
    }
    items {
      list_heading {
        text
      }
      list {
        html
      }
    }
  }
`;
