import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";
import { get } from "lodash";
import { slugify } from "../../prismic/util";
import useJobBoardFilter from "./useJobBoardFilter";

const FILTER_OPTIONS = {
  deptFilter: "deptFilter",
  officeFilter: "officeFilter",
  all: "all",
  none: "none",
};

const JobBoardContext = createContext({
  deptFilter: "",
  handleDeptFilterChange: () => {},
  officeFilter: "",
  handleOfficeFilterChange: () => {},
  finalData: [],
  setFinalData: () => {},
  rawData: [],
});

export const JobBoardContextProvider = ({ children }) => {
  // fetch job posts
  const staticData = useStaticQuery(query);
  const { allGreenhouseDepartment } = useMergePrismicPreviewData(staticData);

  const data = get(allGreenhouseDepartment, "edges", {});
  // clean copy of data
  const rawData = [...data];
  // finalData state used for modifying filtered results
  const [finalData, setFinalData] = useState(data || []);

  const { filter: deptFilter, handleChange: handleDeptFilterChange } =
    useJobBoardFilter();

  const { filter: officeFilter, handleChange: handleOfficeFilterChange } =
    useJobBoardFilter();

  const filterDepts = useCallback(
    (arr = [...data]) => {
      return [...arr].filter(({ node }) => slugify(node.name) === deptFilter);
    },
    [data, deptFilter]
  );

  const filterOffices = useCallback(
    (arr = [...data]) => {
      return [...arr].reduce((acc, { node }) => {
        const finalJobPosts = [];
        const nodeCopy = { ...node };

        // iterate through all job posts and keep those that match officeFilter
        for (let i = 0; i < nodeCopy.jobPosts.length; i++) {
          const jobOfficeLocation = nodeCopy.jobPosts[i].location.name;

          if (slugify(jobOfficeLocation) === officeFilter) {
            finalJobPosts.push(nodeCopy.jobPosts[i]);
          }
        }

        // mutate node with new jobPosts array
        nodeCopy.jobPosts = finalJobPosts;
        if (finalJobPosts.length) {
          acc.push({ node: nodeCopy });
        }

        return acc;
      }, []);
    },
    [data, officeFilter]
  );

  /**
   *
   * @param {keyof FILTER_OPTIONS} option
   * @returns
   */
  const filterData = useCallback(
    option => {
      switch (option) {
        case FILTER_OPTIONS.all: {
          const filteredOffices = filterOffices();
          // Filter out depts last as it is a parent to offices
          const filteredDepts = filterDepts(filteredOffices);
          setFinalData(filteredDepts);
          return;
        }
        case FILTER_OPTIONS.deptFilter: {
          const filteredData = filterDepts();
          setFinalData(filteredData);
          return;
        }
        case FILTER_OPTIONS.officeFilter: {
          const filteredData = filterOffices();
          setFinalData(filteredData);
          return;
        }
        default:
          return setFinalData([...data]);
      }
    },
    [data, filterDepts, filterOffices]
  );

  useEffect(() => {
    if (deptFilter && officeFilter) {
      return filterData("all");
    }
    if (deptFilter) {
      return filterData("deptFilter");
    }
    if (officeFilter) {
      return filterData("officeFilter");
    }
    return filterData("none");
  }, [deptFilter, officeFilter, filterData]);

  const values = {
    deptFilter,
    handleDeptFilterChange,
    officeFilter,
    handleOfficeFilterChange,
    finalData,
    setFinalData,
    rawData,
  };

  return (
    <JobBoardContext.Provider value={values}>
      {children}
    </JobBoardContext.Provider>
  );
};

export const useJobBoardContext = () => {
  const context = useContext(JobBoardContext);

  if (!context) {
    throw new Error(
      `useJobBoardContext must be used within a JobBoardContextProvider`
    );
  }

  return context;
};

const query = graphql`
  query AllJobs {
    allGreenhouseDepartment {
      edges {
        node {
          name
          id
          gh_Id
          jobs {
            gh_Id
            title
            location {
              name
            }
          }
        }
      }
    }
  }
`;
