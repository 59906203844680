import { useEffect, useState } from "react";
import useScript from "./useScript";

export const ZD_SCRIPT_SRC =
  "https://static.zdassets.com/ekr/snippet.js?key=d804f9bd-2c75-46a1-bb81-c03c5e9f7513";
export const ZD_SCRIPT_ID = "ze-snippet";
const ZD_TOKEN_KEY = "zd_hasOpened";

export const useZendeskScript = zdBtnRef => {
  const [isClickEvent, setIsClickEvent] = useState(false);

  const isZendeskStored =
    typeof window !== "undefined" &&
    window.sessionStorage.getItem(ZD_TOKEN_KEY);

  const [zdSrc, setZdSrc] = useState(() => {
    return isZendeskStored ? ZD_SCRIPT_SRC : "";
  });

  // dynamically load Zendesk script
  const zdLoadedStatus = useScript(zdSrc, ZD_SCRIPT_ID);

  const handleZdBtnClick = () => {
    // add src
    setZdSrc(ZD_SCRIPT_SRC);
    // notify type of event to ensure useEffect doesnt render unnecessarily
    setIsClickEvent(true);
  };

  useEffect(() => {
    // This should only run when placeholder button is clicked
    // Not when page is loaded or any other event.
    if (zdSrc && isClickEvent) {
      if (typeof window.zE !== "undefined" && zdLoadedStatus === "ready") {
        // hide the fake button
        zdBtnRef.current.style.opacity = "0";

        // save in sessionStorage to ensure chat history remains
        // and we don't start a new one with the placeholder btn click
        window.sessionStorage.setItem("zd_hasOpened", "true");

        // open zendesk chat on click of button
        window.zE("webWidget", "open");
        setIsClickEvent(false);
      }
    }
  }, [zdSrc, zdLoadedStatus, isClickEvent, zdBtnRef]);

  return {
    zdLoading: isClickEvent,
    handleZdBtnClick,
    zdLoadedStatus,
  };
};
