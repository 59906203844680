import React, { useState } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import Aside from "../Aside/Aside";
import Image from "../Image/Image";
import SlideController from "../SlideController/SlideController";
import SlideIndicators from "../SlideIndicators/SlideIndicators";
import styles from "./hero-slides.module.scss";

const HeroSlides = ({
  state,
  office,
  description,
  footnote,
  photo_carousel,
}) => {
  const [slide, setSlide] = useState(0);

  const updateSlide = slide => setSlide(slide);

  const slideCount = photo_carousel ? photo_carousel.length : 1;

  const hasState = Boolean(state.uid);
  const stateLabel = state.document.data.name;

  return (
    <div className={styles.root}>
      <SlideController
        onUpdate={updateSlide}
        currentSlide={slide}
        count={slideCount}
      >
        <div className={styles.inner}>
          <div className={styles.blockCopy}>
            <div className={styles.copy}>
              {hasState && (
                <Link to={`/locations/${state.uid}`}>{stateLabel}</Link>
              )}
              {office && <h2>{office}</h2>}
              {description && (
                <div dangerouslySetInnerHTML={{ __html: description.html }} />
              )}
              {footnote && (
                <Aside icon="/images/question.svg">{footnote}</Aside>
              )}
            </div>
          </div>
          <div className={styles.blockImage}>
            <div className={styles.imageWrapper}>
              <div className={styles.image}>
                {photo_carousel &&
                  photo_carousel.map((img, index) => {
                    const { photo } = img;
                    return (
                      <div
                        className={`${styles.imageInner} ${
                          slide === index && styles.active
                        }`}
                        key={index}
                      >
                        <Image
                          imageObj={photo}
                          style={{
                            height: "100%",
                          }}
                          imgStyle={{
                            objectFit: "cover",
                            width: "100%",
                            height: "100%",
                          }}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
            <SlideIndicators
              currentSlide={slide}
              count={slideCount}
              updateSlide={updateSlide}
            />
          </div>
        </div>
      </SlideController>
    </div>
  );
};

HeroSlides.propTypes = {
  city: PropTypes.string,
  office: PropTypes.string,
  description: PropTypes.object,
  footnote: PropTypes.object,
  photo_carousel: PropTypes.array,
  location_tag: PropTypes.object,
};

HeroSlides.defaultProps = {
  city: null,
  office: null,
  description: null,
  footnote: null,
  photo_carousel: [],
  location_tag: {},
};

export default HeroSlides;
