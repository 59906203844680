import { useMemo } from "react";
import { Languages, Specialties, Modalities, Licenses, Insurances } from "../types";
import { ALL_LOCATIONS_VALUE } from "./useProviderListFilters";

export const useSelectValues = (rawValues: {
  states: any[];
  availableLanguages: Languages;
  availableSpecialtyTags: Specialties;
  availableModalityTags: Modalities;
  availableInsurances: Insurances;
  availableLicenses: Licenses;
}) => {
  const {
    states,
    availableLanguages,
    availableSpecialtyTags,
    availableModalityTags,
    availableInsurances,
    availableLicenses,
  } = rawValues;

  const statesOptions = useMemo(() => {
    return [
      {
        label: "All Locations",
        value: ALL_LOCATIONS_VALUE,
      },
      ...states.map(state => ({
        label: state.node.data.name,
        value: state.node.uid,
      })),
    ];
  }, [states]);

  const languagesOptions = useMemo(() => {
    return availableLanguages?.map(lang => ({
      label: lang.language.document.data.name,
      value: lang.language.uid,
      totalCount: lang.language.totalCount,
    }));
  }, [availableLanguages]);

  const specialitiesOptions = useMemo(() => {
    return availableSpecialtyTags?.map(st => ({
      label: st.tag.document.data.name,
      value: st.tag.uid,
    }));
  }, [availableSpecialtyTags]);

  const modalitiesOptions = useMemo(() => {
    return availableModalityTags?.map(mt => ({
      label: mt.tag.document.data.name,
      value: mt.tag.uid,
    }));
  }, [availableModalityTags]);

  const licensesOptions = useMemo(() => {
    return availableLicenses?.map(l => ({
      label: l.license.document.data.name,
      value: l.license.uid,
    }));
  }, [availableLicenses]);

  const insurancesOptions = useMemo(() => {
    return availableInsurances?.map(i => ({
      label: i.insurance.document.data.name,
      value: i.insurance.uid,
    }));
  }, [availableInsurances]);

  return {
    statesOptions,
    languagesOptions,
    specialitiesOptions,
    modalitiesOptions,
    insurancesOptions,
    licensesOptions,
  };
};
