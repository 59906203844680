import React from "react";
import { graphql, PageProps } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import get from "lodash/get";
import AnchorSection from "../components/AnchorSection/AnchorSection";
import Layout from "../components/Layout/Layout";
import PostContent from "../components/PostContent/PostContent";
import PostFooter from "../components/PostFooter/PostFooter";
import PostHeader from "../components/PostHeader/PostHeader";
import SeoUtil from "../components/SeoUtil/SeoUtil";
import { flattenNode, flattenSlice, getMetadata } from "../prismic/util";

// settings
const backgroundColor = "#FAF9F0";

const Post = ({ data }: PageProps<Queries.PostPageQuery>) => {
  const postData = flattenNode(get(data, "prismicPostContainer.data"));
  const metadata = getMetadata(postData);

  return (
    <Layout pageUid="post" backgroundColor={backgroundColor}>
      <SeoUtil {...metadata} />
      {postData.body &&
        postData.body.map((slice, index) => {
          const sliceFields = flattenSlice(slice);
          const { slice_type } = slice;
          switch (slice_type) {
            case "post_section_header":
              return <PostHeader {...sliceFields} key={index} />;
            case "post_main_content":
              return <PostContent {...sliceFields} key={index} />;
            case "post_footer":
              return <PostFooter {...sliceFields} key={index} />;
            case "section_anchor":
              return <AnchorSection {...sliceFields} key={index} />;
            default:
              return true;
          }
        })}
    </Layout>
  );
};

export default withPrismicPreview(Post);

export const query = graphql`
  query PostPage($postId: String) {
    prismicPostContainer(uid: { eq: $postId }) {
      prismicId
      uid
      _previewable
      data {
        meta_title {
          text
        }
        meta_description
        og_image_overwrite {
          url
        }
        body {
          ... on PrismicPostContainerDataBodyPostMainContent {
            slice_type
            slice_label
            primary {
              post_text {
                html
              }
              post_footnote {
                html
              }
              cta_text
              cta_link {
                url
              }
              display_width
            }
          }
          ... on PrismicPostContainerDataBodyPostSectionHeader {
            slice_type
            slice_label
            primary {
              heading {
                text
              }
              header_text {
                html
              }
            }
            items {
              stacked_image {
                url
                gatsbyImageData(width: 720, layout: CONSTRAINED)
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 720, layout: CONSTRAINED)
                  }
                }
              }
              stacked_image_mid {
                url
                gatsbyImageData(width: 720, layout: CONSTRAINED)
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 720, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
          ... on PrismicPostContainerDataBodyPostFooter {
            slice_type
            slice_label
            primary {
              footer_text {
                html
              }
            }
          }
          ... on PrismicPostContainerDataBodySectionAnchor {
            id
            slice_type
            primary {
              hash_label
            }
          }
        }
      }
    }
  }
`;
