import { useMemo } from "react";
import { Provider } from "../types";

function randomSeededNumber(seed: number) {
  var x = Math.sin(seed++) * 10000;
  return x - Math.floor(x);
}

/**
 * Fisher–Yates shuffle Algorithm
 * Generate a random permutation of a finite sequence based on seed
 * https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle
 * https://bost.ocks.org/mike/shuffle/
 * https://stackoverflow.com/questions/16801687/javascript-random-ordering-with-seed/53758827#53758827
 *
 */
function shuffle<T>(array: T[], seed: number) {
  var m = array.length,
    t,
    i;

  // While there remain elements to shuffle…
  while (m) {
    // Pick a remaining element…
    i = Math.floor(randomSeededNumber(seed) * m--);

    // And swap it with the current element.
    t = array[m];
    array[m] = array[i];
    array[i] = t;
    ++seed;
  }

  return array;
}

function getSessionSeed(key: string) {
  const sessionSeed =
    typeof window !== "undefined" && window.sessionStorage.getItem(key);

  if (sessionSeed) {
    const parsedSeed = parseInt(sessionSeed, 10);
    if (!isNaN(parsedSeed)) {
      return parsedSeed;
    }
  }

  const randomSeed = Math.random();

  typeof window !== "undefined" &&
    window.sessionStorage.setItem(key, randomSeed.toString());

  return randomSeed;
}

// Get a random ordered list of arr and persist it via
// sessionStorage so we can have a new random order every user session.
function getShuffledData<T>(arr: T[], sessionKey: string) {
  const sessionSeed = getSessionSeed(sessionKey);
  return shuffle([...arr], sessionSeed);
}

export const useShuffledProvidersData = (
  data: Provider[],
  sessionKey: string
) => {
  // make the initial state for filteredProviders state
  const allShuffledProviders = useMemo(() => {
    if (sessionKey) {
      // Create randomized order of providers using a generated
      // seed that is stored to the users session storage.
      const shuffledData = getShuffledData([...data], sessionKey);

      return shuffledData;
    }

    return [];
  }, [data, sessionKey]);
  return allShuffledProviders;
};
