import React from "react";
import Button from "../Button/Button";
import { SITE_URL } from "../../lib/constants";
import styles from "./button-pdf.module.scss";

const ButtonPdf = ({ url }) => (
  <div className={styles.root}>
    <Button
      className={styles.buttonPdf}
      text="View PDF"
      // Also use SITE_URL to ensure we pass www. 
      // base url to this blank page
      url={`${SITE_URL.origin}${url}`}
      target="_blank"
      inline={true}
      icon="/images/download.png"
    />
  </div>
);

export default ButtonPdf;
