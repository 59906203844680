import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import Image from "../Image/Image";
import styles from "./group-detail-leader-list.module.scss";
import { mapAirtableProviderNodeToPrismic } from "../../prismic/util";

const Arrow = () => (
  <svg
    width="34"
    height="28"
    viewBox="0 0 34 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M26.2722 11.8928L17.4343 2.78567L20.3048 0L33.7869 13.8928L20.3048 27.7857L17.4343 25L26.2722 15.8928H0V11.8928H26.2722Z"
      fill="#E9982F"
    />
  </svg>
);

const GroupDetailLeadersList = ({ groupLeadersData }) => {

  const renderLeaders = () => {
    return (groupLeadersData.map(item => {
      const remapProvider = mapAirtableProviderNodeToPrismic(item.node);
      const { uid } = remapProvider;
      const { name, profile_photo, quote = "" } = remapProvider.data;
      const providerLink = `/providers/${uid}`;
      return (
        <div className={styles.groupLead} key={uid}>
          <>
            <div>
              <div className={styles.imageWrapper}>
                <Image
                  imageObj={profile_photo}
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                  }}
                />
              </div>
              <Link to={providerLink} className={styles.viewBio}>
                <span>Bio</span>
                <Arrow />
              </Link>
            </div>
            <div>
              <div className={styles.contentWrapper}>
                <h3>{name.text}</h3>
                {quote && (
                  <div className={styles.quote}>
                    <p>{quote.text}</p>
                  </div>
                )}
              </div>
            </div>
          </>
        </div>
      );
    }))
    
  };

  return (
    <div className={styles.root}>
      <article className={styles.heading}>Group Leaders</article>
      <div className={styles.wrapper}>
        {groupLeadersData?.length ? renderLeaders() : <h4>Coming soon</h4>}
        
      </div>
    </div>
  );
};

export default GroupDetailLeadersList;

GroupDetailLeadersList.propTypes = {
  groupLeadersData: PropTypes.array,
};
