import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { slugify } from "../../prismic/util";
import styles from "./footer.module.scss";

export const LinksColumn = ({ links, title }) => (
  <dl className={`${styles.list} ${title === "Locations" ? styles.twoCol : ""}`}>
    <div>
      <dt>{title}</dt>
      <div className={styles.listItemContainer} >
        {links.map(item => {
          const isForeignDomain = item.link.url.substring(0, 4) === "http";
          const externalLink = Boolean(item.link.target);

          return (
            <dd key={slugify(item.label)}>
              {isForeignDomain || externalLink ? (
                <a target="_blank" rel="noreferrer" href={item.link.url}>
                  {item.label}
                </a>
              ) : (
                <Link to={item.link.url}>{item.label}</Link>
              )}
            </dd>
          );
        })}
      </div>
    </div>

    {
      // Add hardcoded cookie pref link that calls the global osano api.
      // See https://docs.osano.com/hiding-the-cookie-widget
      title === "Help" && (
        <dd key="cookie-pref">
          <a
            onClick={() => {
              window.Osano.cm.showDrawer("osano-cm-dom-info-dialog-open");
            }}
          >
            Cookie Preferences
          </a>
        </dd>
      )
    }
  </dl>
);

LinksColumn.propTypes = {
  title: PropTypes.string.isRequired,
  links: PropTypes.array.isRequired,
};

LinksColumn.defaultProps = {
  title: "",
  links: [],
};
