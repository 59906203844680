import React from "react";
import PropTypes from "prop-types";
import { SelectInput } from "../Forms/common/SelectInput/SelectInput";

const JobBoardFilter = ({ label, filter, handleChange, optionsList }) => {
  const inputId = `job-board-${label.toLowerCase()}-filter`;

  return (
    <SelectInput
      name={inputId}
      label={label}
      onChange={handleChange}
      data={optionsList}
      value={filter}
      disableFirstOption={false}
      showLabel={false}
    />
  );
};

JobBoardFilter.propTypes = {
  label: PropTypes.string.isRequired,
  filter: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  optionsList: PropTypes.array.isRequired,
};

JobBoardFilter.defaultProps = {
  label: "",
  filter: "",
  handleChange: () => {},
  optionsList: [],
};

export default JobBoardFilter;
