import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import FormSuccessValidationIcon from "../../../images/validation/octave-ui-icons_success-form-check.inline.svg";
import { EMAIL_VALIDATION_PATTERN, FORM_REQ_URL } from "../../../lib/constants";
import { LIST_OF_STATES } from "../../../lib/data/listOfStates";
import Button from "../../Button/Button";
import { SelectInput } from "../common/SelectInput/SelectInput";
import { TextInput } from "../common/TextInput/TextInput";
import styles from "./contact-form.module.scss";

/**
 * This is a list of states with the value and label being the label as
 * requested by Chris here https://www.loom.com/share/38d4146c717b435bb4ffb90b89e685b2.
 */
const LIST_OF_STATES_FULL_NAMES = LIST_OF_STATES.map(state => ({
  label: state.label,
  value: state.label,
}));

const INIT_FORM_VALUES = {
  first_name: "",
  last_name: "",
  email: "",
  client_location: ``,
};

/**
 * @param {string} name Name to be formatted
 * @returns {string} Formatted name. Example: "john doe" => "John Doe" or "d'angelo" => "D'Angelo"
 */
const formatName = (name = "") => {
  return name
    .toLowerCase()
    .split("")
    .map((char, index, arr) => {
      const prevChar = arr[index - 1];

      if (!prevChar || /\W/.test(prevChar)) {
        return char.toUpperCase();
      }

      return char;
    })
    .join("");
};

const ContactForm = () => {
  const [formSuccess, setFormSuccess] = useState(false);
  const [formSubmitError, setFormSubmitError] = useState("");
  const [isHydrated, setIsHydrated] = useState(false);

  //ensure inputs are disabled until after hydration
  useEffect(() => {
    setIsHydrated(true);
  }, []);

  const { handleSubmit, control, reset, formState, register, getValues } =
    useForm({
      defaultValues: INIT_FORM_VALUES,
      mode: "all", // used to ensure errrors are shown onBlur, onChange, and onSubmit
    });

  const formBag = {
    getValues,
    errors: formState.errors,
    touchedFields: formState.touchedFields,
  };

  const onSubmit = async data => {
    try {
      const response = await fetch(FORM_REQ_URL, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        body: JSON.stringify({
          ...data,
          first_name: formatName(data.first_name),
          last_name: formatName(data.last_name),
        }),
      });

      if (response) {
        setFormSuccess(true);
        reset();
      }
    } catch (error) {
      setFormSubmitError(
        `There was an issue submitting the form: ${error.message}`
      );
    }
  };

  return (
    <>
      {formSubmitError && (
        <div data-test="contact-form-error" className={styles.form_error}>
          {formSubmitError}
        </div>
      )}
      <form
        className={styles.form}
        name="iterable_optin"
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextInput
          disabled={!isHydrated}
          variant="form"
          label="First Name"
          name="first_name"
          formBag={formBag}
          {...register("first_name", {
            required: {
              value: true,
              message: "A first name is required",
            },
            minLength: {
              value: 1,
              message: "Must be at least 1 character",
            },
            maxLength: {
              value: 50,
              message: "Must be 50 characters or less",
            },
          })}
        />

        <TextInput
          disabled={!isHydrated}
          variant="form"
          label="Last Name"
          name="last_name"
          formBag={formBag}
          {...register("last_name", {
            required: {
              value: true,
              message: "A last name is required",
            },
            minLength: {
              value: 1,
              message: "Must be at least 1 character",
            },
            maxLength: {
              value: 50,
              message: "Must be 50 characters or less",
            },
          })}
        />

        <TextInput
          disabled={!isHydrated}
          variant="form"
          label="Email"
          name="email"
          type="email"
          formBag={formBag}
          {...register("email", {
            required: { value: true, message: "An email is required" },
            pattern: {
              value: EMAIL_VALIDATION_PATTERN,
              message: "Invalid email address.",
            },
          })}
        />

        <Controller
          disabled={!isHydrated}
          name="client_location"
          control={control}
          defaultValue=""
          rules={{
            required: { value: true, message: "Please select a State." },
          }}
          render={({ field }) => {
            const { onChange, onBlur, ref, ...fields } = field;

            return (
              <SelectInput
                variant="form"
                label="State"
                name="client_location"
                data={LIST_OF_STATES_FULL_NAMES}
                onChange={onChange}
                onBlur={onBlur}
                formBag={formBag}
                {...fields}
              />
            );
          }}
        />

        <div className={styles.action_container}>
          <Button
            data-test="contact-form-submit-btn"
            className={styles.submit_btn}
            buttonType="submit"
            disabled={formState.isSubmitting || !isHydrated}
            text={"Submit"}
          />
          {formSuccess && (
            <div
              data-test="contact-form-success"
              className={styles.form_success}
            >
              <span>
                <FormSuccessValidationIcon />
              </span>
              You're now subscribed.
            </div>
          )}
        </div>
      </form>
    </>
  );
};

export default ContactForm;
