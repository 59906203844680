import React, { useMemo, useState } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { get, orderBy, partition } from "lodash";
import PropTypes from "prop-types";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";
import MiniProfile from "../MiniProfile/MiniProfile";
import Reveal from "../Reveal/Reveal";
import { mapAirtableProviderNodesToPrismic, slugify } from "../../prismic/util";
import { TEAM_PATHNAME } from "../../lib/constants";
import { useWindowDimensions } from "../../lib/hooks";
import { getProvidersWithAvailability } from "../../lib/util/getProvidersWithAvailability";
import {
  useSortProvidersByAvailability,
  useMatchingProviders,
} from "../ProvidersList/hooks";
import {
  ALL_LOCATIONS_VALUE,
  SEARCH_SPECIALTY_KEY,
  SEARCH_INSURANCE_KEY,
  SEARCH_LANGUAGE_KEY,
  SEARCH_LICENSE_KEY,
  SEARCH_MODALITY_KEY,
} from "../ProvidersList/hooks/useProviderListFilters";
import { stringToArray } from "../../lib/util";

import styles from "./providers.module.scss";

const defaultHeading = "Meet Our Providers";

let cardColor = 1;

const colorOptions = {
  1: "#F9EECF",
  2: "#BFCEB9",
  3: "#EB991B",
  4: "#9DE0C9",
  5: "#F0C9BC",
};

const Providers = props => {
  const {
    heading,
    providers,
    show_leads,
    show_therapists,
    show_coaches,
    display_limit,
    display_location_filter,
    providersAvailability,
    state_location_filter,
    specialty_filter,
    modality_filter,
    language_filter,
    insurance_filter,
    license_filter,
  } = props;

  const filters = {
    location: state_location_filter
      ? state_location_filter.document.uid
      : ALL_LOCATIONS_VALUE,
    specialties: specialty_filter ? stringToArray(specialty_filter) : [],
    modalities: modality_filter ? stringToArray(modality_filter) : [],
    languages: language_filter ? stringToArray(language_filter) : [],
    licenses: license_filter ? stringToArray(license_filter) : [],
    insurances: insurance_filter ? stringToArray(insurance_filter) : [],
    name: "",
    availability: "",
  };

  const [dropdownFilter, setDropdownFilter] = useState(ALL_LOCATIONS_VALUE);
  const { isMobile } = useWindowDimensions();

  //Static data used when the component is used in a pageDetail template
  const staticData = useStaticQuery(providersQuery);
  const staticProvidersData = useMergePrismicPreviewData(staticData);
  const rawProviders = get(staticProvidersData, "allAirtable.edges", []);

  // If no providers are passed in, use the static data
  const providersData =
    providers || mapAirtableProviderNodesToPrismic(rawProviders);

  const providersWithAvailability = getProvidersWithAvailability(
    providersData,
    providersAvailability
  );

  const { available, unavailable } = useSortProvidersByAvailability(
    providersWithAvailability
  );

  const [leadAvailableProviders, restAvailableProviders] = partition(
    available,
    p => p.node.data?.role === "Lead"
  );

  const orderedProviders = useMemo(
    () => [
      ...leadAvailableProviders,
      ...restAvailableProviders,
      ...unavailable,
    ],
    [leadAvailableProviders, restAvailableProviders, unavailable]
  );

  const filteredProviders = useMatchingProviders(orderedProviders, filters);

  //filter  providers based on location using the dropdown filter
  const uniqueStateLocations = [
    ...new Set(filteredProviders.map(item => item.node.data.state_location)),
  ];
  uniqueStateLocations.sort();

  const currentProviders = useMemo(() => {
    if (dropdownFilter === ALL_LOCATIONS_VALUE) {
      return filteredProviders;
    } else if (dropdownFilter) {
      return filteredProviders.filter(
        p => slugify(p.node.data.state_location) === dropdownFilter
      );
    }
  }, [dropdownFilter, filteredProviders]);

  const count = currentProviders.length;

  const limit = isMobile ? 5 : display_limit || 10;

  if (currentProviders.length === 0) return null;

  const getShowAllProvidersUrl = () => {

    const search = Object.entries({
      [SEARCH_SPECIALTY_KEY]: filters.specialties.join("+"),
      [SEARCH_MODALITY_KEY]: filters.modalities.join("+"),
      [SEARCH_LANGUAGE_KEY]: filters.languages.join("+"),
      [SEARCH_LICENSE_KEY]: filters.licenses.join("+"),
      [SEARCH_INSURANCE_KEY]: filters.insurances.join("+"),
    })
      .filter(([, value]) => value.length > 0)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");

    if (state_location_filter) {
      return `/${TEAM_PATHNAME}/${state_location_filter.document.uid}/?${search}`;
    }

    if (uniqueStateLocations.length === 1) {
      return `/${TEAM_PATHNAME}/${slugify(uniqueStateLocations[0])}/?${search}`;
    }

    if (dropdownFilter !== ALL_LOCATIONS_VALUE) {
      return `/${TEAM_PATHNAME}/${dropdownFilter}/?${search}`;
    }

    return `/${TEAM_PATHNAME}/?${search}`;
  };

  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <Reveal className={styles.header}>
          <h2>{heading?.text || defaultHeading}</h2>
          <div className={styles.filter}>
            {display_location_filter && (
              <>
                <select onChange={e => setDropdownFilter(e.target.value)}>
                  <option value={ALL_LOCATIONS_VALUE}>All Providers</option>
                  {uniqueStateLocations.map(location => {
                    return (
                      <option value={slugify(location)} key={slugify(location)}>
                        {location}
                      </option>
                    );
                  })}
                </select>
              </>
            )}
          </div>
        </Reveal>
        <div className={styles.list}>
          {currentProviders.map((provider, i) => {
            if (i === 0 || cardColor > 4) cardColor = 1;
            else cardColor += 1;

            const uid = provider.node.uid;
            const {
              name,
              job_title,
              profile_photo,
              office_locations,
              state_location,
            } = provider.node.data;

            const locationName =
              office_locations &&
              office_locations
                .reduce((acc, office) => {
                  let locationText = "";
                  if (office) {
                    locationText = `${office.name}, ${state_location}`;
                  }

                  acc.push(locationText);
                  return acc;
                }, [])
                .join(" | ");

            return (
              <Reveal
                className={`${styles.item} ${
                  limit && i > limit - 1 && styles.hidden
                }`}
                key={i}
              >
                <MiniProfile
                  header={name}
                  subheader={job_title ? job_title : locationName}
                  image={profile_photo}
                  uid={uid}
                  cardColor={colorOptions[cardColor]}
                />
              </Reveal>
            );
          })}
        </div>
        {limit && count > limit && (
          <div className={styles.loadMore}>
            <Link to={getShowAllProvidersUrl()} className={styles.link}>
              See all {count} providers
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

Providers.propTypes = {
  display_limit: PropTypes.number,
  display_location_filter: PropTypes.bool,
};

Providers.defaultProps = {
  display_limit: null,
  display_location_filter: false,
};

export default Providers;

const providersQuery = graphql`
  query ActiveProvidersQuery {
    allAirtable(
      filter: {
        data: {
          Status: { eq: "Active" }
          Publish_to_Website: { eq: true }
          Green_Light_Done: { eq: "checked" }
        }
      }
      sort: { data: { Last_Name: ASC } }
    ) {
      totalCount
      edges {
        node {
          ...ProviderDetailsDef
        }
      }
    }
  }
`;

export const fragment = graphql`
  fragment ProviderDetailsDef on Airtable {
    recordId
    data {
      Display_Name__for_website_
      Status
      Credentials
      Specialties
      Location
      Insurance_Accepted
      State__for_website_view_
      Role__for_website_view_
      Job_Title__for_website_view_
      Additional_Language_Capabilities
      Quote__for_website_
      NPI_Number
      Treatment_Modalities
      Edited_Headshot {
        localFiles {
          url
        }
      }
    }
  }
`;

export const fragmentSlice = graphql`
  fragment SliceProviders on PrismicPageContainerDataBodySectionProviders {
    slice_type
    primary {
      show_therapists
      show_coaches
      show_leads
      display_location_filter
      display_limit
      specialty_filter
      modality_filter
      language_filter
      insurance_filter
      license_filter
      heading {
        text
      }
      state_location_filter {
        document {
          ... on PrismicStateLocation {
            uid
          }
        }
      }
    }
  }
`;

export const locationDetailProviderFragment = graphql`
  fragment LocationDetailSliceProviders on PrismicStateLocationDataBodySectionProviders {
    slice_label
    slice_type
    primary {
      show_therapists
      show_coaches
      show_leads
      display_location_filter
      display_limit
      specialty_filter
      modality_filter
      language_filter
      insurance_filter
      license_filter
      heading {
        richText
        html
        text
      }
    }
  }
`;
