import React, { useCallback, useEffect, useState } from "react";
import { graphql, navigate } from "gatsby";
import PropTypes from "prop-types";
import Reveal from "../Reveal/Reveal";
import TopicSection from "./TopicSection";
import styles from "./topics-lists.module.scss";

const TopicsLists = ({ topics, location }) => {
  const [topicIndex, setTopicIndex] = useState(0);

  const calculateFragmentIndex = useCallback(() => {
    // if there is a hash used in the url set state of topic
    if (location.hash) {
      // remove all special characters to get normal string
      const transformedString = location.hash
        .replace(/[^a-z]+/gi, " ")
        .toLowerCase()
        .trim();

      // compare the transformedString to
      const newTopicIndex = topics.findIndex(
        topic => topic.primary.topic.text.toLowerCase() === transformedString
      );

      return newTopicIndex;
    }

    // if no hash used, set state to the first in topics array
    return 0;
  }, [location.hash, topics]);

  // programmatically change the url hash on new topic selection
  const selectTopic = index => {
    setTopicIndex(index);

    const category = topics[index].primary.topic.text
      .toLowerCase()
      .replace(" ", "_");

    navigate(`${location.pathname}#${category}`);
  };

  const currentTopic = topics[topicIndex];

  // programmatically navigate to the specific section via location hash
  useEffect(() => {
    if (location.hash) {
      navigate(location.pathname + location.hash);
    }
  }, [location.pathname, location.hash]);

  useEffect(() => {
    // Onload for setting initial state of any topic linked via url hash.
    // We need to wrap in a setTimeout because there is some funky behavior
    // via gatsby urls and slashes that overrides a "normal" onload.
    setTimeout(() => {
      if (location.hash) {
        setTopicIndex(calculateFragmentIndex());
      }
    });
  }, [location.hash, calculateFragmentIndex]);

  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <Reveal className={styles.topics}>
          {topics.map((topic, i) => (
            <div
              className={`
                    ${styles.topicWrapper}
                    ${i === topicIndex && styles.active}
                    `}
              key={i}
            >
              <button
                type="button"
                className={styles.topicItem}
                onClick={() => selectTopic(i)}
                onKeyDown={() => selectTopic(i)}
              >
                {topic.primary.topic && <span>{topic.primary.topic.text}</span>}
              </button>
              <dl className={`${styles.list} ${styles.listMobile}`}>
                {topic.items.map((qaItem, j) => (
                  <TopicSection {...qaItem} topic={topic} index={j} key={j} />
                ))}
              </dl>
            </div>
          ))}
        </Reveal>
        <dl className={styles.list}>
          {currentTopic?.items.map((qaItem, k) => (
            <TopicSection
              {...qaItem}
              key={k}
              topic={topics[topicIndex]}
              index={k}
            />
          ))}
        </dl>
      </div>
    </div>
  );
};

TopicsLists.propTypes = {
  topics: PropTypes.array.isRequired,
};

export default TopicsLists;

export const fragment = graphql`
  fragment SliceFaq on PrismicPageContainerDataBodyFaqTopicSection {
    slice_type
    slice_label
    primary {
      topic {
        text
      }
    }
    items {
      question {
        text
      }
      answer {
        html
      }
    }
  }
`;
