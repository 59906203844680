import React, { useState } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@reach/accordion";
import Reveal from "../Reveal/Reveal";
import "@reach/accordion/styles.css";
import styles from "./collapsible-list.module.scss";

const CollapsibleList = ({ heading, items }) => {
  const [openItem, setOpenItem] = useState(0);

  const toggleItem = index => setOpenItem(index);

  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <Reveal className={styles.header}>
          <h2>{heading.text}</h2>
        </Reveal>
      </div>
      <div className={styles.inner}>
        <Accordion index={openItem} onChange={toggleItem}>
          {items.map((item, index) => {
            const isItemOpen = openItem === index;
            return (
              <AccordionItem
                key={`accordian-i-${index}`}
                className={`${styles.item} ${isItemOpen ? styles.active : ""}`}
              >
                <h4>
                  <AccordionButton className={styles.itemHeader}>
                    {item.item_heading.text}
                    <div className={styles.itemIcon}>
                      {isItemOpen ? "-" : "+"}
                    </div>
                  </AccordionButton>
                </h4>
                <AccordionPanel>
                  <div
                    className={styles.itemCopy}
                    dangerouslySetInnerHTML={{ __html: item.item_text.html }}
                  />
                </AccordionPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      </div>
    </div>
  );
};

CollapsibleList.propTypes = {
  heading: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
};

export default CollapsibleList;

// Prismic "Section Collapsible" slice
export const fragment = graphql`
  fragment SliceCollapsible on PrismicPageContainerDataBodySectionCollapsible {
    slice_type
    primary {
      heading {
        text
      }
    }
    items {
      item_heading {
        text
      }
      item_text {
        html
      }
    }
  }
`;
