import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { PrismicRichText } from "@prismicio/react";
import get from "lodash/get";
import ParallaxImages from "../ParallaxImages/ParallaxImages";
import Reveal from "../Reveal/Reveal";
import styles from "./home-why-alt.module.scss";
import Image from "../Image/Image";

const HomeWhyAlt = ({
  heading,
  side_decoration,
  side_decoration_mobile,
  items,
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <Reveal className={styles.header}>
          <PrismicRichText
            field={heading.richText}
            components={{
              heading1: ({ children }) => (
                <h1 className={styles.heading}>{children}</h1>
              ),
              heading2: ({ children }) => (
                <h2 className={styles.heading}>{children}</h2>
              ),
              heading3: ({ children }) => (
                <h3 className={styles.heading}>{children}</h3>
              ),
              heading4: ({ children }) => (
                <h4 className={styles.heading}>{children}</h4>
              ),
              heading5: ({ children }) => (
                <h5 className={styles.heading}>{children}</h5>
              ),
              heading6: ({ children }) => (
                <h6 className={styles.heading}>{children}</h6>
              ),
            }}
          />
        </Reveal>
        <div className={styles.blocks}>
          {items &&
            items.map((card, i) => {
              const title = get(card, "title.richText");
              const description = get(card, "description.richText");
              const images = [card.image];

              return (
                <Reveal className={styles.block} key={i}>
                  <div className={styles.images}>
                    {images && <ParallaxImages images={images} />}
                  </div>
                  <div className={styles.block_content}>
                    <PrismicRichText
                      field={title}
                      components={{
                        heading1: ({ children }) => (
                          <h1 className={styles.title}>{children}</h1>
                        ),
                        heading2: ({ children }) => (
                          <h2 className={styles.title}>{children}</h2>
                        ),
                        heading3: ({ children }) => (
                          <h3 className={styles.title}>{children}</h3>
                        ),
                        heading4: ({ children }) => (
                          <h4 className={styles.title}>{children}</h4>
                        ),
                        heading5: ({ children }) => (
                          <h5 className={styles.title}>{children}</h5>
                        ),
                        heading6: ({ children }) => (
                          <h6 className={styles.title}>{children}</h6>
                        ),
                      }}
                    />
                    <PrismicRichText
                      field={description}
                      components={{
                        paragraph: ({ children }) => (
                          <p className={styles.description}>{children}</p>
                        ),
                      }}
                    />
                  </div>
                </Reveal>
              );
            })}
        </div>
      </div>
      <div className={styles.decoration_wrapper}>
        <Image
          imageObj={side_decoration}
          className={styles.decoration}
          alt=""
        />
      </div>
      <div className={styles.decoration_wrapper_mobile}>
        <Image
          imageObj={side_decoration_mobile}
          className={styles.decoration}
          alt=""
        />
      </div>
    </div>
  );
};

HomeWhyAlt.propTypes = {
  heading: PropTypes.object,
  items: PropTypes.array.isRequired,
  side_decoration: PropTypes.object,
};

HomeWhyAlt.defaultProps = {
  items: [],
};

export default HomeWhyAlt;

// Prismic "Home Why Alt" slice
export const fragment = graphql`
  fragment SliceWhyAlt on PrismicPageContainerDataBodySectionWhyAlt {
    slice_type
    slice_label
    primary {
      heading {
        richText
      }
      side_decoration {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
      side_decoration_mobile {
        gatsbyImageData(width: 300, layout: CONSTRAINED)
      }
    }
    items {
      title {
        richText
      }
      description {
        richText
      }
      image {
        alt
        gatsbyImageData(width: 600, layout: CONSTRAINED)
        localFile {
          childImageSharp {
            gatsbyImageData(width: 600, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;
