import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";
import { get } from "lodash";
import { CAREERS_PATH } from "../../lib/constants";
import styles from "./job-board.module.scss";
import usePinJobPost from "./useJobPostPin";

const ClinicBoard = () => {
  const staticData = useStaticQuery(query);
  const { allGreenhouseDepartment } = useMergePrismicPreviewData(staticData);
  const jobObj = get(allGreenhouseDepartment, "edges[0]", {})?.node;
  const jobData = jobObj?.jobs;
  usePinJobPost(jobObj);

  const renderJobs = jobData?.map(job => (
    <div className={styles.card} key={job.gh_Id}>
      <Link to={`${CAREERS_PATH}${job.gh_Id}`}>
        <div className={styles.title_container}>
          <h3 className={styles.title}>{job.title}</h3>
          <div className={styles.right_chevron} aria-hidden="true" />
        </div>
        <div className={styles.location}>{job.location.name}</div>
      </Link>
    </div>
  ));

  return <div className={styles.board_container}>{renderJobs}</div>;
};

export default ClinicBoard;

const query = graphql`
  query ClinicJobs {
    allGreenhouseDepartment(filter: { name: { eq: "Clinicians" } }) {
      edges {
        node {
          id
          name
          jobs {
            content
            id
            gh_Id
            title
            location {
              name
            }
          }
        }
      }
    }
  }
`;
