import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Image from "../Image/Image";
import { isEmpty } from "../../prismic/util";
import styles from "./parallax-images.module.scss";

const ParallaxImages = ({ images, wideBackground, imageRatio }) => {
  const [offset, setOffset] = useState(-0.5);
  const isListening = useRef(false);
  const saveRef = useRef(null);

  useEffect(() => {
    if (!isListening.current) {
      window.addEventListener("scroll", parallax);
      isListening.current = true;
    }
    parallax();
    return () => {
      if (isListening.current) {
        window.removeEventListener("scroll", parallax);
        isListening.current = false;
      }
    };
  }, []);

  const parallax = () => {
    if (!saveRef.current || !isListening.current || window.document.hidden)
      return;
    const top = saveRef.current.getBoundingClientRect().top;
    const height = saveRef.current.getBoundingClientRect().bottom - top;
    let percentShown =
      (window.innerHeight - top) / (height + window.innerHeight);
    if (percentShown < 0) percentShown = 0;
    else if (percentShown > 1) percentShown = 1;
    setOffset(percentShown - 0.5);
  };

  const alignBackground = wideBackground ? wideBackground.toLowerCase() : null;
  const rootStyling = {
    paddingBottom: imageRatio ? `${imageRatio}%` : "100%",
  };

  return (
    <div className={styles.root} ref={saveRef} style={rootStyling}>
      {images.map((image, i) => {
        // override default styling
        const { imgStyle = {}, className = {} } = image;

        const generateImgStyle = !isEmpty(imgStyle)
          ? imgStyle
          : {
              objectFit: "contain",
              objectPosition: "50% 50%",
              width: "100%",
              height: "100%",
            };

        return (
          <div
            className={` ${styles.item} ${
              alignBackground && i === 0 ? styles[alignBackground] : ""
            }`}
            key={i}
          >
            <Image
              imageObj={image}
              alt=""
              style={{
                transform: `translateY(${
                  offset * -60 * (i * (0.5 - (i % 2)))
                }px)`,
                width: "100%",
                height: "90%",
              }}
              imgStyle={generateImgStyle}
              className={`${!isEmpty(className) ? className : ""}`}
            />
          </div>
        );
      })}
    </div>
  );
};

ParallaxImages.propTypes = {
  images: PropTypes.array,
  wideBackground: PropTypes.string,
  imageRatio: PropTypes.number,
};

ParallaxImages.defaultProps = {
  images: [],
  wideBackground: null,
  imageRatio: null,
};

export default ParallaxImages;
