import React, { useEffect, useRef } from "react";
import { useZendeskScript } from "../../lib/hooks/useZendeskScript";
import styles from "./zendesk-btn.module.scss";

export const DENY_PAGE_LIST_ZENDESK = ["index", "terms", "privacy", "hotlines", "provider-show"];

// Placeholder button initially used to help defer zendesk from being
// loaded, until clicked, to help with page performance scores.
// https://codeutility.org/javascript-reduce-the-impact-of-third-party-code-zendesk-stack-overflow/
export const ZendeskBtn = () => {
  const zdBtnRef = useRef();
  const { zdLoading, handleZdBtnClick, zdLoadedStatus } =
    useZendeskScript(zdBtnRef);

  useEffect(() => {
    if (zdLoadedStatus === "idle") {
      // If no script loaded, show the placeholder button
      zdBtnRef.current.style.opacity = 1;
    }
  }, [zdLoadedStatus]);

  return (
    <button
      ref={zdBtnRef}
      onClick={handleZdBtnClick}
      className={styles.root}
      disabled={zdLoading}
    >
      <span className={styles.icon}>?</span>
      <span className={styles.btnLabel}>Support</span>
    </button>
  );
};
