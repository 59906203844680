
import { PINNED_JOB } from "../../lib/constants";

const usePinJobPost = (jobNode) => {
  if (PINNED_JOB[jobNode.name]) {
    const pinnedIdx = jobNode.jobs.findIndex(job => job.gh_Id === PINNED_JOB[jobNode.name]);
    
    if (pinnedIdx > 0) {
      jobNode.jobs.unshift(jobNode.jobs.splice(pinnedIdx, 1)[0]);
    }
  }
};

export default usePinJobPost;