import React from "react";
import { graphql } from "gatsby";
import Button from "../Button/Button";
import styles from "./inline-ctas.module.scss";

const InlineCtas = ({ items }) => (
  <div className={styles.root}>
    {items.map((cta, i) => (
      <Button
        text={cta.cta_text}
        url={cta.cta_link.url}
        key={`${cta.cta_link.url}-${i}`}
      />
    ))}
  </div>
);

export default InlineCtas;

export const fragment = graphql`
  fragment SliceCtaInline on PrismicPageContainerDataBodyCtaInline {
    slice_type
    items {
      cta_link {
        url
      }
      cta_text
    }
  }
`;
