import React, { useEffect } from "react";
import * as PrimitiveDialog from "@radix-ui/react-dialog";
import CloseIcon from "../../images/close-icon.inline.svg";

import styles from "./dialog.module.scss";

const Dialog = ({ open, onOpenChange, children }) => {
  //TODO: investigate if there is a better way to solve this
  // prevent scrolling the background when the dialog is open
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100vh";
    } else {
      document.body.style.overflow = "unset";
      document.body.style.height = "unset";
    }
  }, [open]);

  return (
    <PrimitiveDialog.Root open={open} onOpenChange={onOpenChange}>
      <PrimitiveDialog.Portal>
        <PrimitiveDialog.Overlay className={styles.DialogOverlay} />
        <PrimitiveDialog.Content className={styles.DialogContentContainer}>
          <div className={styles.DialogContent}>{children}</div>
          <PrimitiveDialog.Close asChild>
            <button className={styles.IconButton} aria-label="Close">
              <CloseIcon />
            </button>
          </PrimitiveDialog.Close>
        </PrimitiveDialog.Content>
      </PrimitiveDialog.Portal>
    </PrimitiveDialog.Root>
  );
};

export default Dialog;
