import React from "react";
import PropTypes from "prop-types";
import Button from "../Button/Button";
import styles from "./post-content.module.scss";

const PostContent = ({
  post_text,
  cta_text,
  cta_link,
  post_footnote,
  display_width,
}) => {
  return (
    <div
      className={`${styles.root} ${
        display_width === "wide" ? styles.wide : ""
      }`}
    >
      <div dangerouslySetInnerHTML={{ __html: post_text.html }} />

      {cta_text && (
        <div className={styles.ctaWrapper}>
          <Button text={cta_text} url={`${cta_link.url}`} isInline />
        </div>
      )}

      <div dangerouslySetInnerHTML={{ __html: post_footnote.html }} />
    </div>
  );
};

PostContent.propTypes = {
  post_text: PropTypes.object,
  cta_text: PropTypes.string,
  cta_link: PropTypes.object,
  post_footnote: PropTypes.object,
  display_width: PropTypes.string,
};

PostContent.defaultProps = {
  post_text: null,
  cta_text: null,
  cta_link: null,
  post_footnote: null,
  display_width: "narrrow",
};

export default PostContent;
