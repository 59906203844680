import React from "react";
import JobBoardFilter from "./JobBoardFilter";
import { getUniqueListBy, slugify } from "../../prismic/util";
import { useJobBoardContext } from "./JobBoardContext";
import styles from "./job-board.module.scss";

const JobBoardFilters = () => {
  // State management of the Filter
  const {
    rawData: data,
    deptFilter,
    handleDeptFilterChange,
    officeFilter,
    handleOfficeFilterChange,
  } = useJobBoardContext();

  // Department filter select options
  const deptFilterOptions = [...data].reduce(
    (acc, { node }) => {
      acc.push({ label: node.name, value: slugify(node.name) });
      return acc;
    },
    [{ label: "All Departments", value: "" }]
  );

  // Office filter select options
  const getOfficeOptions = [...data].flatMap(({ node }) => {
    const offices = [{ label: "All Offices", value: "" }];
    for (let i = 0; i < node.jobs.length; i++) {
      const currJobPost = node.jobs[i];
      const name = currJobPost.location.name;
      const slug = slugify(name);

      const office = {
        label: name,
        value: slug,
      };
      offices.push(office);
    }

    return offices;
  });

  // Dedupe office options for a unique list
  const finalOfficeOptions = getUniqueListBy(getOfficeOptions, "value");

  return (
    <div className={styles.filter_container}>
      <JobBoardFilter
        label="Department"
        filter={deptFilter}
        handleChange={handleDeptFilterChange}
        optionsList={deptFilterOptions}
      />
      <JobBoardFilter
        label="Office"
        filter={officeFilter}
        handleChange={handleOfficeFilterChange}
        optionsList={finalOfficeOptions}
      />
    </div>
  );
};

export default JobBoardFilters;
