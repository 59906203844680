import React from "react";
import { Link } from "gatsby";
import { FocusOn } from "react-focus-on";
import {
  Menu,
  MenuButton,
  MenuLink,
  MenuPopover,
  MenuItems,
} from "@reach/menu-button";
import Image from "../Image/Image";
import { stringify } from "querystring";
import "@reach/menu-button/styles.css";
import styles from "./header.module.scss";

export const NavTypes = {
  textLink: "textLink",
  visualLink: "visualLink",
  dropdown: "dropdown",
  nestedDropdown: "nestedDropdown",
  dropdownTextLink: "dropdownTextLink",
  nestedLinkGroup: "nestedLinkGroup",
  nestedDropdownGroup: "nestedDropdownGroup",
};

// store of different types to render
const menuRenderingStore = {
  [NavTypes.textLink]: (navLink, undefined, trackMixpanelNavLinkClick) => {
    // external link vs internal link styling
    if (navLink.externalLink) {
      return (
        <a
          className={styles.link}
          href={navLink.url}
          target="_blank"
          rel="noreferrer"
          key={`${navLink.label}-${navLink.id}`}
          onClick={() => {
            trackMixpanelNavLinkClick(
              navLink.url,
              navLink.label,
              navLink.category
            );
          }}
        >
          {navLink.label}
        </a>
      );
    } else {
      return (
        <Link
          to={navLink.url}
          className={styles.link}
          activeClassName={styles.active}
          key={`${navLink.label}-${navLink.id}`}
          onClick={() => {
            trackMixpanelNavLinkClick(
              navLink.url,
              navLink.label,
              navLink.category
            );
          }}
        >
          {navLink.label}
        </Link>
      );
    }
  },
  [NavTypes.dropdownTextLink]: (
    navLink,
    isMobile,
    trackMixpanelNavLinkClick
  ) => {
    if (isMobile) {
      // external link vs internal link styling
      if (navLink.externalLink) {
        return (
          <a
            className={styles.dropdownLink}
            href={navLink.url}
            target="_blank"
            rel="noreferrer"
            key={`${navLink.label}-${navLink.id}`}
            onClick={() => {
              trackMixpanelNavLinkClick(
                navLink.url,
                navLink.label,
                navLink.category
              );
            }}
          >
            {navLink.label}
          </a>
        );
      } else {
        return (
          <Link
            to={navLink.url}
            className={styles.dropdownLink}
            activeClassName={styles.active}
            key={`${navLink.label}-${navLink.id}`}
            onClick={() => {
              trackMixpanelNavLinkClick(
                navLink.url,
                navLink.label,
                navLink.category
              );
            }}
          >
            {navLink.label}
          </Link>
        );
      }
    } else {
      // external link vs internal link styling
      if (navLink.externalLink) {
        return (
          <MenuLink
            className={styles.dropdownLink}
            href={navLink.url}
            target="_blank"
            rel="noreferrer"
            key={`${navLink.label}-${navLink.id}`}
            onClick={() => {
              trackMixpanelNavLinkClick(
                navLink.url,
                navLink.label,
                navLink.category
              );
            }}
          >
            {navLink.label}
          </MenuLink>
        );
      } else {
        return (
          <MenuLink
            as={Link}
            to={navLink.url}
            className={styles.dropdownLink}
            activeClassName={styles.active}
            key={`${navLink.label}-${navLink.id}`}
            onClick={() => {
              trackMixpanelNavLinkClick(
                navLink.url,
                navLink.label,
                navLink.category
              );
            }}
          >
            {navLink.label}
          </MenuLink>
        );
      }
    }
  },
  [NavTypes.visualLink]: (navLink, isMobile) => {
    if (isMobile) {
      return (
        <Link
          key={`${navLink.label}-${navLink.id}`}
          to={navLink.url}
          className={styles.visualLink}
          activeClassName={styles.active}
        >
          <div className={styles.visualLinkImage}>
            {navLink.image && (
              <Image
                imageObj={{ gatsbyImageData: navLink.image.gatsbyImageData }}
                alt={navLink.image.alt}
              />
            )}
          </div>
          <div className={styles.visualLinkCopy}>
            <div className={styles.visualLinkCopyHeader}>{navLink.label}</div>
            <div className={styles.visualLinkCopySubHeader}>
              {navLink.subHeader}
            </div>
          </div>
        </Link>
      );
    }
    return (
      <MenuLink
        key={`${navLink.label}-${navLink.id}`}
        as={Link}
        to={navLink.url}
        className={styles.visualLink}
        activeClassName={styles.active}
      >
        <div className={styles.visualLinkImage}>
          {navLink.image && (
            <Image
              imageObj={{ gatsbyImageData: navLink.image.gatsbyImageData }}
              alt={navLink.image.alt}
            />
          )}
        </div>
        <div className={styles.visualLinkCopy}>
          <div className={styles.visualLinkCopyHeader}>{navLink.label}</div>
          <div className={styles.visualLinkCopySubHeader}>
            {navLink.subHeader}
          </div>
        </div>
      </MenuLink>
    );
  },
};

// take an array of links and recursively transform
// them into components to render
export const recursiveMenuRendering = (
  arr,
  dropdownState,
  handleDropdown,
  refs,
  isMobile,
  trackMixpanelNavLinkClick,
  otherRefs // array of other refs to use in FocusOn component shards
) => {
  const mapLinks = (arr, idx) => {
    if (idx >= arr.length) return -1;

    return arr.map((item, index) => {
      if (item) {
        const containerKey = `${item.label}-${item.id}-${index}`;

        if (item.hasOwnProperty("children")) {
          // There are 3 types of objects with children
          // 1. nestedLinkGroup, 2. nestedDropdownGroup, 3. dropdown
          if (item.type === NavTypes.nestedLinkGroup) {
            return (
              <div key={containerKey} className={styles.locationItemGroup}>
                <span className={styles.subHeader}>{item.label}</span>
                {mapLinks(item.children, 0)}
              </div>
            );
          }

          if (item.type === NavTypes.nestedDropdownGroup) {
            return (
              <div key={containerKey} className={styles.locationItemGroup}>
                <span className={styles.subHeader}>{item.label}</span>
                {mapLinks(item.children, 0)}
              </div>
            );
          }

          if (item.type === NavTypes.nestedDropdown) {
            return (
              <button
                className={`${styles.nestedDropdown} ${
                  dropdownState[item.id] ? styles.active : ""
                } ${styles.noBtn}`}
                key={containerKey}
                onClick={() => handleDropdown(item)}
                aria-expanded={dropdownState[item.id]}
                aria-controls={`${item.id}-menu-list`}
                aria-haspopup={true}
              >
                <span>{item.label}</span>
                <div
                  id={`${item.id}-menu-list`}
                  className={styles.nestedDropdownContainer}
                  hidden={!dropdownState[item.id]}
                  role="menu"
                >
                  <div className={styles.nestedDropdownLinks}>
                    {mapLinks(item.children, 0)}
                  </div>
                </div>
              </button>
            );
          }

          // render regular dropdown ie. top level header dropdown
          // if isMobile, use react-focus-on library
          // if desktop, use accessible dropdown menu list component
          return (
            <div
              className={`${styles.locations} ${
                dropdownState[item.id] ? styles.active : ""
              } `}
              key={containerKey}
            >
              {isMobile ? (
                <>
                  <button
                    className={styles.noBtn}
                    onClick={() => handleDropdown(item)}
                    aria-expanded={dropdownState[item.id]}
                    aria-controls={`${item.id}-menu-list`}
                    aria-haspopup={true}
                    {...(refs ? { ref: refs[item.id] } : "")}
                  >
                    {item.label}
                  </button>
                  <div
                    id={`${item.id}-menu-list`}
                    className={`${styles.locationsDropdown} ${
                      arr.length - 1 === index
                        ? styles.locationsDropdownLast
                        : ""
                    }`}
                    hidden={!dropdownState[item.id]}
                  >
                    <div className={styles.dropdownItems}>
                      {mapLinks(item.children, 0)}
                    </div>
                    {item.seeMoreUrl && (
                      <Link
                        to={item.seeMoreUrl}
                        className={styles.dropdownButton}
                      >
                        {`See All ${item.label}`}
                      </Link>
                    )}
                  </div>
                </>
              ) : (
                <Menu>
                  <MenuButton
                    id={`menu-btn-${item.id}-${index}`}
                    className={styles.noBtn}
                  >
                    {item.label}
                  </MenuButton>
                  <MenuPopover
                    className={`${styles.locationsDropdown} ${
                      arr.length - 1 === index
                        ? styles.locationsDropdownLast
                        : ""
                    }`}
                    portal={false}
                  >
                    <MenuItems className={styles.menuItemsContainer}>
                      {mapLinks(item.children, 0)}
                      {item.seeMoreUrl && (
                        <MenuLink
                          as={Link}
                          to={item.seeMoreUrl}
                          className={styles.dropdownButton}
                          key={stringify(item.label)}
                        >
                          {`See All ${item.label}`}
                        </MenuLink>
                      )}
                    </MenuItems>
                  </MenuPopover>
                </Menu>
              )}
            </div>
          );
        } else {
          // render the specific item
          return menuRenderingStore[item.type](
            item,
            isMobile,
            trackMixpanelNavLinkClick
          );
        }
      }

      return null;
    });
  };

  // initial recursive call
  return mapLinks(arr, 0);
};
