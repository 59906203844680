import React from "react";
import PropTypes from "prop-types";
import styles from "./post-footer.module.scss";

const PostFooter = ({ footer_text }) => {
  return (
    <div className={styles.root}>
      <div dangerouslySetInnerHTML={{ __html: footer_text.html }} />
    </div>
  );
};

PostFooter.propTypes = {
  footer_text: PropTypes.object,
};

PostFooter.defaultProps = {
  footer_text: null,
};

export default PostFooter;
