import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

const AnimatedLine = ({ linePath, lineLength, height, width }) => {
  const [percentShown, setPercentShown] = useState(0);
  const isListening = useRef(false);
  const saveRef = useRef(null);

  useEffect(() => {
    if (!isListening.current && typeof window !== "undefined") {
      window.addEventListener("scroll", reveal);
      isListening.current = true;
    }
    reveal();

    if (typeof window !== "undefined") {
      return () => {
        window.removeEventListener("scroll", reveal);
      };
    }
  }, []);

  const reveal = () => {
    if (!saveRef.current || !isListening.current || window.document.hidden)
      return;
    const delta = saveRef.current.getBoundingClientRect().top;
    const height = saveRef.current.getBoundingClientRect().bottom - delta;
    let percentShown = (window.innerHeight - delta) / window.innerHeight;
    percentShown = (percentShown - 0.4) * (window.innerHeight / height);
    if (percentShown < 0) percentShown = 0;
    else if (percentShown > 1) percentShown = 1;
    setPercentShown(percentShown);
  };

  return (
    <svg height={height} width={width} ref={saveRef}>
      <path
        strokeDasharray={`${lineLength}, ${lineLength}`}
        strokeDashoffset={lineLength * (1 - percentShown)}
        fill="none"
        stroke="#ca9673"
        strokeWidth="1px"
        d={linePath}
      />
    </svg>
  );
};

AnimatedLine.propTypes = {
  linePath: PropTypes.string.isRequired,
  lineLength: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

export default AnimatedLine;
