import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { InputStyling } from "../InputStyling/InputStyling";
import { ValidationIcon } from "../ValidationIcon/ValidationIcon";
import { ValidationText } from "../ValidationText/ValidationText";
import { useInputValidation, useToggleInputLabel } from "../../../../lib/hooks";
import styles from "./text-input.module.scss";

/**
 * To use TextInput in form, ensure to spread the return from the register
 * function via react-hook-forms. Refer to ContactForm for example. This is
 * required to pass form input props. Any form validation is also done in
 * that register function.
 */
export const TextInput = forwardRef(
  (
    { variant, label, showLabel, name, onBlur, type, formBag, ...props },
    ref
  ) => {
    const isFormVariant = variant === "form";
    const { hideLabel, setHideLabel } = useToggleInputLabel();

    const { errorMessage, isTouched, isValid, validityStyles, focusStyles } =
      useInputValidation(variant, name, formBag, !hideLabel);

    const hasValue =
      (!isFormVariant && Boolean(props.value)) ||
      (isFormVariant && Boolean(formBag?.getValues(name)));

    const placeholderValue = hasValue || !hideLabel ? "" : label;

    return (
      <div className={styles.root}>
        <div className={styles.input_group}>
          <label hidden htmlFor={name}>
            {label}
          </label>
          <input
            id={name}
            type={type}
            name={name}
            title={label}
            placeholder={placeholderValue}
            // only show label when user is focused on input
            // or if there is a value
            onFocus={() => {
              if (!hasValue) setHideLabel(false);
            }}
            {...props}
            onBlur={e => {
              // onBlur is only passed when used in form variant
              if (onBlur) onBlur(e);

              // don't show label when input no longer focused
              // and there is no value.
              if (!hasValue) setHideLabel(true);
            }}
            // Below used for default props not automatically passed
            // via form
            {...(!isFormVariant
              ? {
                  value: props.value,
                  onChange: e => props.onChange(e.target.value),
                }
              : "")}
            className={`${styles.text_input} ${
              isFormVariant
                ? styles[validityStyles]
                : hasValue
                ? styles.valid
                : ""
            }`}
            ref={ref}
          />
          <InputStyling
            label={label}
            validityStyles={
              isFormVariant ? validityStyles : hasValue ? "valid" : ""
            }
            hideLabel={!showLabel ? true : !hasValue && hideLabel}
            focusStyles={focusStyles}
          />
          {isFormVariant && (
            <ValidationIcon isTouched={isTouched} isValid={isValid} />
          )}
        </div>
        {isFormVariant && <ValidationText errorMessage={errorMessage} />}
      </div>
    );
  }
);

TextInput.propTypes = {
  variant: PropTypes.oneOf(["default", "form"]),
  label: PropTypes.string.isRequired,
  showLabel: PropTypes.bool,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  formBag: PropTypes.shape({
    getValues: PropTypes.func.isRequired, // used for 'form' variant
    errors: PropTypes.object, // used for 'form' variant
    touchedFields: PropTypes.object, // used for 'form' variant
  }),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

TextInput.defaultProps = {
  variant: "default",
  label: "",
  showLabel: true,
  name: "",
  type: "text",
  formBag: {
    control: {},
    getValues: () => {},
  },
  onChange: () => {},
  onBlur: () => {},
};
