import React from "react";
import { Link } from "gatsby";
import { CAREERS_PATH } from "../../lib/constants";
import { useJobBoardContext } from "./JobBoardContext";
import JobBoardFilters from "./JobBoardFilters";
import styles from "./job-board.module.scss";
import usePinJobPost from "./useJobPostPin";

const DeptBoard = () => {
  const { finalData: jobData } = useJobBoardContext();

  const renderJobs = jobData.map(({ node }) => {
    if (!node?.jobPosts.length) return null;
    usePinJobPost(node);

    return (
      <div key={node.id}>
        <h5 className={styles.category}>{node.name}</h5>
        {node.jobPosts.map(job => (
          <div className={styles.card} key={job.gh_Id}>
            <Link to={`${CAREERS_PATH}${job.gh_Id}`}>
              <div className={styles.title_container}>
                <h3 className={styles.title}>{job.title}</h3>
                <div className={styles.right_chevron} aria-hidden="true" />
              </div>
              <div className={styles.location}>{job.location.name}</div>
            </Link>
          </div>
        ))}
      </div>
    );
  });

  return (
    <>
      <JobBoardFilters />
      <div className={styles.board_container}>{renderJobs}</div>
    </>
  );
};

export default DeptBoard;
