import React, { createContext, useContext } from "react";

const CurrentProviderContext = createContext({
  providerData: null,
});

export const CurrentProviderContextProvider = ({ value, children }) => {
  const current = {
    providerData: value,
  };

  return (
    <CurrentProviderContext.Provider value={current}>
      {children}
    </CurrentProviderContext.Provider>
  );
};

export const useCurrentProviderContext = () => {
  const context = useContext(CurrentProviderContext);

  if (!context) {
    throw new Error(
      `useCurrentProviderContext must be used within a CurrentProviderContextProvider`
    );
  }

  return context;
};
