import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";
import PropTypes from "prop-types";
import Image from "../Image/Image";
import styles from "./hero-images-block.module.scss";

const HeroImageBlocks = ({ items }) => {
  const staticData = useStaticQuery(logoQuery);
  const { logo } = useMergePrismicPreviewData(staticData);

  // configuration for the color of overlay layer
  const overlayColor = [
    "rgb(233 152 47 / 0.5)",
    "rgb(55 108 103 / 0.5)",
    "rgb(207 169 146 / 0.5)",
  ];

  return (
    <div className={styles.root} id="hero">
      <div className={styles.inner}>
        <div className={`${styles.media} ${styles.left}`}>
          <Image className={styles.image} imageObj={items[0].hero_image} />
          <div
            className={styles.overlay}
            style={{ backgroundColor: overlayColor[0] }}
          >
            <Link to={items[0].hero_link.url}>
              <h2 className={styles.heading}>{items[0].hero_heading.text}</h2>
            </Link>
          </div>
        </div>
        <div className={`${styles.media} ${styles.right}`}>
          <Image className={styles.image} imageObj={items[1].hero_image} />
          <div
            className={styles.overlay}
            style={{ backgroundColor: overlayColor[1] }}
          >
            <Link to={items[1].hero_link.url}>
              <h2 className={styles.heading}>{items[1].hero_heading.text}</h2>
            </Link>
          </div>
        </div>
      </div>
      <div className={`${styles.media} ${styles.bottom}`}>
        <Image className={styles.image} imageObj={items[2].hero_image} />
        <div
          className={styles.overlay}
          style={{ backgroundColor: overlayColor[2] }}
        >
          <Link to={items[2].hero_link.url}>
            <h2 className={styles.heading}>{items[2].hero_heading.text}</h2>
          </Link>
        </div>
        <Image className={styles.icon} imageObj={{ localFile: logo }} />
      </div>
    </div>
  );
};

HeroImageBlocks.propTypes = {
  items: PropTypes.array.isRequired,
};

export default HeroImageBlocks;

// Prismic "Section Hero Full Bleed" slice
export const fragment = graphql`
  fragment SliceHeroImageBlocks on PrismicPageContainerDataBodySectionHeroFullBleed {
    slice_type
    slice_label
    items {
      hero_heading {
        text
      }
      hero_link {
        url
      }
      hero_image {
        url
        localFile {
          childImageSharp {
            gatsbyImageData(height: 800, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;

export const logoQuery = graphql`
  {
    logo: file(relativePath: { eq: "octave-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 93, layout: FIXED)
      }
    }
  }
`;
