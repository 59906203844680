import React, { useEffect } from "react";
import { graphql, PageProps } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import get from "lodash/get";
import AnchorSection from "../components/AnchorSection/AnchorSection";
import CollapsibleList from "../components/CollapsibleList/CollapsibleList";
import CondensedHero from "../components/CondensedHero/CondensedHero";
import GridItems from "../components/GridItems/GridItems";
import Hero from "../components/Hero/Hero";
import HeroImageBlocks from "../components/HeroImageBlocks/HeroImageBlocks";
import HomeDetails from "../components/HomeDetails/HomeDetails";
import HomeWhy from "../components/HomeWhy/HomeWhy";
import HomeWhyAlt from "../components/HomeWhyAlt/HomeWhyAlt";
import HotlinesList from "../components/HotlinesList/HotlinesList";
import IframeEmbed from "../components/IframeEmbed/IframeEmbed";
import InlineCtas from "../components/InlineCtas/InlineCtas";
import JobBoard from "../components/JobBoards/JobBoard";
import Layout from "../components/Layout/Layout";
import LegalBody from "../components/LegalBody/LegalBody";
import ListsBlock from "../components/ListsBlock/ListsBlock";
import NumberedBlock from "../components/NumberedBlock/NumberedBlock";
import Press from "../components/Press/Press";
import PressAlt from "../components/PressAlt/PressAlt";
import Providers from "../components/Providers/Providers";
import ProvidersAlt from "../components/ProvidersAlt/ProvidersAlt";
import SeoUtil from "../components/SeoUtil/SeoUtil";
import SortableLocations from "../components/SortableLocations/SortableLocations";
import SplitBlock from "../components/SplitBlock/SplitBlock";
import SplitCtas from "../components/SplitCtas/SplitCtas";
import TableSimple from "../components/TableSimple/TableSimple";
import Team from "../components/Team/Team";
import Testimonials from "../components/Testimonials/Testimonials";
import TopicsLists from "../components/TopicsLists/TopicsLists";
import {
  adjustSlices,
  flattenNode,
  flattenSlice,
  getMetadata,
  getOpenGraphImage,
} from "../prismic/util";
import { MIXPANEL_CTA_EVENT_PATHS } from "../lib/constants";
import { useMixpanel } from "../lib/mixpanel/tracking";

/*
 * Prismic's `slice` feature makes it possible to dynamically
 * create pages with certain sections that have been defined.
 *
 * We check for the `slice_type`, and tie that to a react component
 * that can handle that slice's data.
 *
 * Slices are housed within a `body` object, and contain a `type`
 * and two objects that hold its data:
 * - `primary` for non-repated data, and
 * - `items` for repeated data.
 */
const Page = ({
  data,
  location,
  pageContext,
}: PageProps<Queries.PageDetailQuery>) => {
  const pageUid = get(data, "prismicPageContainer.uid");
  const pageData = flattenNode(get(data, "prismicPageContainer.data"));
  const openGraphImg = getOpenGraphImage(pageData);
  const metadata = getMetadata(pageData, null, null, openGraphImg);
  const pageBody = adjustSlices(pageData);
  const showContactForm = pageData?.show_contact_form ?? true;
  const showBookNowButton = pageData?.show_book_now_button ?? true;
  const hideFooter = pageData?.hide_footer ?? false;

  // Side-wide content (shared across pages)
  const sectionNumbered = get(data, "prismicSectionNumbered.data");

  // Site Banner / Eyebrow
  let banner = null;
  const bannerEdge = get(data, "allPrismicSiteBanner.edges[0]", null);
  if (bannerEdge) {
    const { is_sitewide, banner_page_placements } = bannerEdge.node.data;

    if (
      is_sitewide ||
      banner_page_placements.map(b => b.banner_page?.uid).includes(pageUid)
    ) {
      banner = bannerEdge.node.data;
    }
  }

  const mixpanel = useMixpanel();
  const shouldTrack = MIXPANEL_CTA_EVENT_PATHS.includes(pageUid);

  useEffect(() => {
    if (shouldTrack) {
      mixpanel.track("page_viewed", { "Page UID": pageUid });
    }
  }, [shouldTrack, pageUid, mixpanel]);

  return (
    <Layout
      pageUid={pageUid}
      bannerData={banner}
      showContactForm={showContactForm}
      showBookNowButton={showBookNowButton}
      hideFooter={hideFooter}
    >
      <SeoUtil {...metadata} />
      {pageBody &&
        pageBody.map((slice, i) => {
          // Combine "primary" slice keys at top level
          // with repeated "items" slice key
          const sliceFields = flattenSlice(slice);
          const { slice_type } = slice;
          const key = `${slice.slice_type}-${i}`;

          // "Combined" fields are an array of slices.
          if (slice_type === "cta_block" && slice.combined) {
            return <SplitCtas ctas={slice.combined} key={key} />;
          }
          if (slice_type === "faq_topic_section" && slice.combined) {
            return (
              <TopicsLists
                topics={slice.combined}
                key={key}
                location={location}
              />
            );
          }
          // Site-wide content.
          if (slice_type === "section_how_it_works") {
            return <NumberedBlock {...sectionNumbered} key={key} />;
          }

          switch (slice_type) {
            case "section_hero":
              return (
                <Hero
                  {...sliceFields}
                  key={key}
                  pageUid={pageUid}
                  shouldTrack={shouldTrack}
                />
              );
            case "section_hero_full_bleed":
              return <HeroImageBlocks {...sliceFields} key={key} />;
            case "section_hero_condensed":
              return (
                <CondensedHero
                  {...sliceFields}
                  icon={"/images/question.svg"}
                  key={key}
                />
              );
            case "section_split":
              return (
                <SplitBlock
                  {...sliceFields}
                  image_collage={sliceFields.items}
                  key={key}
                />
              );
            case "section_providers":
              return (
                <Providers
                  {...sliceFields}
                  providers={sliceFields.items}
                  providersAvailability={
                    pageContext.providersAvailability.pagedScheduleAvailabilityV2.availabilityList
                  }
                  key={key}
                />
              );
            case "section_providers_alt":
              return <ProvidersAlt {...sliceFields} key={key} />;
            case "section_detail_carousel":
              return <HomeDetails {...sliceFields} key={key} />;
            case "section_why":
              return <HomeWhy {...sliceFields} key={key} />;
            case "section_why_alt":
              return <HomeWhyAlt {...sliceFields} key={key} />;
            case "section_testimonials":
              return <Testimonials {...sliceFields} key={key} />;
            case "section_press":
              return <Press {...sliceFields} key={key} />;
            case "section_press_alt":
              return <PressAlt {...sliceFields} key={key} />;
            case "section_team":
              return <Team {...sliceFields} key={key} />;
            case "section_location_cards":
              return <SortableLocations {...sliceFields} key={key} />;
            case "section_split_list":
              return <ListsBlock {...sliceFields} key={key} />;
            case "simple_table":
              return <TableSimple {...sliceFields} key={key} />;
            case "cta_inline":
              return <InlineCtas {...sliceFields} key={key} />;
            case "section_collapsible":
              return <CollapsibleList {...sliceFields} key={key} />;
            case "section_hotlines":
              return <HotlinesList {...sliceFields} key={key} />;
            case "section_text_body":
              return <LegalBody {...sliceFields} key={key} />;
            case "section_two_col_link_list":
              return <GridItems {...sliceFields} key={key} />;
            case "job_board":
              return <JobBoard {...sliceFields} key={key} />;
            case "section_iframe":
              return <IframeEmbed {...sliceFields} key={key} />;
            case "section_anchor":
              return <AnchorSection {...sliceFields} key={key} />;

            default:
              return null;
          }
        })}
    </Layout>
  );
};

export default withPrismicPreview(Page);

export const query = graphql`
  query PageDetail($pageId: String) {
    allPrismicSiteBanner(
      limit: 1
      filter: { data: { is_active: { eq: true } } }
      sort: { last_publication_date: DESC }
    ) {
      edges {
        node {
          _previewable
          data {
            background_color
            banner_text {
              text
              html
            }
            banner_page_placements {
              banner_page {
                uid
              }
            }
            is_active
            is_sitewide
            cta_text
            cta_link {
              url
              uid
              link_type
            }
          }
        }
      }
    }
    prismicPageContainer(uid: { eq: $pageId }) {
      uid
      prismicId
      _previewable
      data {
        ...MetadataDef
        show_contact_form
        show_book_now_button
        hide_footer
        body {
          ... on PrismicPageContainerDataBodySectionHero {
            ...SliceHero
          }
          ... on PrismicPageContainerDataBodySectionHeroFullBleed {
            ...SliceHeroImageBlocks
          }
          ... on PrismicPageContainerDataBodySectionHeroCondensed {
            ...SliceHeroCondensed
          }
          ... on PrismicPageContainerDataBodySectionSplit {
            ...SliceSplit
          }
          ... on PrismicPageContainerDataBodySectionDetailCarousel {
            ...SliceDetail
          }
          ... on PrismicPageContainerDataBodySectionWhy {
            ...SliceWhy
          }
          ... on PrismicPageContainerDataBodySectionWhyAlt {
            ...SliceWhyAlt
          }
          ... on PrismicPageContainerDataBodySectionTestimonials {
            ...SliceTestimonials
          }
          ... on PrismicPageContainerDataBodySectionPress {
            ...SlicePress
          }
          ... on PrismicPageContainerDataBodySectionPressAlt {
            ...SlicePressAlt
          }
          ... on PrismicPageContainerDataBodySectionProviders {
            ...SliceProviders
          }
          ... on PrismicPageContainerDataBodySectionProvidersAlt {
            ...SliceProvidersAlt
          }
          ... on PrismicPageContainerDataBodySectionTeam {
            ...SliceTeam
          }
          ... on PrismicPageContainerDataBodySectionLocationCards {
            ...SliceLocationCards
          }
          ... on PrismicPageContainerDataBodySectionHowItWorks {
            ...SliceNumbered
          }
          ... on PrismicPageContainerDataBodySectionSplitList {
            ...SliceLists
          }
          ... on PrismicPageContainerDataBodySimpleTable {
            ...SliceSimpleTable
          }
          ... on PrismicPageContainerDataBodySectionCollapsible {
            ...SliceCollapsible
          }
          ... on PrismicPageContainerDataBodyCtaBlock {
            ...SliceCtaBlock
          }
          ... on PrismicPageContainerDataBodyCtaInline {
            ...SliceCtaInline
          }
          ... on PrismicPageContainerDataBodyFaqTopicSection {
            ...SliceFaq
          }
          ... on PrismicPageContainerDataBodySectionTextBody {
            slice_type
            slice_label
            primary {
              body_text {
                html
              }
              anchor
            }
          }
          ... on PrismicPageContainerDataBodySectionHotlines {
            ...SliceHotlines
          }
          ... on PrismicPageContainerDataBodySectionTwoColLinkList {
            ...SliceLinkList
          }
          ... on PrismicPageContainerDataBodyJobBoard {
            ...SliceJobBoard
          }
          ... on PrismicPageContainerDataBodySectionIframe {
            ...SliceIframe
          }
          ... on PrismicPageContainerDataBodySectionAnchor {
            ...SliceAnchor
          }
        }
      }
    }
    prismicSectionNumbered(uid: { eq: "how-it-works" }) {
      ...SectionNumberedDef
    }
  }
`;
