import React from "react";
import { Link, navigate } from "gatsby";
import PropTypes from "prop-types";
import styles from "./button.module.scss";

// TODO: handle internal links with <Link> tags
const Button = ({
  text,
  url,
  internalLink,
  onClick,
  isSmaller,
  isInline,
  isDropdownToggle,
  icon,
  style,
  className,
  target,
  disabled,
  buttonType,
  children,
  ...props
}) => {
  const handleClick = event => {
    if (url && url.indexOf("#") === 0) {
      event.preventDefault();
      let id = url.split("#")[1];
      if (id.indexOf("?") && id.indexOf("?") === id.length - 1) {
        id = id.substring(0, id.length - 1);
      }
      const el = document.getElementById(id);
      window.scroll({
        behavior: "smooth",
        left: 0,
        top: el.getBoundingClientRect().top - 100,
      });
      navigate(`#${id}`);
    }
    onClick && onClick(event);
  };

  const targetProps = {
    target,
    ...(target === "_blank" && { rel: "noopener" }),
  };

  let classes = `${styles.root} ${isSmaller ? styles.smaller : ""} ${
    isInline ? styles.inline : ""
  } ${isDropdownToggle ? styles.dropdownToggle : ""} ${
    className ? className : ""
  }`;
  classes = classes.trim();

  if (url) {
    if (internalLink) {
      return (
        <Link className={classes} style={style} to={url} {...targetProps}>
          {text}
        </Link>
      );
    }

    return (
      <a
        className={classes}
        style={style}
        href={url}
        disabled={!url && !onClick}
        onClick={handleClick}
        {...targetProps}
        {...props}
      >
        {icon && (
          <span className={styles.icon}>
            <img alt="" src={icon} />
          </span>
        )}
        {text}
      </a>
    );
  } else {
    return (
      <button
        className={classes}
        style={style}
        disabled={disabled}
        type={buttonType}
        {...(onClick ? { onClick: handleClick } : null)}
        {...props}
      >
        {children ? (
          children
        ) : (
          <>
            {icon && (
              <span className={styles.icon}>
                <img alt="" src={icon} />
              </span>
            )}
            {text}
          </>
        )}
      </button>
    );
  }
};

Button.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
  internalLink: PropTypes.bool,
  onClick: PropTypes.func,
  isSmaller: PropTypes.bool,
  isInline: PropTypes.bool,
  isDropdownToggle: PropTypes.bool,
  icon: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  buttonType: PropTypes.string,
};

Button.defaultProps = {
  url: null,
  internalLink: false,
  isSmaller: false,
  isInline: false,
  isDropdownToggle: false,
  icon: null,
  style: {},
  disabled: false,
  buttonType: "button",
};

export default Button;
