import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Aside from "../Aside/Aside";
import ParallaxImages from "../ParallaxImages/ParallaxImages";
import Reveal from "../Reveal/Reveal";
import styles from "./numbered-block.module.scss";

const NumberedBlock = ({
  heading,
  numbered_list,
  footnote,
  collage_images,
}) => {
  const images = collage_images.map(img => img.stacked_image);

  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <div className={styles.blockCopy}>
          <Reveal className={styles.copy}>
            {heading?.text && <h2>{heading.text}</h2>}
            {numbered_list?.length > 0 &&
              numbered_list.map((li, i) => (
                <div key={i}>
                  <p>
                    <span className={styles.number}>{i + 1}</span>
                  </p>
                  <div
                    dangerouslySetInnerHTML={{ __html: li.list_item.html }}
                  />
                </div>
              ))}
            {footnote?.text && (
              <Aside icon="/images/question.svg">{footnote.text}</Aside>
            )}
          </Reveal>
        </div>
        {images?.length > 0 && (
          <div className={styles.blockImage}>
            <Reveal className={styles.images}>
              <ParallaxImages images={images} />
            </Reveal>
          </div>
        )}
      </div>
    </div>
  );
};

NumberedBlock.propTypes = {
  heading: PropTypes.object,
  numbered_list: PropTypes.array,
  footnote: PropTypes.object,
  collage_images: PropTypes.array,
};

NumberedBlock.defaultProps = {
  heading: null,
  numbered_list: [],
  footnote: null,
  collage_images: [],
};

export default NumberedBlock;

// Prismic "Section Numbered" content type.
export const fragment = graphql`
  fragment SectionNumberedDef on PrismicSectionNumbered {
    _previewable
    data {
      heading {
        text
      }
      numbered_list {
        list_item {
          text
          html
        }
      }
      footnote {
        text
      }
      collage_images {
        stacked_image {
          url
          gatsbyImageData(width: 600, layout: CONSTRAINED)
          localFile {
            childImageSharp {
              gatsbyImageData(width: 600, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
`;

// Prismic Slice referring to site-wide "Numbered" content above.
export const fragmentSlice = graphql`
  fragment SliceNumbered on PrismicPageContainerDataBodySectionHowItWorks {
    slice_type
    primary {
      heading {
        text
      }
    }
  }
`;

export const locationStateDetailSliceNumberFragment = graphql`
  fragment OfficeLocationStateDetailSliceNumbered on PrismicOfficeLocationDataBodySectionHowItWorks {
    id
    primary {
      heading {
        richText
      }
    }
    slice_label
    slice_type
  }
`;

export const locationDetailSliceNumberFragment = graphql`
  fragment LocationDetailSliceNumbered on PrismicLocationTagsDataBodySectionHowItWorks {
    slice_type
    primary {
      heading {
        text
      }
    }
  }
`;
