/**
 *
 * @param {object} control
 * @param {string} name
 * @param {func} getValues
 * @param {boolean} isFocused
 * @returns
 */
export const useInputValidation = (variant, name, formBag, isFocused) => {
  let errorMessage = "";
  let isValid = null;
  let isTouched = null;
  let validityStyles = "";
  let focusStyles = "";

  if (variant !== "form") {
    return {
      errorMessage,
      isValid,
      isTouched,
      validityStyles,
      focusStyles,
    };
  }

  const { getValues, errors, touchedFields } = formBag;

  errorMessage = errors?.[name]?.message;
  isValid = !Boolean(errorMessage) && Boolean(getValues(name));
  isTouched = touchedFields?.[name];

  validityStyles = isValid ? "valid" : !isValid && isTouched ? "error" : "";

  // Fieldsets don't inherently have a focus state so utilize the
  // hideLabel state for the focused state of the fieldset styling.
  // When the label is hidden, it means the input is focused.
  // Used for InputStyling component.
  if (isValid) {
    if (isFocused) {
      focusStyles = "focus_valid";
    }
  } else {
    if (isFocused) {
      focusStyles = "focus";
    }

    if (isTouched) {
      focusStyles = "focus_error";
    }
  }

  return {
    errorMessage,
    isValid,
    isTouched,
    validityStyles,
    focusStyles,
  };
};
