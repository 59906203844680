import React from "react";
import { Content, Portal } from "@radix-ui/react-select";
import styles from "./select-input.module.scss";

export const SelectContent = ({ children, ...props }) => {
  return (
    <Portal>
      <Content className={styles.select_content} {...props}>
        {children}
      </Content>
    </Portal>
  );
};
