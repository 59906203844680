import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import LazyImage from "../LazyImage/LazyImage";

const Image = ({
  imageObj,
  alt,
  title,
  className,
  fallbackClass,
  style,
  imgStyle,
  loading = "lazy", // "lazy" | "eager"
  lazyLoad,
}) => {
  if (!imageObj) return <></>;

  const requiresFallback =
    !imageObj.localFile?.childImageSharp &&
    !imageObj.gatsbyImageData?.layout &&
    !!imageObj.url;
  const altData = alt?.text ? alt.text : alt ? alt : "";

  let imgProps = {};
  const gatsbyImage = getImage(imageObj.localFile || imageObj.gatsbyImageData);

  if (requiresFallback) {
    const fallbackImgStyle = {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      ...imgStyle,
    };
    imgProps = {
      src: imageObj.url,
      title: title,
      className: fallbackClass,
      style: fallbackImgStyle,
    };
  } else {
    imgProps = {
      title: title,
      className: className,
      style: style,
      imgStyle: imgStyle,
    };
  }

  return (
    <>
      {requiresFallback ? (
        <div className={className} style={style}>
          {lazyLoad ? (
            <LazyImage imgProps={imgProps} alt={altData} />
          ) : (
            <img {...imgProps} alt={altData} />
          )}
        </div>
      ) : (
        gatsbyImage && (
          <GatsbyImage
            {...imgProps}
            image={gatsbyImage}
            alt={altData}
            loading={loading}
          />
        )
      )}
    </>
  );
};

export default Image;
