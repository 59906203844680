import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import BgFloatingDots from "../../images/blobs-dots.inline.svg";
import AnimatedLine from "../AnimatedLine/AnimatedLine";
import Button from "../Button/Button";
import HeroImageMask from "./HeroImageMask";
import Image from "../Image/Image";
import LinkButton from "../LinkButton/LinkButton";
import styles from "./hero-floating-mask.module.scss";

const scrollDown = e => {
  e.preventDefault();
  const el = document.getElementById("hero");
  window.scroll({
    behavior: "smooth",
    left: 0,
    top: el.getBoundingClientRect().bottom - el.getBoundingClientRect().top,
  });
};

const HeroFloatingMask = ({
  hero_heading,
  hero_text,
  cta_text,
  cta_link,
  ctaSection,
  secondaryPage,
  hero_image,
}) => {
  return (
    <div className={styles.root} id="hero">
      <div className={styles.inner}>
        <div className={styles.media}>
          {/* SVG component below used as mask and connected to via id in css */}
          <HeroImageMask />

          <div className={styles.wrapped_img}>
            <BgFloatingDots className={styles.bg_dots} />
            <div className={styles.clipped}>
              <Image
                className={styles.image}
                imageObj={hero_image}
                loading="eager"
              />
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.copy}>
            <div
              className={`${secondaryPage ? styles.secondary : ""}`}
              dangerouslySetInnerHTML={{ __html: hero_heading.html }}
            />
            <div dangerouslySetInnerHTML={{ __html: hero_text.html }} />
            {ctaSection ? (
              ctaSection()
            ) : (
              <>
                {cta_text && <Button text={cta_text} url={`${cta_link.url}`} />}
              </>
            )}
          </div>
          <div className={styles.wrapper}>
            <LinkButton
              className={styles.text}
              onClick={e => {
                scrollDown(e);
              }}
            >
              Learn more
            </LinkButton>
            <AnimatedLine
              linePath={"M 80 0 L 80 160 Q 80 250, 0 240"}
              lineLength={160 + 80 * 1.57}
              height={240}
              width={82}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

HeroFloatingMask.propTypes = {
  hero_heading: PropTypes.object,
  hero_text: PropTypes.object,
  cta_text: PropTypes.string,
  cta_link: PropTypes.object,
  ctaSection: PropTypes.func,
  secondaryPage: PropTypes.bool,
  hero_image: PropTypes.object,
};

HeroFloatingMask.defaultProps = {
  ctaSection: null,
  secondaryPage: false,
  hero_image: {},
};

export default HeroFloatingMask;

// Prismic "Section Hero" slice
export const fragment = graphql`
  fragment LocationSliceHeroFloating on PrismicStateLocationDataBodySectionHeroFloating {
    slice_type
    slice_label
    primary {
      hero_heading {
        text
        html
      }
      hero_text {
        html
      }
      cta_text
      cta_link {
        url
      }
      hero_image {
        url
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 1200
              placeholder: BLURRED
              layout: CONSTRAINED
            )
          }
        }
      }
    }
  }
`;
