import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import styles from "./tabs.module.scss";
import { Link } from "gatsby";
import {
  SEARCH_SPECIALTY_KEY,
  SEARCH_MODALITY_KEY,
} from "../ProvidersList/hooks/useProviderListFilters";

const transformData = data => {
  // map out all the page data in accordance to their tab,
  // and in those gather common copy under common subheaders
  return data?.reduce((acc, { primary, items }) => {
    const tabName = primary.tab_name;
    const newItems = items?.reduce((innerAcc, item) => {
      if (innerAcc[item.header]) {
        innerAcc[item.header].push(item);
      } else {
        innerAcc[item.header] = [item];
      }

      return innerAcc;
    }, {});

    if (newItems) {
      acc.push({
        tab_name: tabName,
        items: newItems,
      });
    }

    return acc;
  }, []);
};

const renderTags = (tags, searchKey, getRedirectUrl) => (
  <p>
    {tags.map(
      ({ tag }, index) =>
        tag?.document?.data?.name && (
          <Link
            to={getRedirectUrl(searchKey, tag.uid)}
            key={`${tag.uid}-${index}`}
          >
            {`${tag?.document?.data?.name}${
              tags.length - 1 === index ? "" : ",  "
            }`}
          </Link>
        )
    )}
  </p>
);

const Tabs = ({
  data,
  specialtyTags,
  treatmentModalityTags,
  getRedirectUrl,
}) => {
  const transformedData = useMemo(() => transformData(data), [data]);
  const [activeTab, setActiveTab] = useState(0);

  //TODO: revisit this to use css hover state
  const handleTabClick = idx => {
    setActiveTab(0);
    const top = document.getElementById(`section-${idx}`).offsetTop;
    window.scrollTo({
      top: top,
      behavior: "smooth",
    });
  };

  const handleMouseEnter = idx => {
    // Skip click events on touch interactions
    if (navigator.maxTouchPoints > 0) {
      return;
    }

    // Skip click events on touch interactions
    if ("ontouchstart" in window) {
      return;
    }

    setActiveTab(idx);
  };

  const renderTabContent = (tabData, idx) => {
    switch (tabData?.type) {
      case "text":
        return (
          <div
            key={`text-${idx}`}
            dangerouslySetInnerHTML={{ __html: tabData?.text_details?.html }}
          />
        );
      case "education":
        return (
          <div
            className={styles.reactTabsItemCustomList}
            key={`education-${idx}`}
            dangerouslySetInnerHTML={{ __html: tabData?.text_details?.html }}
          />
        );
      case "specialty tags":
        return renderTags(specialtyTags, SEARCH_SPECIALTY_KEY, getRedirectUrl);
      case "treatment modality tags":
        return renderTags(
          treatmentModalityTags,
          SEARCH_MODALITY_KEY,
          getRedirectUrl
        );

      case "personal info":
        return (
          <div
            className={styles.reactTabsItemPersonalInfo}
            key={`personal-info-${idx}`}
            dangerouslySetInnerHTML={{ __html: tabData?.text_details?.html }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.reactTabs}>
      <div className={styles.reactTabsList}>
        {data.map(({ primary }, idx) => (
          <button
            className={`${styles.reactTabsTab} ${
              activeTab === idx ? styles.reactTabsTabActive : ""
            }`}
            key={`tab-outer-${primary?.tab_name}`}
            onClick={() => handleTabClick(idx)}
            onMouseEnter={() => handleMouseEnter(idx)}
            onMouseLeave={() => setActiveTab(0)}
          >
            {primary?.tab_name}
          </button>
        ))}
      </div>
      <div>
        {transformedData.map((tabSection, index) => (
          <div
            key={`tab-section-${index}`}
            id={`section-${index}`}
            className={styles.reactTabsSection}
          >
            {tabSection.tab_name == "Personal Details" && (
              <h4
                style={{ marginBottom: "-1rem" }}
                className={styles.reactTabsItemHeader}
              >
                Get to Know Me
              </h4>
            )}

            {Object.keys(tabSection.items).map((tabHeader, idx) => (
              <div className={styles.reactTabsItem} key={`${tabHeader}-${idx}`}>
                <h4
                  className={
                    tabSection.items[tabHeader][0].type === "personal info"
                      ? styles.reactTabsItemSubHeader
                      : styles.reactTabsItemHeader
                  }
                >
                  {tabHeader}
                </h4>
                <div className={styles.reactTabsItemDetails}>
                  {tabSection.items[tabHeader].map((item, itemIdx) =>
                    renderTabContent(item, itemIdx)
                  )}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  data: PropTypes.array,
  specialtyTags: PropTypes.array,
  treatmentModalityTags: PropTypes.array,
};

export default Tabs;
