import React from "react";
import { graphql, PageProps } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import get from "lodash/get";
import Article from "../components/Article/Article";
import { flattenNode, getMetadata } from "../prismic/util";

const ArticleTemplate = ({
  data,
  location,
  pageContext,
}: PageProps<
  Queries.ArticlePageQuery,
  {
    articleId?: string;
  }
>) => {
  const renderData = flattenNode(get(data, "prismicArticle.data"));
  const articleHeader = renderData?.body?.find(
    item => item.slice_type === "article_header"
  );
  const ogImage = articleHeader?.primary?.header_image?.url;
  const metadata = getMetadata(renderData, null, null, ogImage);
  const pdfUrlPath = `/pdf/${pageContext?.articleId}.pdf`;

  return (
    <Article
      renderData={renderData}
      metadata={metadata}
      location={location}
      pdfUrlPath={pdfUrlPath}
    />
  );
};

export default withPrismicPreview(ArticleTemplate);

export const query = graphql`
  query ArticlePage($articleId: String) {
    prismicArticle(uid: { eq: $articleId }) {
      prismicId
      uid
      _previewable
      url
      data {
        meta_title {
          text
        }
        meta_description
        og_image_overwrite {
          url
        }
        body {
          ... on PrismicArticleDataBodyArticleHeader {
            slice_type
            slice_label
            primary {
              article_title {
                text
              }
              intro_text {
                html
                richText
              }
              header_image {
                url
                gatsbyImageData(width: 1200, layout: CONSTRAINED)
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1200, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
          ... on PrismicArticleDataBodyArticleImageColumns {
            slice_type
            slice_label
            items {
              section_image {
                url
                gatsbyImageData(width: 440, layout: CONSTRAINED)
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 440, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
          ... on PrismicArticleDataBodyArticleSectionFull {
            slice_type
            slice_label
            primary {
              section_text {
                html
                richText
              }
              section_image {
                url
                gatsbyImageData(width: 600, layout: CONSTRAINED)
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 600, layout: CONSTRAINED)
                  }
                }
              }
              section_image_align
            }
          }
          ... on PrismicArticleDataBodyResourceList {
            slice_type
            slice_label
            primary {
              resource_text {
                html
                richText
              }
            }
            items {
              resource_item {
                document {
                  ... on PrismicArticle {
                    uid
                    data {
                      body {
                        ... on PrismicArticleDataBodyArticleHeader {
                          primary {
                            article_title {
                              text
                            }
                          }
                        }
                      }
                      excerpt {
                        html
                        richText
                      }
                      thumbnail {
                        url
                        gatsbyImageData(width: 720, layout: CONSTRAINED)
                        localFile {
                          childImageSharp {
                            gatsbyImageData(width: 720, layout: CONSTRAINED)
                          }
                        }
                      }
                    }
                    url
                  }
                }
              }
            }
          }
          ... on PrismicArticleDataBodyArticleFooter {
            slice_type
            slice_label
            primary {
              footer_text {
                html
                richText
              }
            }
            items {
              article_tag
            }
          }
          ... on PrismicArticleDataBodySectionAnchor {
            id
            slice_type
            primary {
              hash_label
            }
          }
        }
      }
    }
  }
`;
