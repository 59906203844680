import React from "react";
import PropTypes from "prop-types";
import { format, parseISO } from "date-fns";
import Button from "../Button/Button";
import Image from "../Image/Image";
import styles from "./group-detail-info.module.scss";

const GroupDetailInfo = ({
  heading,
  category,
  category_short,
  location_text,
  price_text,
  schedule_text,
  start_date,
  duration_text,
  thumbnails,
  enroll_link_url,
  enroll_button_text,
}) => {
  const categoryText = category_short || category || null;
  const startDateFmt = start_date
    ? format(parseISO(start_date), "EEEE, MMMM do, yyyy")
    : null;
  return (
    <div className={styles.root}>
      <div className={styles.info}>
        <h1 className={styles.heading}>{heading.text}</h1>
        {categoryText && <div className={styles.tag}>{categoryText}</div>}
        <div className={styles.mobileThumbnail}>
          {thumbnails.map((item, index) => {
            const image = item.group_thumb;
            return (
              <Image
                imageObj={image}
                className={styles.thumbnailImages}
                key={index}
              />
            );
          })}
        </div>
        <ul className={styles.metaList}>
          {location_text && (
            <li>
              <span className={styles.label}>Where</span>
              <span className={styles.value}>{location_text.text}</span>
            </li>
          )}
          {schedule_text && (
            <li>
              <span className={styles.label}>When</span>

              <span className={styles.value}>{schedule_text}</span>
            </li>
          )}
          {duration_text && (
            <li>
              <span className={styles.label}>Duration</span>
              <span className={styles.value}>{duration_text}</span>
            </li>
          )}
          {price_text && (
            <li>
              <span className={styles.label}>Price</span>
              <span className={styles.value}>{price_text}</span>
            </li>
          )}
          {startDateFmt && (
            <li>
              <span className={styles.label}>Start</span>
              <span className={styles.valueBig}>{startDateFmt}</span>
            </li>
          )}
        </ul>
        <div className={styles.ctaWrapper}>
          <Button
            text={enroll_button_text}
            url={enroll_link_url?.url}
            target={enroll_link_url?.target}
          />
        </div>
      </div>
      <div className={styles.thumbnail}>
        {thumbnails.map((item, index) => {
          const image = item.group_thumb;
          return (
            <Image
              imageObj={image}
              className={styles.thumbnailImages}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
};

export default GroupDetailInfo;

GroupDetailInfo.propTypes = {
  heading: PropTypes.object.isRequired,
  description: PropTypes.object,
  location_city: PropTypes.string,
  location_text: PropTypes.object,
  price_text: PropTypes.string,
  schedule_text: PropTypes.string,
  start_date: PropTypes.string,
  thumbnails: PropTypes.array,
};
