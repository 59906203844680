import React, { createContext, useContext } from "react";
import mixpanel from "mixpanel-browser";

export const MixpanelContext = createContext();

export const MixpanelProvider = ({ children }) => (
  <MixpanelContext.Provider value={mixpanel}>
    {children}
  </MixpanelContext.Provider>
);

export const useMixpanel = () => useContext(MixpanelContext);

export { mixpanel };