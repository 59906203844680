import React from "react";
import PropTypes from "prop-types";
import ErrorIcon from "../../../../images/validation/octave-ui-icons_alert-denied-red.inline.svg";
import styles from "./validation-icon.module.scss";

export const ValidationIcon = ({ isValid, isTouched, hasDropdown }) => {
  const classes = `${styles.validation_icon} ${
    hasDropdown ? styles.has_dropdown : ""
  }`;

  return <>{!isValid && isTouched ? <ErrorIcon className={classes} /> : null}</>;
};

ValidationIcon.propTypes = {
  isValid: PropTypes.bool.isRequired,
  isTouched: PropTypes.bool.isRequired,
  hasDropdown: PropTypes.bool,
};

ValidationIcon.defaultProps = {
  isValid: false,
  isTouched: false,
  hasDropdown: false,
};
