import React from "react";
import PropTypes from "prop-types";
import Image from "../Image/Image";
import styles from "./article-images.module.scss";

const ArticleImages = ({ items }) => {
  return (
    <div className={styles.root}>
      <div className={styles.imageContain}>
        {items.map((i, index) => (
          <Image
            key={`a-img-${index}`}
            imageObj={i.section_image}
            className={styles.articleImage}
          />
        ))}
      </div>
    </div>
  );
};

ArticleImages.propTypes = {
  items: PropTypes.array,
};

export default ArticleImages;
