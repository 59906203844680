import React, { useEffect, useRef, useState } from "react";
import { SkipNavContent, SkipNavLink } from "@reach/skip-nav";
import "@reach/skip-nav/styles.css";
import PropTypes from "prop-types";
import Footer from "../Footer/Footer";
import ContactFooter from "../Forms/ContactForm/ContactFooter/ContactFooter";
import Header from "../Header/Header";
import { DENY_PAGE_LIST_ZENDESK, ZendeskBtn } from "../ZendeskBtn/ZendeskBtn";
import {
  defaultBackgroundColor,
  initialLayoutPaddingTop,
  octExternalUrlParams,
  SCHEDULE_CONSULT_URL,
  SCROLL_PAGES,
} from "../../lib/constants";
import { ZD_SCRIPT_SRC } from "../../lib/hooks/useZendeskScript";
import {
  combineQueryString,
  getSessionStorage,
  loadZdScript,
} from "../../prismic/util";
import "../../styles/base.scss";
import styles from "./layout.module.scss";

const Layout = ({
  pageUid,
  children,
  bannerData,
  backgroundColor,
  showContactForm,
  showBookNowButton = true,
  hideFooter = false,
}) => {
  // Determine if current page should have smooth scroll behaviour
  const isScrollPage = SCROLL_PAGES.includes(pageUid);
  const [isDesktop, setIsDesktop] = useState(false);

  // Zendesk Help Widget
  const showZendesk = !DENY_PAGE_LIST_ZENDESK.includes(pageUid);

  let hasBanner = false;
  if (bannerData) {
    hasBanner = true;
  }

  const [layoutPaddingTop, setLayoutPaddingTop] = useState(() => {
    if (hasBanner) {
      return 0;
    }

    return initialLayoutPaddingTop;
  });

  useEffect(() => {
    // TODO: move this to a hook!
    // check session storage for query string data.
    // if found, we want to pass along on any links that target
    // the octave onboarding/scheduling app (ie, have a matching
    // full hostname of "my.findoctave.com").
    const qsFromSession = getSessionStorage(octExternalUrlParams);

    if (qsFromSession) {
      const links = document.getElementsByTagName("a");
      const appDomain = new URL(SCHEDULE_CONSULT_URL).hostname;

      [...links].forEach(l => {
        if (l.href) {
          const linkUrl = new URL(l.href);
          if (linkUrl.hostname === appDomain) {
            const paramsToPassAlong = combineQueryString(
              new URLSearchParams(qsFromSession),
              linkUrl.searchParams
            );
            linkUrl.search = paramsToPassAlong;
            l.href = linkUrl.href;
          }
        }
      });
    }
  }, []);

  const isZdScriptLoadedRef = useRef(
    typeof document !== "undefined"
      ? Boolean(document.querySelector(`script[src="${ZD_SCRIPT_SRC}"]`))
      : false
  );

  useEffect(() => {
    // Add Zendesk script if:
    // 1. Desktop screen sizes
    // 2. Not loaded yet
    // 3. Screen resizes + desktop size
    if (
      typeof window !== "undefined" &&
      showZendesk &&
      !isZdScriptLoadedRef.current
    ) {
      if (window.innerWidth > 768) {
        setIsDesktop(true);

        // Create script
        loadZdScript();

        isZdScriptLoadedRef.current = true;
      } else {
        setIsDesktop(false);
      }
    }

    const loadZdScriptResize = window.addEventListener("resize", () => {
      if (
        window.innerWidth > 768 &&
        showZendesk &&
        !isZdScriptLoadedRef.current
      ) {
        setIsDesktop(true);

        // Create script
        loadZdScript();

        isZdScriptLoadedRef.current = true;
      } else {
        setIsDesktop(false);
      }
    });

    return () => {
      window.removeEventListener("resize", loadZdScriptResize);
    };
  }, [showZendesk]);

  // Should only show placeholder button on mobile and if Zendesk not already loaded
  const showZdPlaceholderBtn =
    showZendesk && !isDesktop && !isZdScriptLoadedRef.current;

  const bannerStyles = hasBanner ? styles.hasBanner : "";
  const smoothScrollStyles = isScrollPage ? styles.smooth_scroll : "";

  return (
    <>
      <SkipNavLink className={styles.skip_nav_link} />
      <div
        className={`${styles.root} page-${pageUid} ${bannerStyles} ${smoothScrollStyles}`}
        style={{
          backgroundColor: backgroundColor || defaultBackgroundColor,
          paddingTop: layoutPaddingTop,
        }}
      >
        <Header
          hasBanner={hasBanner}
          bannerData={bannerData}
          setLayoutPaddingTop={setLayoutPaddingTop}
          showBookNowButton={showBookNowButton}
        />
        <SkipNavContent>{children}</SkipNavContent>
        {showContactForm && <ContactFooter />}
        {!hideFooter && <Footer />}
        {showZdPlaceholderBtn && <ZendeskBtn />}
        {/* {popupCopy ? (
        <PopupModal isOpen={isOpen} closeModal={closeModal} {...popupCopy} />
      ) : (
        ""
      )} */}
      </div>
    </>
  );
};

Layout.propTypes = {
  pageUid: PropTypes.string,
  children: PropTypes.node.isRequired,
  bannerData: PropTypes.object,
  backgroundColor: PropTypes.string,
  showContactForm: PropTypes.bool,
  
};

Layout.defaultProps = {
  pageUid: "",
  bannerData: null,
  showContactForm: true,
};

export default Layout;
