import React from "react";
import { isObject } from "lodash";
import PropTypes from "prop-types";
import styles from "./aside.module.scss";

const Aside = ({ children, noPadding, icon }) => {
  const getMarkup = children => {
    if (isObject(children)) {
      if (!(children.text || children.html)) {
        // Prismic may return a RichText object with
        // nothing in it
        return null;
      } else {
        const asideContent = children.html || children.text;
        return <div dangerouslySetInnerHTML={{ __html: asideContent }} />;
      }
    } else {
      return <div dangerouslySetInnerHTML={{ __html: children }} />;
    }
  };

  const markup = getMarkup(children);

  return (
    markup && (
      <div className={`${styles.root} ${noPadding ? styles.noPadding : ""}`}>
        <div className={styles.icon}>{icon && <img alt="" src={icon} />}</div>
        <div className={styles.content}>{markup}</div>
      </div>
    )
  );
};

Aside.propTypes = {
  noPadding: PropTypes.bool,
  icon: PropTypes.string,
};

Aside.defaultProps = {
  noPadding: false,
  icon: null,
};

export default Aside;
