import React from "react";
import PropTypes from "prop-types";
import { PrismicRichText } from "@prismicio/react";
import LocationCard from "../LocationCard/LocationCard";
import { slugify } from "../../prismic/util";
import styles from "./location-groups.module.scss";

const LocationGroups = ({ groups, singleGroupData }) => {
  const renderAsGroups = !singleGroupData && groups;
  const renderAsSingleGroup = singleGroupData?.office_locations && !groups?.[0];

  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        {renderAsGroups &&
          Object.entries(groups).map((data, index) => {
            const title = data[0];
            const group = data[1];
            return (
              <div
                className={styles.groupItem}
                key={index}
                id={`location-group-${slugify(title)}`}
              >
                <div className={styles.groupHeader}>
                  <h2>{title}</h2>
                </div>
                {group.length > 0 && (
                  <div className={styles.locationList}>
                    {group.map(locationData => {
                      // this check is redundant as we filter out data via gql
                      // but used as a precaution. Can remove if needed.
                      const isVirtualLocation =
                        locationData.uid.includes("virtual");

                      if (isVirtualLocation) return null;

                      return (
                        <div
                          className={styles.locationItem}
                          key={locationData.uid}
                        >
                          <LocationCard {...locationData} />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
        {renderAsSingleGroup && (
          <div
            className={`${styles.groupItem} ${styles.singleGroupItem}`}
            id={`location-group-${slugify(
              singleGroupData.section_title.richText.text
            )}`}
          >
            <div className={styles.groupHeader}>
              <PrismicRichText
                field={singleGroupData.section_title.richText}
                components={{
                  heading1: ({ children }) => (
                    <h1 className={styles.groupHeader}>{children}</h1>
                  ),
                  heading2: ({ children }) => (
                    <h2 className={styles.groupHeader}>{children}</h2>
                  ),
                  heading3: ({ children }) => (
                    <h3 className={styles.groupHeader}>{children}</h3>
                  ),
                  heading4: ({ children }) => (
                    <h4 className={styles.groupHeader}>{children}</h4>
                  ),
                  heading5: ({ children }) => (
                    <h5 className={styles.groupHeader}>{children}</h5>
                  ),
                  heading6: ({ children }) => (
                    <h6 className={styles.groupHeader}>{children}</h6>
                  ),
                }}
              />
            </div>
            <div className={styles.locationList}>
              {singleGroupData.office_locations.map(locationData => {
                const isVirtualLocation = locationData.uid.includes("virtual");

                if (isVirtualLocation) return null;

                return (
                  <div className={styles.locationItem} key={locationData.uid}>
                    <LocationCard {...locationData} />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

LocationGroups.propTypes = {
  groups: PropTypes.object,
};

LocationGroups.defaultProps = {
  groups: {},
};

export default LocationGroups;
