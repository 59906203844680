import { useState } from "react";

export const useToggleInputLabel = () => {
  const [hideLabel, setHideLabel] = useState(true);

  /**
   * Used for hiding the label on select elements while focused
   * @param {boolean} open
   */
  const toggleLabel = open => {
    if (open) {
      setHideLabel(false);
    } else {
      setHideLabel(true);
    }
  };

  return { hideLabel, toggleLabel, setHideLabel };
};
