import React, { useState, useEffect, useRef } from "react";
import PlaceholderCard from "../../images/placeholder-card.svg";

function LazyImage({ imgProps, alt }) {
  const { src, style } = imgProps;

  const [imageSrc, setImageSrc] = useState(PlaceholderCard);
  const [isVisible, setIsVisible] = useState(false);
  const imageRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setImageSrc(src);
            setIsVisible(true);
            observer.disconnect();
          }
        });
      },
      { rootMargin: "500px" }
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    // Set back to placeholder when src changes
    setImageSrc(PlaceholderCard);

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [src]);

  return (
    <img
      {...imgProps}
      style={{
        ...style,
        opacity: isVisible ? 1 : 0,
        transition: "opacity 500ms ease",
      }}
      ref={imageRef}
      src={imageSrc}
      alt={alt}
    />
  );
}

export default LazyImage;
