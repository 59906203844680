import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import PropTypes from "prop-types";
import ParallaxImages from "../ParallaxImages/ParallaxImages";
import Reveal from "../Reveal/Reveal";
import styles from "./home-why.module.scss";

const HomeWhy = ({ heading, items }) => {
  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <Reveal className={styles.header}>
          <h2>{heading.text}</h2>
        </Reveal>
      </div>
      <div className={styles.blocks}>
        {items &&
          items.map((card, i) => {
            const title = get(card, "item_title.text");
            const description = get(card, "item_text.html");
            const images = [
              card.stacked_image_back,
              card.stacked_image_mid,
              card.stacked_image_front,
            ];

            return (
              <Reveal className={styles.block} key={i}>
                <div className={styles.images}>
                  {images && <ParallaxImages images={images} />}
                </div>
                <h4>{title}</h4>
                <div dangerouslySetInnerHTML={{ __html: description }} />
              </Reveal>
            );
          })}
      </div>
    </div>
  );
};

HomeWhy.propTypes = {
  heading: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
};

export default HomeWhy;

// Prismic "Section Why" slice
export const fragment = graphql`
  fragment SliceWhy on PrismicPageContainerDataBodySectionWhy {
    slice_type
    slice_label
    primary {
      heading {
        text
      }
    }
    items {
      item_title {
        text
      }
      item_text {
        html
      }
      stacked_image_back {
        url
        gatsbyImageData(width: 600, layout: CONSTRAINED)
        localFile {
          childImageSharp {
            gatsbyImageData(width: 600, layout: CONSTRAINED)
          }
        }
      }
      stacked_image_mid {
        url
        gatsbyImageData(width: 600, layout: CONSTRAINED)
        localFile {
          childImageSharp {
            gatsbyImageData(width: 600, layout: CONSTRAINED)
          }
        }
      }
      stacked_image_front {
        url
        gatsbyImageData(width: 600, layout: CONSTRAINED)
        localFile {
          childImageSharp {
            gatsbyImageData(width: 600, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;
