import React, { useState } from "react";
import PropTypes from "prop-types";
import Reveal from "../Reveal/Reveal";
import SlideController from "../SlideController/SlideController";
import SlideIndicators from "../SlideIndicators/SlideIndicators";
import styles from "./table-section.module.scss";

const TableSection = ({ table_title, col_heading_1, col_heading_2, items }) => {
  const [slide, setSlide] = useState(0);

  const updateSlide = slide => setSlide(slide);

  const tableHeadings = [col_heading_1, col_heading_2];

  const slideCount = 2;

  return (
    <div className={styles.root}>
      <SlideController
        onUpdate={updateSlide}
        currentSlide={slide}
        count={slideCount}
        autoPlay={false}
      >
        <div className={styles.inner}>
          <Reveal className={styles.header}>
            <h2>{table_title.text}</h2>
          </Reveal>
          <Reveal>
            <div
              className={`${styles.table} ${
                slide === 1 && styles.tableScrolled
              }`}
            >
              {tableHeadings.map((th, index) => (
                <div className={styles.tableHeader} key={`th-${index}`}>
                  <h4>{th.text}</h4>
                </div>
              ))}
              {items.map((row, itemIndex) => {
                return Object.entries(row).map((cell, rowIndex) => (
                  <div className={styles.tableSection} key={`row-${rowIndex}`}>
                    <div
                      className={styles.tableSectionCopy}
                      key={`${itemIndex}-${rowIndex}`}
                    >
                      <div dangerouslySetInnerHTML={{ __html: cell[1].html }} />
                    </div>
                  </div>
                ));
              })}
            </div>
            <div className={styles.indicators}>
              <SlideIndicators
                currentSlide={slide}
                count={slideCount}
                updateSlide={updateSlide}
              />
            </div>
          </Reveal>
        </div>
      </SlideController>
    </div>
  );
};

TableSection.propTypes = {
  table_title: PropTypes.object,
  col_heading_1: PropTypes.object,
  col_heading_2: PropTypes.object,
  items: PropTypes.array.isRequired,
};

export default TableSection;
