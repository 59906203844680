import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Image from "../Image/Image";
import { LinksColumn } from "./LinksColumn";
import styles from "./footer.module.scss";


const Footer = () => {
  const footerData = useStaticQuery(QUERY);

  const linkGroups = footerData.prismicFooter.data.body;
  const socialLinks = footerData.prismicFooter.data.social_links;
  const footerImg = footerData.prismicFooter.data.footer_image;
  const alert = footerData.prismicFooter.data.alert.html;

  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        {linkGroups.map(({ id, items, primary }, index) => (
          <LinksColumn title={primary.title} links={items} key={id} />
        ))}
        <div>
          <div className={styles.follow}>Follow Us</div>
          <ul className={styles.socialLinkList}>
            {socialLinks.map((social, i) => (
              <li
                className={styles.socialLinkListItem}
                key={`social-link-${i}`}
              >
                <a
                  target="_blank"
                  rel="noreferrer"
                  className={styles.socialLink}
                  href={social.link.url}
                >
                  <Image
                    alt={social.label}
                    className={styles.socialLinkImg}
                    imageObj={{ gatsbyImageData: social.icon.gatsbyImageData }}
                    title={social.label}
                  />
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.graphic}>
          <div className={styles.imageContain}>
            <Image
              alt={footerImg.alt || ""}
              className={styles.socialLinkImg}
              imageObj={{ gatsbyImageData: footerImg.gatsbyImageData }}
            />
          </div>
        </div>
      </div>
      <div
        dangerouslySetInnerHTML={{ __html: alert }}
        className={styles.alert}
      />
      <div className={styles.copyright}>
        &copy; {new Date().getFullYear()} Octave Health Group Inc.
        <div className={styles.contact}>
          <a href="mailto:support@findoctave.com">support@findoctave.com</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;

const QUERY = graphql`
  query Footer {
    prismicFooter {
      data {
        alert {
          html
        }
        footer_image {
          gatsbyImageData(layout: FULL_WIDTH, width: 300, height: 300)
          alt
        }
        social_links {
          icon {
            gatsbyImageData(width: 40)
          }
          label
          link {
            url
            target
          }
        }
        body {
          ... on PrismicFooterDataBodyLinkColumnGroup {
            id
            items {
              label
              link {
                url
              }
            }
            primary {
              title
            }
          }
        }
      }
    }
  }
`;
