import React from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import styles from "./modal.module.scss";

const Modal = ({ children, isOpen, closeModal, isLeadGen }) => (
  <div>
    {isLeadGen ? (
      <ReactModal
        isOpen={isOpen}
        onRequestClose={closeModal}
        className={{
          base: styles.leadgenModal,
          afterOpen: styles.modalAfterOpen,
          beforeClose: styles.modalBeforeClose,
        }}
        style={{ overlay: { background: "var(--dark-scrim)" } }}
      >
        <div className={styles.leadgenInner}>
          {children}
          {closeModal && (
            <button className={styles.leadgenCloseButton} onClick={closeModal}>
              &times;
            </button>
          )}
        </div>
      </ReactModal>
    ) : (
      <ReactModal
        isOpen={isOpen}
        onRequestClose={closeModal}
        className={{
          base: styles.modal,
          afterOpen: styles.modalAfterOpen,
          beforeClose: styles.modalBeforeClose,
        }}
        style={{ overlay: { background: "transparent" } }}
      >
        <div className={styles.inner}>
          {children}
          {closeModal && (
            <button className={styles.closeButton} onClick={closeModal}>
              &times;
            </button>
          )}
        </div>
      </ReactModal>
    )}
  </div>
);

Modal.propTypes = {
  children: PropTypes.object.isRequired,
  isOpen: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]),
  closeModal: PropTypes.func,
};

export default Modal;
