import React, { useEffect, useRef, useState } from "react";
import { get } from "lodash";
import PropTypes from "prop-types";
import Button from "../Button/Button";
import Image from "../Image/Image";
import Tabs from "../Tabs/Tabs";
import {
  providerRole,
  PROVIDER_BIO_AVAILABILITY_WIDGET_BASE_URL,
  AVAILABILITY_WIDGET_BREAKPOINT,
  TEAM_PATHNAME,
  SEARCH_IS_TESTING_SLOTS_KEY,
} from "../../lib/constants";
import {
  SEARCH_INSURANCE_KEY,
  SEARCH_LANGUAGE_KEY,
} from "../ProvidersList/hooks/useProviderListFilters";
import { flattenNode, isEmpty, slugify } from "../../prismic/util";
import styles from "./profile.module.scss";
import Dialog from "../Dialog/Dialog";
import ProfileShape from "../../images/profile-bio-shape.inline.svg";
import { Link } from "gatsby";
import { formatStateName } from "../../lib/util";

const Profile = ({
  profile,
  title,
  ctaCopy,
  ctaUrl,
  modal,
  hideAvailabilityWidget,
  isSlotsFeatureFlagOn,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const [openBookingModal, setOpenBookingModal] = useState(false);
  const [isAvailabilityWidgetFixed, setIsAvailabilityWidgetFixed] =
    useState(false);
  const [widgetUrl, setWidgetUrl] = useState(
    PROVIDER_BIO_AVAILABILITY_WIDGET_BASE_URL
  );

  const bioBottomRef = useRef(null);
  useEffect(() => {
    // Observer for Intersection
    const observer = new IntersectionObserver(entries => {
      const entry = entries[0];
      setIsAvailabilityWidgetFixed(
        !entry.isIntersecting && entry.boundingClientRect.y > 0
      );
    });

    if (bioBottomRef.current) {
      observer.observe(bioBottomRef.current);
    }

    // Function to check screen size
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < AVAILABILITY_WIDGET_BREAKPOINT);
    };

    // Check screen size and add event listener
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    // Cleanup function
    return () => {
      // Unobserve the observer
      if (bioBottomRef.current) {
        observer.unobserve(bioBottomRef.current);
      }

      // Remove the resize event listener
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  useEffect(() => {
    const url = new URL(PROVIDER_BIO_AVAILABILITY_WIDGET_BASE_URL);
    url.searchParams.append("layout", isMobile ? "mobile" : "desktop");
    url.searchParams.append("npi", profile.npi);
    url.searchParams.append("state", formatStateName(profile.state_location));
    if (isSlotsFeatureFlagOn) {
      url.searchParams.append(SEARCH_IS_TESTING_SLOTS_KEY, "true");
    }
    setWidgetUrl(url.href);
  }, [isMobile, profile, isSlotsFeatureFlagOn]);

  const specialtyTags = profile.specialty_tags;
  const treatmentModalityTags = profile.treatment_modality_tags;

  const pageData = flattenNode(get(profile, "body"));

  const {
    name,
    quote,
    role,
    profile_photo,
    profile_body,
    languages,
    job_title,
    npi,
    pronouns,
    insurances,
    license_number,
    licensed_in,
    state_location,
  } = profile;

  const getRedirectUrl = (searchKey, searchValue) => {
    return `/${TEAM_PATHNAME}/${slugify(
      state_location
    )}?${searchKey}=${searchValue}`;
  };
  const licensedLabel = () => {
    switch (role?.toLowerCase()) {
      case providerRole.coach:
        return "Available in";
      default:
        return "Licensed in";
    }
  };

  const renderInsuranceList = () => {
    // safe guard to ensure no null insurance entries
    const activeInsuranceList = insurances?.filter(
      ({ insurance }) => insurance.uid
    );

    if (activeInsuranceList?.length > 0) {
      return (
        <h5>
          Insurances Accepted:{" "}
          {activeInsuranceList?.map(({ insurance }, index) => {
            const lastInsurance = index >= activeInsuranceList.length - 1;
            // Insurance options vary by state, so we include the state location in the URL
            // to guarantee that the insurance is available in the dropdown menu.
            return (
              <Link
                to={getRedirectUrl(SEARCH_INSURANCE_KEY, insurance.uid)}
                className={styles.insuranceItem}
                key={insurance.uid}
              >
                {`${insurance.document.data.name}${lastInsurance ? "" : ", "}`}
              </Link>
            );
          })}
        </h5>
      );
    }
    return null;
  };

  const renderLanguageList = () => {
    // safe guard to ensure no null language entries
    const activeLanguageList = languages?.filter(
      ({ language }) => language.uid
    );

    if (activeLanguageList?.length > 0) {
      return (
        <h5>
          Speaks{" "}
          {activeLanguageList?.map(({ language }, index) => {
            const lastLanguage = index >= activeLanguageList.length - 1;
            return (
              <Link
                to={getRedirectUrl(SEARCH_LANGUAGE_KEY, language.uid)}
                className={styles.languageItem}
                key={language.uid}
              >
                {`${language.uid}${lastLanguage ? "" : ", "}`}
              </Link>
            );
          })}
        </h5>
      );
    }
    return null;
  };

  if (isEmpty(profile)) return null;

  const renderAvailabilityWidget = () => {
    if (hideAvailabilityWidget) return;

    if (!isMobile) {
      return (
        <div className={styles.iframeDesktop}>
          <iframe
            style={{
              opacity: `${isAvailabilityWidgetFixed ? 1 : 0}`,
              pointerEvents: `${isAvailabilityWidgetFixed ? "auto" : "none"}`,
            }}
            src={widgetUrl}
          />
        </div>
      );
    }

    if (isMobile) {
      return (
        <div
          className={styles.bannerContainer}
          style={{
            position: `${isAvailabilityWidgetFixed ? "fixed" : "absolute"}`,
          }}
        >
          {/*
            //will be added when we get the data from the availability api
            <div className={styles.description}>Next available</div>
            <div className={styles.date}></div>
            */}

          <Button onClick={() => setOpenBookingModal(prevState => !prevState)}>
            {`Check ${name.text.split(" ")[0]}'s availability `}
          </Button>
        </div>
      );
    }
    return;
  };

  return (
    <>
      {!modal ? (
        <div className={styles.profileContainer}>
          <div className={styles.shapeContainer}>
            <ProfileShape />
          </div>
          <div className={styles.profile}>
            <div className={styles.profileInner}>
              <div className={styles.profileOverview}>
                <div className={styles.profileOverviewHeader}>
                  <div className={styles.profileOverviewHeaderImage}>
                    {profile_photo && (
                      <Image
                        imageObj={profile_photo}
                        alt={name.text}
                        className={styles.imageWrapper}
                        fallbackClass={styles.fallback}
                      />
                    )}
                  </div>
                  <div className={styles.profileOverviewHeaderInfo}>
                    <h3 className={styles.profileOverviewHeaderInfoName}>
                      {name.text}
                    </h3>

                    {pronouns && (
                      <h4 className={styles.profileOverviewHeaderInfoPronouns}>
                        {pronouns}
                      </h4>
                    )}

                    <div className={styles.profileOverviewHeaderInfoSubCopy}>
                      <h5>
                        {`${licensedLabel()} ${licensed_in} #${license_number}`}
                      </h5>
                      {renderInsuranceList()}
                      {renderLanguageList()}
                    </div>
                  </div>
                </div>

                {quote?.text && (
                  <div className={styles.profileOverviewQuote}>
                    {quote.text}
                  </div>
                )}
              </div>
              {pageData && (
                <Tabs
                  data={pageData}
                  specialtyTags={specialtyTags}
                  treatmentModalityTags={treatmentModalityTags}
                  getRedirectUrl={getRedirectUrl}
                />
              )}
            </div>
            {!isMobile && renderAvailabilityWidget(npi)}
          </div>
          {isMobile && renderAvailabilityWidget(npi)}
          <span ref={bioBottomRef} />
        </div>
      ) : (
        <div className={styles.modalProfile}>
          <div className={styles.title}>
            <h3>{title}</h3>
          </div>
          <div className={styles.inner}>
            <div className={styles.overview}>
              <div className={styles.leftCopy}>
                {profile_photo && (
                  <Image
                    imageObj={profile_photo}
                    alt={name.text}
                    className={styles.imageWrapper}
                    fallbackClass={styles.fallback}
                  />
                )}
                <h3 className={styles.header}>{name.text}</h3>
                <p className={styles.subheader}>{job_title}</p>
                {ctaCopy && <p className={styles.ctaText}>{ctaCopy}</p>}
                {ctaUrl ? (
                  <Button url={ctaUrl} text="Book a Consultation" isInline />
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className={styles.details}>
              <div className={styles.rightCopy}>
                <div dangerouslySetInnerHTML={{ __html: profile_body.html }} />
              </div>
            </div>
          </div>
        </div>
      )}
      <Dialog
        open={openBookingModal}
        onOpenChange={() => setOpenBookingModal(false)}
      >
        <iframe className={styles.iframeMobile} src={widgetUrl} />
      </Dialog>
    </>
  );
};

Profile.propTypes = {
  profile: PropTypes.object,
  title: PropTypes.string,
  ctaCopy: PropTypes.string,
  ctaUrl: PropTypes.string,
  modal: PropTypes.bool,
};

Profile.defaultProps = {
  profile: {},
  title: null,
  ctaCopy: null,
  ctaUrl: null,
  modal: false,
};

export default Profile;
