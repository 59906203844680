import React from "react";
import { graphql, PageProps } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { get } from "lodash";
import Layout from "../components/Layout/Layout";
import Profile from "../components/Profile/Profile";
import SeoUtil from "../components/SeoUtil/SeoUtil";
import { CurrentProviderContextProvider } from "../lib/hooks/useCurrentProviderContext";
import {
  flattenNode,
  getMetadata,
  mapAirtableProviderNodeToPrismic,
} from "../prismic/util";
import {
  SEARCH_SHOW_AVAILABILITY_WIDGET_KEY,
  SEARCH_IS_TESTING_SLOTS_KEY,
} from "../lib/constants";

const ProviderAirtableTemplate = ({
  data,
  location,
}: PageProps<Queries.ProviderDetailQuery>) => {
  const airtableProvider = flattenNode(get(data, "airtable"));
  const mappedProvider = mapAirtableProviderNodeToPrismic(airtableProvider);
  const providerData = get(mappedProvider, "data");
  // This is needed for the provider profile to pass the provider uid to the booking app via query string.
  if (providerData) {
    providerData["uid"] = mappedProvider?.uid;
  }

  const meta_title = `${providerData?.name.text} | Our Providers`;
  const meta_description = `Learn more about this Octave provider and book a therapy appointment.`;
  const ogImage = providerData?.profile_image?.url;
  const metadata = getMetadata(
    providerData,
    meta_title,
    meta_description,
    ogImage
  );

  const urlParams = new URLSearchParams(location?.search);
  const hideAvailabilityWidget =
    urlParams.get(SEARCH_SHOW_AVAILABILITY_WIDGET_KEY) === "false";
    const isSlotsFeatureFlagOn = urlParams.get(SEARCH_IS_TESTING_SLOTS_KEY) === "true";
  return (
    <CurrentProviderContextProvider value={providerData}>
      <Layout pageUid="provider-show" showContactForm={false}>
        <SeoUtil {...metadata} />
        <Profile
          title="Our Team"
          profile={providerData}
          hideAvailabilityWidget={hideAvailabilityWidget}
          isSlotsFeatureFlagOn={isSlotsFeatureFlagOn}
        />
      </Layout>
    </CurrentProviderContextProvider>
  );
};

export default withPrismicPreview(ProviderAirtableTemplate);

export const query = graphql`
  query ProviderDetail($recordId: String!) {
    airtable(recordId: { eq: $recordId }) {
      recordId
      data {
        Display_Name__for_website_
        Credentials
        State__for_website_view_
        Education
        NPI_Number
        License_Number
        Location
        Pronouns
        Specialties
        Insurance_Accepted
        Treatment_Modalities
        Specialties_Explanation
        Additional_Language_Capabilities
        Therapy_Style
        Quote__for_website_
        What_are_your_interests_outside_of_work_
        What_book_have_you_read_more_than_once_and_why_
        What_is_one_thing_you_do_daily_that_supports_your_mental_and_emotional_well_being_
        Do_you_have_any_pets_and_what_s_your_favorite_pet_story_
        Favorite_organization_non_profit_and_why_
        How_do_you_recharge_
        Why_did_you_become_a_mental_health_professional_
        Edited_Headshot {
          localFiles {
            url
          }
        }
      }
    }
  }
`;
