/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import * as React from "react";
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews";

import { octExternalUrlParams } from "./src/lib/constants";
import { setSessionStorage } from "./src/prismic/util";
import linkResolver from "./src/prismic/linkResolver";

import ArticleTemplate from "./src/templates/articleDetail";
import GroupTemplate from "./src/templates/groupDetail";
import LocationTemplate from "./src/templates/locationDetail";
import Page from "./src/templates/pageDetail";
import Post from "./src/templates/postDetail";
import ProviderTemplate from "./src/templates/providerDetail";
import LocationStateDetail from "./src/templates/locationStateDetail";
import { MixpanelProvider, mixpanel } from "./src/lib/mixpanel/tracking";

const repositoryConfigs = [
  {
    repositoryName: `${process.env.GATSBY_PRISMIC_REPOSITORY_NAME}`,
    linkResolver: linkResolver,
    componentResolver: {
      article: ArticleTemplate,
      page_container: Page,
      post_container: Post,
      group: GroupTemplate,
      location: LocationTemplate,
      provider: ProviderTemplate,
      state_location: LocationStateDetail,
    },
  },
];

const isMixpanelEnabled = () => {
  return (
    process.env.NODE_ENV === "production" && process.env.GATSBY_MIXPANEL_API_TOKEN
  );
};

// Logs when the client route changes
export const onRouteUpdate = ({ location }) => {
  if (isMixpanelEnabled()) {
    mixpanel.track_pageview();
  }

  if (
    location.key !== "initial" ||
    (!location.search && location.key === "initial")
  ) {
    return;
  }

  // store the query string params to session storage
  const currentUrl = new URL(location.href);
  // Don't store our own query params. This should go somewhere else..
  currentUrl.searchParams.delete("specialty_tags");
  currentUrl.searchParams.delete("languages");

  return setSessionStorage(
    octExternalUrlParams,
    currentUrl.searchParams.toString()
  );
};

export const onClientEntry = () => {
  if (isMixpanelEnabled()) {
    mixpanel.init(process.env.GATSBY_MIXPANEL_API_TOKEN as string);
  } else {
    mixpanel.init("disabled");
    mixpanel.disable();
  }
};

export const wrapRootElement = ({ element }) => {
  return (
    <MixpanelProvider>
      <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
        {element}
      </PrismicPreviewProvider>
    </MixpanelProvider>
  );
};
