import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Reveal from "../Reveal/Reveal";
import SlideController from "../SlideController/SlideController";
import SlideIndicators from "../SlideIndicators/SlideIndicators";
import styles from "./testimonials.module.scss";

const Testimonials = ({ items }) => {
  const [slide, setSlide] = useState(0);
  const [height, setHeight] = useState(60);

  useEffect(() => {
    window.removeEventListener("resize", resize);
    window.addEventListener("resize", resize);
    resize();
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  const resize = () => {
    // Set height of the slider
    const slides = document.querySelectorAll(`.${styles.slide}`);
    let height = 0;
    slides.forEach((slide, i) => {
      const slideheight = slide.getBoundingClientRect().height;
      if (slideheight > height) {
        height = slideheight;
      }
    });
    setHeight(height + 60);
  };

  const updateSlide = slide => setSlide(slide);

  return (
    <div className={styles.root}>
      <Reveal className={styles.inner}>
        <SlideController
          onUpdate={updateSlide}
          currentSlide={slide}
          count={items.length}
        >
          <div className={styles.slides} style={{ height }}>
            {items.map((item, i) => {
              const { quote, source } = item;
              return (
                <div
                  className={`${styles.slide} ${
                    slide === i ? styles.active : ""
                  }`}
                  key={i}
                >
                  <div className={styles.quote}>
                    <blockquote>{quote.text}</blockquote>
                  </div>
                  <span>{source.text}</span>
                </div>
              );
            })}
            <div className={styles.indicators}>
              <SlideIndicators
                currentSlide={slide}
                count={items.length}
                updateSlide={updateSlide}
              />
            </div>
          </div>
        </SlideController>
      </Reveal>
    </div>
  );
};

Testimonials.propTypes = {
  h1: PropTypes.string,
  testimonial: PropTypes.array.isRequired,
};

Testimonials.defaultProps = {
  h1: null,
  testimonial: [],
};

export default Testimonials;

export const fragment = graphql`
  fragment SliceTestimonials on PrismicPageContainerDataBodySectionTestimonials {
    slice_type
    slice_label
    items {
      quote {
        text
        html
      }
      source {
        text
      }
    }
  }
`;
