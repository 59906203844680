import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { PrismicRichText } from "@prismicio/react";
import Image from "../Image/Image";
import styles from "./press.module.scss";
import { useMixpanel } from "../../lib/mixpanel/tracking";
import { MIXPANEL_LINK_CLICK_EVENT_NAME } from "../../lib/constants";

const Press = ({ items, section_title }) => {
  const mixpanel = useMixpanel();

  const renderPartnerLogo = (imageObj, altText) => (
    <Image
      imageObj={imageObj}
      alt={altText || ""}
      className={styles.partner_logo}
    />
  );

  const handleClick = (source_link, source_name) => {
    mixpanel.track(MIXPANEL_LINK_CLICK_EVENT_NAME, {
      destination_url: source_link.url,
      origin_url: window?.location.href,
      Section: "Press Bar",
      cta_copy: source_name.text,
      cta_type: "Badge",
      category: "press bar",
    });

    window.location.href = source_link.url;
  };

  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <PrismicRichText field={section_title.richText} />
        <div className={styles.partners_wrapper}>
          {items.map((item, i) => {
            const { logo_image, source_link, source_name } = item;

            if (source_link?.url) {
              return (
                <div className={styles.item} key={i}>
                  <button
                    className={styles.imageWrapper}
                    onClick={() => handleClick(source_link, source_name)}
                  >
                    {renderPartnerLogo(logo_image, source_name.text)}
                    <div className={styles.caption}>See Press</div>
                  </button>
                </div>
              );
            }

            return (
              <div className={styles.item} key={i}>
                {renderPartnerLogo(logo_image, source_name.text)}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Press;

Press.propTypes = {
  press_items: PropTypes.array,
};

Press.defaultProps = {
  press_items: [],
};

// Prismic "Section Press" slice
export const fragment = graphql`
  fragment SlicePress on PrismicPageContainerDataBodySectionPress {
    slice_type
    slice_label
    primary {
      section_title {
        richText
      }
    }
    items {
      source_link {
        url
      }
      source_name {
        text
      }
      logo_image {
        url
        localFile {
          childImageSharp {
            gatsbyImageData(width: 300, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;
