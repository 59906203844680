export const formatStateName = (stateName: string) => {
  

  if (!stateName) {
    return;
  }
  // Remove commas
  stateName = stateName.replace(/,/g, "");

  // Replace spaces with underscores
  stateName = stateName.replace(/\s+/g, "_");

  // Convert to upper case
  stateName = stateName.toUpperCase();

  return stateName;
};
