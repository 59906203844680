import React from "react";
import { graphql, PageProps } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import get from "lodash/get";
import AnchorSection from "../components/AnchorSection/AnchorSection";
import HeroFloatingMask from "../components/HeroFloatingMask/HeroFloatingMask";
import Layout from "../components/Layout/Layout";
import IframeEmbed from "../components/IframeEmbed/IframeEmbed";
import LocationGroups from "../components/LocationGroups/LocationGroups";
import NumberedBlock from "../components/NumberedBlock/NumberedBlock";
import Providers from "../components/Providers/Providers";
import SeoUtil from "../components/SeoUtil/SeoUtil";
import SplitBlock from "../components/SplitBlock/SplitBlock";
import {
  adjustSlices,
  flattenNode,
  flattenSlice,
  getMetadata,
  getOpenGraphImage,
  mapAirtableProviderNodesToPrismic,
} from "../prismic/util";

const LocationStateDetail = ({
  data,
  pageContext,
}: PageProps<Queries.LocationStateDetailQuery>) => {
  const pageUid = get(data, "prismicStateLocation.uid");
  const pageData = flattenNode(get(data, "prismicStateLocation.data"));
  const showContactForm = pageData?.show_contact_form ?? true;
  const openGraphImg = getOpenGraphImage(pageData);
  const metadata = getMetadata(pageData, null, null, openGraphImg);
  const pageBody = adjustSlices(pageData);
  const sectionOfficeLocations = pageData.office_locations;
  //const stateProviders = get(data, "allAirtable.edges", []);
  //console.log("providers!", stateProviders);
  const remapProviders = mapAirtableProviderNodesToPrismic(
    data.allAirtable.edges
  );  

  // Used for Location Groups
  const flatOfficeLocations = sectionOfficeLocations.reduce((acc, office) => {
    if (office.location.document?.data) {
      // office_locations data is shaped as below, hence the need to
      // iterate and flatten array.
      acc.push({
        uid: office.location.uid,
        ...office.location.document?.data,
      });
    }
    return acc;
  }, []);

  // Site-wide content (shared across pages)
  const sectionNumbered = get(data, "prismicSectionNumbered.data");

  return (
    <Layout showContactForm={showContactForm} pageUid={pageUid}>
      <SeoUtil {...metadata} />
      {pageBody?.map((slice, i) => {
        const sliceFields = flattenSlice(slice);
        const { slice_type } = slice;
        const key = `location-state-detail-${slice_type}-${i}`;

        switch (slice_type) {
          case "section_hero_floating":
            return <HeroFloatingMask {...sliceFields} key={key} />;
          case "section_split":
            return (
              <SplitBlock
                {...sliceFields}
                image_collage={sliceFields.items}
                key={key}
              />
            );
          case "section_how_it_works":
            return <NumberedBlock {...sectionNumbered} key={key} />;
          case "section_providers":
            return (
              <Providers
                {...sliceFields}
                providers={remapProviders}
                providersAvailability={
                  pageContext.providersAvailability.pagedScheduleAvailabilityV2.availabilityList
                }
                key={key}
              />
            );
          case "section_clinic_locations":
            return (
              <LocationGroups
                singleGroupData={{
                  section_title: sliceFields.section_title,
                  office_locations: flatOfficeLocations,
                }}
                key={key}
              />
            );
          case "section_iframe":
            return <IframeEmbed {...sliceFields} key={key} />;
          case "section_anchor":
            return <AnchorSection {...sliceFields} key={key} />;
          default:
            return null;
        }
      })}
    </Layout>
  );
};

export default withPrismicPreview(LocationStateDetail);

export const query = graphql`
  query LocationStateDetail(
    $locationId: String!
    $two_letter_code: String!
  ) {
    site {
      siteMetadata {
        title
        description
      }
    }
    prismicStateLocation(uid: { eq: $locationId }) {
      uid
      prismicId
      _previewable
      data {
        name
        show_contact_form
        meta_title {
          text
        }
        meta_description
        generate_page
        og_image_overwrite {
          url
          gatsbyImageData(width: 1200, layout: CONSTRAINED)
        }
        office_locations {
          location {
            uid
            document {
              ... on PrismicOfficeLocation {
                ...LocationCardDef
              }
            }
          }
        }
        body {
          ... on PrismicStateLocationDataBodySectionClinicLocations {
            id
            items {
              clinic_locations {
                document {
                  ... on PrismicOfficeLocation {
                    ...LocationCardDef
                  }
                }
              }
            }
            primary {
              section_title {
                richText
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicStateLocationDataBodySectionHeroFloating {
            ...LocationSliceHeroFloating
          }
          ... on PrismicStateLocationDataBodySectionSplit {
            ...LocationSliceSplit
          }
          ... on PrismicStateLocationDataBodySectionHowItWorks {
            id
            primary {
              heading {
                richText
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicStateLocationDataBodySectionProviders {
            ...LocationDetailSliceProviders
          }
          ... on PrismicStateLocationDataBodySectionIframe {
            ...LocationSliceIframe
          }
          ... on PrismicStateLocationDataBodySectionAnchor {
            ...StateLocationSliceAnchor
          }
        }
      }
    }
    prismicSectionNumbered(uid: { eq: "how-it-works" }) {
      ...SectionNumberedDef
    }
    allAirtable(
      filter: {
        data: {
          Status: { eq: "Active" }
          Publish_to_Website: { eq: true }
          Green_Light_Done: { eq: "checked" }
          State__for_website_view_: { eq: $two_letter_code }
        }
      }
      sort: { data: { Last_Name: ASC } }
    ) {
      edges {
        node {
          ...ProviderDetailsDef
        }
      }
    }
  }
`;
