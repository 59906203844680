import React from "react";
import PropTypes from "prop-types";
import styles from "./article-footer.module.scss";

const ArticleFooter = ({ footer_text }) => {
  return (
    <div className={styles.root}>
      <div className={styles.imageContain}>
        <img src="/images/logomark.png" alt="Octave Logo" />
      </div>

      <div className={styles.footerText}>
        <div dangerouslySetInnerHTML={{ __html: footer_text.html }} />
      </div>
    </div>
  );
};

ArticleFooter.propTypes = {
  footer_text: PropTypes.object,
};

ArticleFooter.defaultProps = {
  footer_text: null,
};

export default ArticleFooter;
