import React from "react";
import PropTypes from "prop-types";
import styles from "./validation-text.module.scss";

export const ValidationText = ({ errorMessage }) => {
  return (
    <div
      data-test="validation-error-message"
      className={styles.error_helper_text}
    >
      {errorMessage}
    </div>
  );
};

ValidationText.propTypes = {
  errorMessage: PropTypes.string,
};

ValidationText.defaultProps = {
  errorMessage: "",
};
