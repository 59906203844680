import {
  Provider,
  ProvidersWithAvailability,
} from "../../components/ProvidersList/types";
import { formatStateName } from "./formatStateName";
import {
  WEEKDAYS,
  AFTERNOON_HOUR,
  MORNING_HOUR,
  EARLY_HOUR,
} from "../constants";

export const getProvidersWithAvailability = (
  remapProvidersArray: Provider[],
  schedulesArray
): ProvidersWithAvailability[] => {
  const map = new Map();

  remapProvidersArray.forEach(item => {
    const npiKey = item.node.data.npi;
    map.set(npiKey, item);
  });

  schedulesArray.forEach(item => {
    const npiKey = item.schedule.practitioner.nationalProviderIdentifier;
    if (map.has(npiKey)) {

      const uniqueDaysOfWeek = [
        ...new Set(item.availability.map(i => new Date(i).getDay())),
      ];
      const daysOfWeek = uniqueDaysOfWeek.map((i: number) => WEEKDAYS[i]);

      const timesOfDay = [];

      const daysOfWeekAndTimesOfDay = [];

      item.availability.forEach(i => {
        const date = new Date(i);
        const hour = date.getHours();
        const day = date.getDay();

        if (hour < EARLY_HOUR) {
          timesOfDay.push("early");
          daysOfWeekAndTimesOfDay.push(`${WEEKDAYS[day]}-early`);
        } else if (hour < MORNING_HOUR && date.getHours() >= EARLY_HOUR) {
          timesOfDay.push("morning");
          daysOfWeekAndTimesOfDay.push(`${WEEKDAYS[day]}-morning`);
        } else if (hour < AFTERNOON_HOUR && date.getHours() >= MORNING_HOUR) {
          timesOfDay.push("afternoon");
          daysOfWeekAndTimesOfDay.push(`${WEEKDAYS[day]}-afternoon`);
        } else if (hour >= AFTERNOON_HOUR) {
          timesOfDay.push("evening");
          daysOfWeekAndTimesOfDay.push(`${WEEKDAYS[day]}-evening`);
        }
      });

      const existingItem = map.get(npiKey);

      //make sure the state of the provider matches the state of the schedule
      if (
        formatStateName(existingItem.node.data.state_location) !==
        item.schedule.practitioner.states.at(0)
      )
        return;

      existingItem.node.data.availabilityInfo = {
        ...item.schedule,
        availability: item.availability,
        daysOfWeek: daysOfWeek,
        timesOfDay: [...new Set(timesOfDay)],
        daysOfWeekAndTimesOfDay: [...new Set(daysOfWeekAndTimesOfDay)],
      };
    }
  });

  return Array.from(map.values());
};
