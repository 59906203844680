import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { graphql } from "gatsby";
import Image from "../Image/Image";
import styles from "./press-alt.module.scss";
import Tooltip from "../Tooltip/Tooltip";
import { useMixpanel } from "../../lib/mixpanel/tracking";
import { MIXPANEL_LINK_CLICK_EVENT_NAME } from "../../lib/constants";

const PressAlt = ({ items, section_title }) => {
  const mixpanel = useMixpanel();
  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <PrismicRichText
          field={section_title.richText}
          components={{
            heading1: ({ children }) => (
              <h1 className={styles.title}>{children}</h1>
            ),
            heading2: ({ children }) => (
              <h2 className={styles.title}>{children}</h2>
            ),
            heading3: ({ children }) => (
              <h3 className={styles.title}>{children}</h3>
            ),
            heading4: ({ children }) => (
              <h4 className={styles.title}>{children}</h4>
            ),
            heading5: ({ children }) => (
              <h5 className={styles.title}>{children}</h5>
            ),
            heading6: ({ children }) => (
              <h6 className={styles.title}>{children}</h6>
            ),
          }}
        />
        <div className={styles.partners}>
          {items.map((item, i) => {
            const { logo_image, link, show_tooltip, tooltip_content } = item;

            if (link?.url) {
              return (
                <div key={`press-alt-logo-${i}`} className={styles.img_wrapper}>
                  <a
                    href={link.url}
                    target={link.target || "_blank"}
                    rel="noopener noreferrer"
                    onClick={() =>
                      mixpanel.track(MIXPANEL_LINK_CLICK_EVENT_NAME, {
                        destination_url: link.url,
                        origin_url: window?.location.href,
                        Section: "Insurance Providers",
                        cta_copy: logo_image.alt || "",
                        cta_type: "Badge",
                        category: "Insurance",
                      })
                    }
                  >
                    <Image
                      className={styles.logo}
                      imageObj={logo_image}
                      alt={logo_image.alt || ""}
                    />
                  </a>
                  {show_tooltip && tooltip_content && (
                    <Tooltip tooltipContent={tooltip_content} />
                  )}
                </div>
              );
            }

            return (
              <div className={styles.img_wrapper} key={`press-alt-logo-${i}`}>
                <Image
                  className={styles.logo}
                  imageObj={logo_image}
                  alt={logo_image.alt || ""}
                />
                {show_tooltip && tooltip_content && (
                  <Tooltip tooltipContent={tooltip_content} />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PressAlt;

// Prismic "Section Press Alt" slice
export const fragment = graphql`
  fragment SlicePressAlt on PrismicPageContainerDataBodySectionPressAlt {
    slice_type
    slice_label
    primary {
      section_title {
        richText
      }
    }
    items {
      logo_image {
        alt
        url
        localFile {
          childImageSharp {
            gatsbyImageData(width: 300, layout: CONSTRAINED)
          }
        }
      }
      link {
        url
        target
      }
      link_label
      show_tooltip
      tooltip_content
    }
  }
`;
