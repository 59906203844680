import React from "react";
import PropTypes from "prop-types";
import ButtonPdf from "../ButtonPdf/ButtonPdf";
import Image from "../Image/Image";
import styles from "./article-header.module.scss";

const ArticleHeader = ({
  article_title,
  header_image,
  intro_text,
  pdfLink,
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.imageContain}>
        {header_image && header_image.url && (
          <>
            <Image imageObj={header_image} style={{ maxHeight: 250 }} />
            <div className={styles.bg} />
          </>
        )}
      </div>
      <div className={styles.sectionHeaderContain}>
        <div className={styles.sectionHeader}>
          <h2 className={styles.heading}>{article_title.text}</h2>
        </div>

        {intro_text && (
          <div
            className={styles.sectionContent}
            dangerouslySetInnerHTML={{ __html: intro_text.html }}
          />
        )}
        {pdfLink && <ButtonPdf url={pdfLink} />}
      </div>
    </div>
  );
};

ArticleHeader.propTypes = {
  article_title: PropTypes.object,
  header_image: PropTypes.object,
  intro_text: PropTypes.object,
  pdfLink: PropTypes.string,
};

ArticleHeader.defaultProps = {
  article_title: null,
  header_image: null,
  intro_text: null,
  pdfLink: null,
};

export default ArticleHeader;
