import React from "react";
import PropTypes from "prop-types";
import Image from "../Image/Image";
import styles from "./article-section-full.module.scss";

const ArticleSectionFull = ({
  section_text,
  section_image,
  section_image_align,
}) => {
  let imageAlign = null;

  if (section_image && section_image.url) {
    imageAlign = `${section_image_align}Align`;
  }

  return (
    <div className={styles.root}>
      <div
        className={`${styles.sectionContain} ${
          imageAlign ? styles[imageAlign] : ""
        }`}
      >
        {section_image && section_image.url && (
          <div className={`${styles.imageContain}`}>
            <Image imageObj={section_image} />
          </div>
        )}
        <div
          className={styles.sectionContent}
          dangerouslySetInnerHTML={{ __html: section_text.html }}
        />
      </div>
    </div>
  );
};

ArticleSectionFull.propTypes = {
  section_text: PropTypes.object,
  section_image: PropTypes.object,
  section_image_align: PropTypes.string,
};

ArticleSectionFull.defaultProps = {
  section_text: null,
  section_image: null,
  section_image_align: null,
};

export default ArticleSectionFull;
