import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Button from "../Button/Button";
import Reveal from "../Reveal/Reveal";
import styles from "./hotlines-list.module.scss";

const HotlinesList = ({ items }) => (
  <div className={styles.root}>
    <div className={styles.inner}>
      {items &&
        items.map((item, index) => (
          <Reveal className={styles.item} key={item.hotline_name.text}>
            <div className={styles.itemCopy}>
              {item.hotline_name && <h4>{item.hotline_name.text}</h4>}
              {item.hours && <p>{item.hours}</p>}
            </div>
            <div className={styles.itemCta}>
              <Button text={item.phone_number} url={item.phone_link} />
            </div>
          </Reveal>
        ))}
    </div>
  </div>
);

HotlinesList.propTypes = {
  hotlines: PropTypes.array,
};

HotlinesList.defaultProps = {
  hotlines: [],
};

export default HotlinesList;

export const fragment = graphql`
  fragment SliceHotlines on PrismicPageContainerDataBodySectionHotlines {
    slice_type
    slice_label
    items {
      hotline_name {
        text
      }
      phone_link
      hours
      phone_number
    }
  }
`;
