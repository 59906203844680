import React, { useState } from "react";
import styles from "./tooltip.module.scss";
import InfoIcon from "../../images/info-icon.inline.svg";

import * as PrimitiveTooltip from "@radix-ui/react-tooltip";

const Tooltip = ({ tooltipContent }) => {
  const [open, setOpen] = useState(false);

  return (
    <PrimitiveTooltip.Provider>
      <PrimitiveTooltip.Root
        delayDuration={0}
        open={open}
        onOpenChange={setOpen}
      >
        <PrimitiveTooltip.Trigger asChild >
          <button
            className={styles.iconButton}
            onClick={() => setOpen(prev => !prev)}
          >
            <InfoIcon />
          </button>
        </PrimitiveTooltip.Trigger>
        <PrimitiveTooltip.Portal>
          <PrimitiveTooltip.Content
            side="right"
            className={styles.tooltipContent}
            sideOffset={5}
          >
            {tooltipContent}
          </PrimitiveTooltip.Content>
        </PrimitiveTooltip.Portal>
      </PrimitiveTooltip.Root>
    </PrimitiveTooltip.Provider>
  );
};

export default Tooltip;