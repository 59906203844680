import React from "react";
import PropTypes from "prop-types";
import Aside from "../Aside/Aside";
import Button from "../Button/Button";
import ParallaxImages from "../ParallaxImages/ParallaxImages";
import Reveal from "../Reveal/Reveal";
import styles from "./split-block-default.module.scss";

const SplitBlockDefault = ({
  section_title,
  section_text,
  cta_text,
  cta_link,
  section_footnote,
  image_collage,
  is_inverted,
  footnote_icon,
  align_background,
  image_ratio,
}) => {
  const imageList = image_collage?.map(img => {
    if (img.stacked_image) return img.stacked_image;
    if (img.split_panel_image) return img.split_panel_image;
    return null;
  });
  const iconPath = footnote_icon ? `/images/${footnote_icon}.svg` : null;

  return (
    <div className={`${styles.root} ${is_inverted ? styles.inverted : ""}`}>
      <div className={styles.inner}>
        <div className={styles.blockImage}>
          <Reveal className={styles.images}>
            {imageList && (
              <ParallaxImages
                images={imageList}
                wideBackground={align_background}
                imageRatio={image_ratio}
              />
            )}
          </Reveal>
        </div>
        <div className={styles.blockCopy}>
          <Reveal className={styles.copy}>
            {section_title && <h2>{section_title.text}</h2>}
            {section_text && (
              <div dangerouslySetInnerHTML={{ __html: section_text.html }} />
            )}
            {cta_text && cta_link && (
              <Button text={cta_text} url={`${cta_link.url}`} />
            )}
            {section_footnote && (
              <Aside icon={iconPath || "/images/question.svg"}>
                {section_footnote}
              </Aside>
            )}
          </Reveal>
        </div>
      </div>
    </div>
  );
};

SplitBlockDefault.propTypes = {
  section_title: PropTypes.object,
  section_text: PropTypes.object,
  cta_text: PropTypes.string,
  cta_link: PropTypes.object,
  section_footnote: PropTypes.object,
  image_collage: PropTypes.array,
  is_inverted: PropTypes.bool,
  footnote_icon: PropTypes.string,
  align_background: PropTypes.string,
  image_ratio: PropTypes.number,
};

SplitBlockDefault.defaultProps = {
  section_title: null,
  section_text: null,
  cta_text: null,
  cta_link: null,
  section_footnote: null,
  image_collage: null,
  is_inverted: false,
  footnote_icon: null,
  align_background: null,
  image_ratio: null,
};

export default SplitBlockDefault;
