import { useMemo } from "react";
import {
  ALL_LOCATIONS_VALUE,
  ProviderListFilters,
} from "./useProviderListFilters";
import { ProvidersWithAvailability } from "../types";
import { slugify } from "../../../prismic/util";

export const useMatchingProviders = (
  providers: ProvidersWithAvailability[],
  filters: ProviderListFilters
) => {
  return useMemo(() => {
    const {
      specialties,
      modalities,
      languages,
      licenses,
      location,
      insurances,
      name,
      availability,
      dayOfWeek,
      timeOfDay,
    } = filters;

    return providers.filter(provider => {
      const providerLocationSlug = slugify(provider.node.data.state_location);
      const matchesLocation =
        location !== ALL_LOCATIONS_VALUE
          ? providerLocationSlug === location
          : true;

      const matchesSpecialties = specialties.length
        ? specialties.every(specialty =>
            provider.node.data.specialty_tags?.some(
              st => st.tag.uid === specialty
            )
          )
        : true;

      const matchesModalities = modalities.length
        ? modalities.every(modality =>
            provider.node.data.treatment_modality_tags?.some(
              st => st.tag.uid === modality
            )
          )
        : true;

      const matchesLanguages = languages.length
        ? languages.every(language =>
            provider.node.data.languages?.some(l => l.language.uid === language)
          )
        : true;

      const matchesLicenses = licenses.length
        ? licenses.every(license =>
            provider.node.data.license_groups?.some(
              l => l.license.uid === license
            )
          )
        : true;

      const matchesInsurances = insurances.length
        ? insurances.every(insurance =>
            provider.node.data.insurances?.some(
              i => i.insurance.uid === insurance
            )
          )
        : true;

      const matchesNameSearch = name
        ? // Match only if an element of the name starts with the search term.
          provider.node.data.name.text
            .split(" ")
            .some(n => n.toLowerCase().startsWith(name.toLowerCase()))
        : true;

      const matchesAvailability =
        availability === ""
          ? true
          : provider.node.data.availabilityInfo?.availability?.length > 0;

      const matchesDayOfWeek = dayOfWeek?.length
        ? dayOfWeek.every(day =>
            provider.node.data.availabilityInfo?.daysOfWeek?.includes(day)
          )
        : true;

      const matchesTimeOfDay = timeOfDay?.length
        ? timeOfDay.some(day =>
            provider.node.data.availabilityInfo?.timesOfDay?.some(
              time => time === day
            )
          )
        : true;

      const matchesDayAndTime =
        dayOfWeek?.length && timeOfDay?.length
          ? provider.node.data.availabilityInfo?.daysOfWeekAndTimesOfDay?.some(
              dayAndTime =>
                dayOfWeek.some(day => dayAndTime.includes(day)) &&
                timeOfDay.some(time => dayAndTime.includes(time))
            )
          : true;

      return (
        matchesLocation &&
        matchesSpecialties &&
        matchesModalities &&
        matchesLanguages &&
        matchesLicenses &&
        matchesInsurances &&
        matchesNameSearch &&
        matchesAvailability &&
        matchesDayOfWeek &&
        matchesTimeOfDay &&
        matchesDayAndTime
      );
    });
  }, [providers, filters]);
};
