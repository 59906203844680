import React from "react";
import styles from "./hero-floating-mask.module.scss";

/**
 * Mask for hero image. Use same "id" of "my-clip-path" to be shared 
 * and used with clip-path in css of "clipped" element.
 *
 * @returns JSX.Element
 */
const HeroImageMask = () => {
  return (
    <svg className={styles.svg}>
      <clipPath id="my-clip-path" clipPathUnits="objectBoundingBox">
        <path d="M0.892,0.3 C0.892,0.567,0.543,0.83,0.311,0.83 C0.08,0.83,-0.108,0.798,-0.108,0.3 C-0.108,0.034,-0.093,-0.219,0.397,-0.163 C0.628,-0.163,0.892,0.034,0.892,0.3"></path>
      </clipPath>
    </svg>
  );
};

export default HeroImageMask;
