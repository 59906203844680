import React, { useEffect } from "react";
import outdatedBrowserRework from "outdated-browser-rework";
import "./outdatebrowser.module.scss";

const OutdatedBrowser = () => {
  useEffect(() =>
    outdatedBrowserRework({
      browserSupport: {
        Chrome: 70,
        Edge: 15,
        Safari: 10,
        "Mobile Safari": 11,
        Firefox: 10,
        Opera: 50,
        IE: false,
        FBAN: true,
        FBAV: true,
        Instagram: true,
      },
      requireChromeOnAndroid: false,
      isUnknownBrowserOK: true, // Needs this to avoid displaying message on Facebook/Instagram internal browser - See https://github.com/mikemaccana/outdated-browser-rework/issues/57
      debug: false,
    })
  );
  return <div id="outdated" />;
};

export default OutdatedBrowser;
