import React, { memo } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import Image from "../Image/Image";
import styles from "./mini-profile.module.scss";

const MiniProfile = ({
  header,
  subheader,
  image,
  cardColor,
  uid,
  ctaAction,
}) => {
  return (
    <>
      {uid ? (
        <Link
          to={`/providers/${uid}`}
          className={styles.root}
          style={{ backgroundColor: cardColor }}
        >
          <Image
            imageObj={image}
            alt={header.text}
            className={styles.imageWrapper}
            fallbackClass={styles.fallback}
            lazyLoad
          />
          <div className={styles.providerCopy}>
            <h5>{header.text}</h5>
            <p>{subheader}</p>
          </div>
        </Link>
      ) : (
        <div
          role="button"
          tabIndex="0"
          onClick={ctaAction}
          onKeyDown={ctaAction}
          aria-label={header.text}
          className={styles.root}
          style={{ backgroundColor: cardColor }}
        >
          <Image
            imageObj={image}
            alt={header.text}
            className={styles.imageWrapperAlt}
            fallbackClass={styles.fallback}
          />
          <div className={styles.providerCopyAlt}>
            <h4>{header.text}</h4>
            <p>{subheader}</p>
          </div>
        </div>
      )}
    </>
  );
};

MiniProfile.propTypes = {
  header: PropTypes.object,
  subheader: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  cardColor: PropTypes.string,
  ctaText: PropTypes.string,
  ctaAction: PropTypes.func,
  uid: PropTypes.string,
};

MiniProfile.defaultProps = {
  header: {},
  subheader: "",
  image: null,
  cardColor: "#F9EECF",
  ctaText: "Bio",
  ctaAction: null,
  uid: "",
};

export default memo(MiniProfile);
