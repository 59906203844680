import React from "react";
import PropTypes from "prop-types";
import styles from "./legal-body.module.scss";

const LegalBody = ({ body_text, anchor }) => (
  <div className={styles.root}>
    {anchor && <div id={anchor} style={{ padding: "4rem 0" }}></div>}
    <div className={styles.inner}>
      <div dangerouslySetInnerHTML={{ __html: body_text.html }} />
    </div>
  </div>
);

LegalBody.propTypes = {
  body_text: PropTypes.object.isRequired,
  anchor: PropTypes.string,
};

export default LegalBody;
