import { useEffect, useState } from "react";
import { SCREEN_SIZE } from "../constants";

export const useWindowDimensions = () => {
  const isBrowser = typeof window !== "undefined";

  const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    
    if (isBrowser) {
      handleWindowResize();
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [isBrowser]);

  const isMobile = width <= SCREEN_SIZE.MOBILE_LANDSCAPE;

  return { isMobile };
};
