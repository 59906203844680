import React from "react";
import styles from "./link-button.module.scss";

const LinkButton = ({ children, onClick, className }) => (
  <button className={`${styles.linkButton} ${className}`} onClick={onClick}>
    {children}
  </button>
);

export default LinkButton;
