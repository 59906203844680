import React from "react";
import { graphql, PageProps } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { get } from "lodash";
import HeroSlides from "../components/HeroSlides/HeroSlides";
import Layout from "../components/Layout/Layout";
// import LocationAmenities from "../components/LocationAmenities/LocationAmenities"
import LocationDetails from "../components/LocationDetails/LocationDetails";
import NumberedBlock from "../components/NumberedBlock/NumberedBlock";
import Providers from "../components/Providers/Providers";
import SeoUtil from "../components/SeoUtil/SeoUtil";
import IframeEmbed from "../components/IframeEmbed/IframeEmbed";
import AnchorSection from "../components/AnchorSection/AnchorSection";
import { locationModel } from "../lib/constants";
import {
  adjustSlices,
  flattenNode,
  flattenSlice,
  getMetadata,
  mapAirtableProviderNodesToPrismic,
} from "../prismic/util";

const LocationTemplate = ({ data,pageContext }: PageProps<Queries.LocationDetailQuery>) => {
  const locationData = get(data, "prismicOfficeLocation.data", {});
  const pageData = flattenNode(locationData);
  const pageBody = adjustSlices(pageData);
  const showContactForm = locationData?.show_contact_form ?? true;
  const ogImage =
    locationData?.thumbnail_image?.url ||
    locationData?.photo_carousel?.[0]?.photo?.url;
  const metadata = getMetadata(locationData, null, null, ogImage);
  const providers = get(data, "allAirtable.edges", []); 

  const {
    body,
    office,
    description,
    footnote,
    photo_carousel,
    thumbnail_tagline,
    contact,
    hours,
    transit,
    address,
    geo,
    state,
  } = locationData;

  // Data schema for Virtual is not set in stone
  // When it comes to Virtual specific cities ie. LA
  // but there are no providers for that office location,
  // default to their Virtual.
  // TODO -- Future schema hierarchy changes for locations
  const virtualCAProviders = get(data, "virtualCAProviders.edges", []);
  const virtualNYProviders = get(data, "virtualNYProviders.edges", []);
  let providerProps = providers;

  if (providers.length === 0 && office === locationModel.virtualCA) {
    providerProps = virtualCAProviders;
  } else if (providers.length === 0 && office === locationModel.virtualNY) {
    providerProps = virtualNYProviders;
  }

  const remapProviders = mapAirtableProviderNodesToPrismic(providerProps);

  const hero = {
    office,
    state,
    description,
    footnote,
    photo_carousel,
  };

  const locationDetails = {
    body,
    thumbnail_tagline,
    contact,
    hours,
    transit,
    address,
    geo,
  };

  // Side-wide content (shared across pages)
  const sectionNumbered = get(data, "prismicSectionNumbered.data");

  return (
    <Layout showContactForm={showContactForm} pageUid="location-show">
      <SeoUtil {...metadata} />
      <HeroSlides {...hero} />
      <Providers
        providersAvailability={
          pageContext.providersAvailability.pagedScheduleAvailabilityV2.availabilityList
        }
        providers={remapProviders}
      />

      <LocationDetails {...locationDetails} />
      {/*// TODO: no example for this component */}
      {/* {process.env.SHOW_STUDIO_AMENITIES === "true" && (
        <LocationAmenities {...amenities} />
      )} */}
      {pageBody?.map((slice, i) => {
        const sliceFields = flattenSlice(slice);
        const { slice_type } = slice;
        const key = `location-state-detail-${slice_type}-${i}`;

        switch (slice_type) {
          case "section_how_it_works":
            return <NumberedBlock {...sectionNumbered} key={key} />;
          case "section_iframe":
            return <IframeEmbed {...sliceFields} key={key} />;
            case "section_anchor":
              return <AnchorSection {...sliceFields} key={key} />;
          default:
            return null;
        }
      })}
    </Layout>
  );
};

export default withPrismicPreview(LocationTemplate);

export const query = graphql`
  query LocationDetail(
    $officeId: String!
    $airtable_location_field: String!
  ) {
    site {
      siteMetadata {
        title
        description
      }
    }
    prismicOfficeLocation(uid: { eq: $officeId }) {
      ...LocationDetailsDef
    }
    allAirtable(
      filter: {
        data: {
          Status: { eq: "Active" }
          Publish_to_Website: { eq: true }
          Green_Light_Done: { eq: "checked" }
          Location: { eq: $airtable_location_field }
        }
      }
      sort: { data: { Last_Name: ASC } }
    ) {
      edges {
        node {
          ...ProviderDetailsDef
        }
      }
    }
    virtualCAProviders: allAirtable(
      filter: {
        data: {
          Status: { eq: "Active" }
          Publish_to_Website: { eq: true }
          Location: { eq: "CA - Virtual" }
        }
      }
      sort: { data: { Last_Name: ASC } }
    ) {
      edges {
        node {
          ...ProviderDetailsDef
        }
      }
    }
    virtualNYProviders: allAirtable(
      filter: {
        data: {
          Status: { eq: "Active" }
          Publish_to_Website: { eq: true }
          Green_Light_Done: { eq: "checked" }
          Location: { eq: "NY - Virtual" }
        }
      }
      sort: { data: { Last_Name: ASC } }
    ) {
      edges {
        node {
          ...ProviderDetailsDef
        }
      }
    }
    prismicSectionNumbered(uid: { eq: "how-it-works" }) {
      ...SectionNumberedDef
    }
  }
`;
