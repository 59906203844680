import React, { useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Hammer from "react-hammerjs";

const percentShown = 0.3;

const SlideController = ({
  currentSlide,
  onUpdate,
  count,
  autoPlay,
  className,
  children,
}) => {
  const isListening = useRef(false);
  const saveRef = useRef(null);
  const interval = useRef(null);

  const handleUpdate = useCallback(
    slide => {
      onUpdate(slide);
    },
    [onUpdate]
  );

  const incrementSlide = useCallback(
    isNext => {
      if (!inViewport()) return;

      let slide = currentSlide + (isNext ? 1 : -1);
      if (slide < 0) {
        slide = count - 1;
      } else if (slide >= count) {
        slide = 0;
      }

      handleUpdate(slide);
    },
    [handleUpdate, count, currentSlide]
  );

  const onKeydown = useCallback(
    event => {
      switch (event.keyCode) {
        case 37: // Left
          clean();
          incrementSlide(false);
          break;
        case 39: // Right
          clean();
          incrementSlide(true);
          break;
        default:
          break;
      }
    },
    [incrementSlide]
  );

  useEffect(() => {
    // TODO: need to investigate the purpose of this line
    document.removeEventListener("keydown", onKeydown);
    // TODO: resize function is missing
    // document.removeEventListener("resize", resize)
    document.addEventListener("keydown", onKeydown);

    if (!isListening.current && autoPlay) {
      interval.current = setInterval(() => incrementSlide(true), 13000);
      isListening.current = true;
    }
    return () => {
      document.removeEventListener("keydown", onKeydown);
      // TODO: resize function is missing
      // document.removeEventListener("resize", resize)
      if (isListening.current) {
        clean();
      }
    };
  }, [autoPlay, incrementSlide, onKeydown]);

  const handleSwipe = event => {
    switch (event.offsetDirection) {
      case 4: // Left
        clean();
        incrementSlide(false);
        break;
      case 2: // Right
        clean();
        incrementSlide(true);
        break;
      default:
        break;
    }
  };

  const clean = () => {
    // In case we need to access this externally
    clearInterval(interval.current);
    clearInterval(interval);
    isListening.current = false;
  };

  const inViewport = () => {
    if (!saveRef.current || window.document.hidden) return false;
    const { top, height } = saveRef.current.getBoundingClientRect();
    const scrolledTo = top < window.innerHeight * (1 - percentShown);
    const notScrolledPast = top + height > window.innerHeight * percentShown;
    return scrolledTo && notScrolledPast;
  };

  return (
    <div ref={saveRef} className={className}>
      <Hammer onSwipe={handleSwipe}>{children}</Hammer>
    </div>
  );
};

export default SlideController;

SlideController.propTypes = {
  currentSlide: PropTypes.number.isRequired,
  onUpdate: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  autoPlay: PropTypes.bool,
  className: PropTypes.string,
};

SlideController.defaultProps = {
  autoPlay: true,
  className: "",
};
