import React from "react";
import { graphql } from "gatsby";
import ClinicBoard from "./ClinicBoard";
import DeptBoard from "./DeptBoard";
import { JobBoardContextProvider } from "./JobBoardContext";
import styles from "./job-board.module.scss";

const JobBoard = ({ add_clinician_board, description, title }) => {
  return (
    <div className={styles.root} id="listings">
      <div className={styles.inner}>
        <div className={styles.intro}>
          <div dangerouslySetInnerHTML={{ __html: title.html }} />
          <div dangerouslySetInnerHTML={{ __html: description.html }} />
        </div>
        {add_clinician_board ? (
          <ClinicBoard />
        ) : (
          <JobBoardContextProvider>
            <DeptBoard />
          </JobBoardContextProvider>
        )}
      </div>
    </div>
  );
};

export default JobBoard;

// Prismic "Section Job Board" slice
export const fragment = graphql`
  fragment SliceJobBoard on PrismicPageContainerDataBodyJobBoard {
    id
    slice_label
    slice_type
    primary {
      title {
        html
      }
      description {
        html
      }
      add_clinician_board
    }
  }
`;
