import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import styles from "./anchor-section.module.scss";

const AnchorSection = ({
  hash_label,
  overwritePadding,
  hideOnMobile,
  hideOnDesktop,
}) => {
  return (
    <div
      id={hash_label}
      {...(overwritePadding ? { style: { padding: overwritePadding } } : null)}
      className={`${styles.root} ${
        hideOnMobile
          ? styles.hideOnMobile
          : hideOnDesktop
          ? styles.hideOnDesktop
          : ""
      }`}
    />
  );
};

export default AnchorSection;

AnchorSection.propTypes = {
  hash_label: PropTypes.string.isRequired,
  overwritePadding: PropTypes.string,
  hideOnMobile: PropTypes.bool,
  hideOnDesktop: PropTypes.bool,
};

AnchorSection.defaultProps = {
  hideOnMobile: false,
  hideOnDesktop: false,
};

// Prismic "Section Anchor" slice
export const fragment = graphql`
  fragment SliceAnchor on PrismicPageContainerDataBodySectionAnchor {
    id
    slice_type
    primary {
      hash_label
    }
  }
  fragment StateLocationSliceAnchor on PrismicStateLocationDataBodySectionAnchor {
    id
    slice_type
    primary {
      hash_label
    }
  }
  fragment OfficeLocationSliceAnchor on PrismicOfficeLocationDataBodySectionAnchor {
    id
    slice_type
    primary {
      hash_label
    }
  }
`;
