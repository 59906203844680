const linkResolver = doc => {
  // This is an absolute path or matches the domain...
  if (doc.link_type === "Web") {
    const productionUrl = "https://www.findoctave.com/";
    const altProductionUrl = "https://findoctave.com/";
    const hasProductionUrl = doc.url.includes(productionUrl);
    const hasAltProductionUrl = doc.url.includes(altProductionUrl);

    if (hasProductionUrl) {
      const url = doc.url.replace(productionUrl, "");
      return `/${url}`;
    }
    if (hasAltProductionUrl) {
      const url = doc.url.replace(altProductionUrl, "");
      return `/${url}`;
    }

    return `${doc.url}`;
  }

  // URL for a provider type
  if (doc.type === "provider") {
    return `/providers/${doc.uid}/`;
  }

  // URL for a group type
  if (doc.type === "group") {
    return `/groups/${doc.uid}/`;
  }

  // URL for an provider resource article type
  if (doc.type === "article") {
    return `/provider-resources/${doc.uid}/`;
  }

  // URL for a page type
  if (doc.type === "page_container") {
    return `/${doc.uid}/`;
  }

  // URL for a post type
  if (doc.type === "post_container") {
    return `/${doc.uid}/`;
  }

  // URL for a location type
  if (doc.type === "location_tags") {
    return `/locations/${doc.uid}/`;
  }

  // URL for a location type
  if (doc.type === "location") {
    return `/${doc.uid}/`;
  }

  // URL for a group list type
  if (doc.type === "group_list") {
    return `/${doc.uid}/`;
  }

  if (doc.type === "state_location") {
    return `/locations/${doc.uid}/`;
  }

  // Backup for all other types
  return "/";
};

module.exports = linkResolver;
