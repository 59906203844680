import React from "react";
import PropTypes from "prop-types";
import AnimatedLine from "../AnimatedLine/AnimatedLine";
import Button from "../Button/Button";
import ParallaxImages from "../ParallaxImages/ParallaxImages";
import Reveal from "../Reveal/Reveal";
import styles from "./split-block-intro.module.scss";

const IntroBlock = ({
  cta_link,
  cta_text,
  section_title,
  section_text,
  bottom_title,
  image_collage,
  align_background,
  divider,
}) => {
  const imageList = image_collage.map(img => img.stacked_image);

  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <Reveal className={styles.header}>
          {section_title && <h1>{section_title.text}</h1>}
        </Reveal>
        <div className={styles.blocks}>
          <div className={styles.blockCopy}>
            <Reveal className={styles.copy}>
              <div dangerouslySetInnerHTML={{ __html: section_text.html }} />
              {cta_text && cta_link && (
                <Button text={cta_text} url={`${cta_link.url}`} />
              )}
            </Reveal>
          </div>
          <div className={styles.blockImage}>
            <Reveal className={styles.images}>
              {imageList && (
                <ParallaxImages
                  images={imageList}
                  wideBackground={align_background}
                />
              )}
            </Reveal>
          </div>
          {divider && (
            <div className={styles.lineWrapper}>
              <AnimatedLine
                linePath={"M 0 0 Q 0 80, 80 80 Q 160 80, 160 160"}
                lineLength={160 * 1.6}
                height={162}
                width={162}
              />
            </div>
          )}
          {bottom_title && (
            <div className={styles.leadIn}>
              <Reveal className={styles.copy}>
                <p className={styles.callout}>{bottom_title.text}</p>
              </Reveal>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

IntroBlock.propTypes = {
  cta_link: PropTypes.object,
  cta_text: PropTypes.string,
  section_title: PropTypes.object,
  section_text: PropTypes.object,
  bottom_title: PropTypes.object,
  image_collage: PropTypes.array,
  wideBackground: PropTypes.string,
  divider: PropTypes.node,
};

IntroBlock.defaultProps = {
  cta_link: {},
  cta_text: "",
  section_title: {},
  section_text: {},
  bottom_title: {},
  image_collage: [],
  wideBackground: null,
  divider: null,
};

export default IntroBlock;
