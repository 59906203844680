import { navigate, PageProps } from "gatsby";
import React, { useMemo } from "react";

export type ProviderListFilters = {
  location: string;
  specialties: string[];
  modalities: string[];
  languages: string[];
  licenses: string[];
  insurances: string[];
  name: string;
  availability: string;
  dayOfWeek: string[];
  timeOfDay: string[];
};

export const ALL_LOCATIONS_VALUE = "all";
export const SEARCH_SPECIALTY_KEY = "specialty_tags";
export const SEARCH_MODALITY_KEY = "modality_tags";
export const SEARCH_LANGUAGE_KEY = "languages";
export const SEARCH_LICENSE_KEY = "licenses";
export const SEARCH_INSURANCE_KEY = "insurance";
export const SEARCH_NAME_KEY = "name";
export const SEARCH_AVAILABILITY_KEY = "available";
export const SEARCH_DAY_OF_WEEK_KEY = "day_of_week";
export const SEARCH_TIME_OF_DAY_KEY = "time_of_day";
export const ALL_DROPDOWN_MOBILE_FILTERS_KEY = "allDropdownMobileFilters";

type SecondaryFilterName =
  | "specialty_tags"
  | "modality_tags"
  | "languages"
  | "licenses"
  | "insurance"
  | "name"
  | "available"
  | "day_of_week"
  | "time_of_day";

function parseFiltersFromUrl(
  pathname: string,
  search: string
): ProviderListFilters {
  const urlParams = new URLSearchParams(search);
  const location = pathname.split("/")[2] || ALL_LOCATIONS_VALUE;
  const specialties = urlParams.get(SEARCH_SPECIALTY_KEY)?.split(" ") || [];
  const modalities = urlParams.get(SEARCH_MODALITY_KEY)?.split(" ") || [];
  const languages = urlParams.get(SEARCH_LANGUAGE_KEY)?.split(" ") || [];
  const licenses = urlParams.get(SEARCH_LICENSE_KEY)?.split(" ") || [];
  const insurances = urlParams.get(SEARCH_INSURANCE_KEY)?.split(" ") || [];
  const name = urlParams.get(SEARCH_NAME_KEY) || "";
  const availability = urlParams.get(SEARCH_AVAILABILITY_KEY) || "";
  const dayOfWeek = urlParams.get(SEARCH_DAY_OF_WEEK_KEY)?.split(" ") || [];
  const timeOfDay = urlParams.get(SEARCH_TIME_OF_DAY_KEY)?.split(" ") || [];

  //if some option of the availability dropdown is selected, the availability toggle is set to true
  const newAvailability =
    availability ||
    (dayOfWeek.length > 0 || timeOfDay.length > 0 ? "true" : "");

  return {
    location,
    specialties,
    modalities,
    languages,
    licenses,
    insurances,
    name,
    availability: newAvailability,
    dayOfWeek,
    timeOfDay,
  };
}

export const useProviderListFilters = (location: PageProps["location"]) => {
  const filters = useMemo(
    () => parseFiltersFromUrl(location.pathname, location.search),
    [location.pathname, location.search]
  );

  const currentSearchParams = new URLSearchParams(location.search);

  const setFilters = (newFilters: ProviderListFilters) => {
    let pathname = "/team";

    if (newFilters.location !== ALL_LOCATIONS_VALUE) {
      pathname = `${pathname}/${newFilters.location}`;
    }

    const search = Object.entries({
      ...Object.fromEntries(currentSearchParams.entries()),
      [SEARCH_SPECIALTY_KEY]: newFilters.specialties.join("+"),
      [SEARCH_MODALITY_KEY]: newFilters.modalities.join("+"),
      [SEARCH_LANGUAGE_KEY]: newFilters.languages.join("+"),
      [SEARCH_LICENSE_KEY]: newFilters.licenses.join("+"),
      [SEARCH_INSURANCE_KEY]: newFilters.insurances.join("+"),
      [SEARCH_NAME_KEY]: newFilters.name,
      [SEARCH_AVAILABILITY_KEY]: newFilters.availability,
      [SEARCH_DAY_OF_WEEK_KEY]: newFilters.dayOfWeek.join("+"),
      [SEARCH_TIME_OF_DAY_KEY]: newFilters.timeOfDay.join("+"),
    })
      .filter(([, value]) => value.length > 0)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");

    navigate(`${pathname}?${search}`);
  };

  const setLocation = (location: string | null) =>
    setFilters({ ...filters, location });

  const setSpecialties = (specialties: string[]) =>
    setFilters({ ...filters, specialties });

  const toggleSpecialty = (specialty: string) => {
    const newSpecialties = filters.specialties.includes(specialty)
      ? filters.specialties.filter(s => s !== specialty)
      : [...filters.specialties, specialty];

    setSpecialties(newSpecialties);
  };

  const setModalities = (modalities: string[]) =>
    setFilters({ ...filters, modalities });

  const toggleModality = (modality: string) => {
    const newModalities = filters.modalities.includes(modality)
      ? filters.modalities.filter(s => s !== modality)
      : [...filters.modalities, modality];

    setModalities(newModalities);
  };

  const setLanguages = (languages: string[]) =>
    setFilters({ ...filters, languages });

  const toggleLanguage = (language: string) => {
    const newLanguages = filters.languages.includes(language)
      ? filters.languages.filter(l => l !== language)
      : [...filters.languages, language];

    setLanguages(newLanguages);
  };

  const setLicenses = (licenses: string[]) =>
    setFilters({ ...filters, licenses });

  const toggleLicense = (license: string) => {
    const newLicenses = filters.licenses.includes(license)
      ? filters.licenses.filter(l => l !== license)
      : [...filters.licenses, license];

    setLicenses(newLicenses);
  };

  const setInsurances = (insurances: string[]) =>
    setFilters({ ...filters, insurances });

  const toggleInsurance = (insurance: string) => {
    const newInsurances = filters.insurances.includes(insurance)
      ? filters.insurances.filter(i => i !== insurance)
      : [...filters.insurances, insurance];

    setInsurances(newInsurances);
  };

  const setSearchByName = (currentNameSearch: string) => {
    setFilters({ ...filters, name: currentNameSearch });
  };

  const setAvailability = (availability: string) => {
    setFilters({ ...filters, availability });
  };

  const setDayOfWeek = (dayOfWeek: string[]) => {
    setFilters({ ...filters, dayOfWeek });
  };

  const toggleDayOfWeek = (day: string) => {
    const newDayOfWeek = filters.dayOfWeek.includes(day)
      ? filters.dayOfWeek.filter(d => d !== day)
      : [...filters.dayOfWeek, day];

    setDayOfWeek(newDayOfWeek);
  };

  const setTimeOfDay = (timeOfDay: string[]) => {
    setFilters({ ...filters, timeOfDay });
  };

  const toggleTimeOfDay = (time: string) => {
    const newTimeOfDay = filters.timeOfDay.includes(time)
      ? filters.timeOfDay.filter(t => t !== time)
      : [...filters.timeOfDay, time];

    setTimeOfDay(newTimeOfDay);
  };

  const clearFilter = (filterType: SecondaryFilterName) => {
    if (filterType === SEARCH_SPECIALTY_KEY) {
      filters.specialties = [];
      setSpecialties([]);
    } else if (filterType === SEARCH_MODALITY_KEY) {
      filters.modalities = [];
      setModalities([]);
    } else if (filterType === SEARCH_LANGUAGE_KEY) {
      filters.languages = [];
      setLanguages([]);
    } else if (filterType === SEARCH_LICENSE_KEY) {
      filters.licenses = [];
      setLicenses([]);
    } else if (filterType === SEARCH_INSURANCE_KEY) {
      filters.insurances = [];
      setInsurances([]);
    } else if (filterType === SEARCH_NAME_KEY) {
      filters.name = "";
      setSearchByName("");
    } else if (filterType === SEARCH_AVAILABILITY_KEY) {
      filters.availability = "";
      setAvailability("");
    } else if (filterType === SEARCH_DAY_OF_WEEK_KEY) {
      filters.dayOfWeek = [];
      setDayOfWeek([]);
    } else if (filterType === SEARCH_TIME_OF_DAY_KEY) {
      filters.timeOfDay = [];
      setTimeOfDay([]);
    } else if (filterType === ALL_DROPDOWN_MOBILE_FILTERS_KEY) {
      filters.specialties = [];
      filters.modalities = [];
      filters.languages = [];
      filters.licenses = [];
      filters.insurances = [];
      filters.dayOfWeek = [];
      filters.timeOfDay = [];
      setSpecialties([]);
      setModalities([]);
      setLanguages([]);
      setLicenses([]);
      setInsurances([]);
      setDayOfWeek([]);
      setTimeOfDay([]);
    }
  };

  return [
    filters,
    setFilters,
    {
      setLocation,
      setSpecialties,
      toggleSpecialty,
      setModalities,
      toggleModality,
      setLanguages,
      toggleLanguage,
      setLicenses,
      toggleLicense,
      setInsurances,
      toggleInsurance,
      clearFilter,
      setSearchByName,
      setAvailability,
      setDayOfWeek,
      toggleDayOfWeek,
      setTimeOfDay,
      toggleTimeOfDay,
    },
  ] as const;
};
