import React, { createRef, useEffect, useRef, useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import { FocusOn } from "react-focus-on";
import LogoImg from "../../images/octave-logo.inline.svg";
import Banner from "../Banner/Banner";
import OutdatedBrowser from "../OutdatedBrowser/OutdatedBrowser";
import {
  defaultBannerHeight,
  initialLayoutPaddingTop,
  SCHEDULE_CONSULT_URL,
  MIXPANEL_LINK_CLICK_EVENT_NAME
} from "../../lib/constants";
import { useCurrentProviderContext } from "../../lib/hooks/useCurrentProviderContext";
import { recursiveMenuRendering } from "./menuRenderHelpers";
import { useHeaderData } from "./useHeaderData";
import styles from "./header.module.scss";
import { combineQueryString } from "../../prismic/util";
import { useMixpanel } from "../../lib/mixpanel/tracking";

const Header = ({
  hasBanner,
  bannerData,
  setLayoutPaddingTop,
  showBookNowButton,
}) => {
  const headerData = useStaticQuery(QUERY).allPrismicHeader.edges[0].node.data;
  const NavLinksModel = useHeaderData(headerData);

  const mixpanel = useMixpanel();

  // If we're on a provider  profile page, we want to add the provider's
  // uid and NPI to the query string of the "Book Now" link.
  // We need to use a ref to the link element to do this, because there's
  // another hook that looks at the DOM to repopulate query params on the
  // target onboarding url.
  const { providerData } = useCurrentProviderContext();
  const bookNowElem = useRef();
  useEffect(() => {
    if (!showBookNowButton) return;

    const newBookNowLink = new URL(bookNowElem.current.href);
    if (providerData) {
      newBookNowLink.searchParams.set(
        "source",
        encodeURIComponent(providerData.uid)
      );
      newBookNowLink.searchParams.set("npi", providerData.npi);
    }
    bookNowElem.current.href = newBookNowLink.toString();
  }, [providerData, showBookNowButton]);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenuOpen = () => {
    setIsMobileMenuOpen(prev => !prev);
  };

  const menuBtnRef = useRef();
  const menuContainerRef = useRef(); // used to give ability for container to scroll when focus locked

  // used to help with react-focus-on when dropdown appears
  // to make dropdown btn tabbable when screen focus is on.
  const getDropdownRefs = () => {
    const newObj = {};

    // map out all the items that have dropdowns only
    const recursiveMap = (arr, index) => {
      if (index >= arr.length) return;

      arr.forEach(link => {
        if (link?.children) {
          recursiveMap(link.children, 0);
          newObj[link.id] = createRef();
        }
      });
    };
    recursiveMap(NavLinksModel, 0);

    return newObj;
  };
  const dropdownRefs = getDropdownRefs();

  const [dropdownState, setDropdownState] = useState(() => {
    const newObj = {};

    // map out all the items that have dropdowns only
    const recursiveMap = (arr, index) => {
      if (index >= arr.length) return;

      arr.forEach(link => {
        if (link?.children) {
          recursiveMap(link.children, 0);
          newObj[link.id] = false;
        }
      });
    };
    recursiveMap(NavLinksModel, 0);

    return newObj;
  });

  const handleDropdown = (item, overwriteData) =>
    setDropdownState(prev => ({
      ...prev,
      [item.id]: overwriteData ?? !prev[item.id],
    }));

  const [scrolled, setScrolled] = useState(false);
  const [offset, setOffset] = useState(0);
  // just stored the value, to avoid rerender, so should use ref instead of state
  const scrollActivated = useRef(false);
  const [bannerHeight, setBannerHeight] = useState(defaultBannerHeight);

  // const listSize = 3
  // TODO: need to check that how that block worked
  //   // Track client-side page changes, close header if open on modal
  //   Router.onRouteChangeComplete = () => {
  //     this.setState({ open: false })
  //     window.analytics && window.analytics.page()
  //   }

  //   // Set credit card variant for consult onboarding in cookies for a week to
  //   // prevent someone from dropping out of the flow by leaving the site
  //   if (variation) Cookies.set('variation', variation, { expires: 7 })
  // }

  useEffect(() => {
    const calculateHeaderOffset = yOffset => {
      if (hasBanner) {
        const offset = yOffset > bannerHeight ? bannerHeight : yOffset;
        if (yOffset > bannerHeight) {
          setLayoutPaddingTop(initialLayoutPaddingTop);
        } else {
          setLayoutPaddingTop(0);
        }
        setOffset(offset);
      }
    };

    const setNewBannerHeight = () => {
      if (document.getElementById("banner")) {
        const heightOfBanner = document
          .getElementById("banner")
          .getBoundingClientRect().height;
        setBannerHeight(heightOfBanner);
      }
    };

    const handleScroll = () => {
      let yOffset = 0;
      if (typeof window !== "undefined") {
        yOffset = window.pageYOffset;
      }
      if (yOffset > 640) {
        if (!scrollActivated.current) {
          scrollActivated.current = true;
          setScrolled(true);
        }
      } else {
        if (scrollActivated.current) {
          scrollActivated.current = false;
          setScrolled(false);
        }
      }
      calculateHeaderOffset(yOffset);
    };

    const onResize = () => {
      setNewBannerHeight();
      let yOffset = 0;
      if (typeof window !== "undefined") {
        yOffset = window.pageYOffset;
      }
      calculateHeaderOffset(yOffset);
    };

    setNewBannerHeight();
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", onResize);
    }
    return () => {
      scrollActivated.current = false;
      if (typeof window !== "undefined") {
        window.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", onResize);
      }
    };
  }, [hasBanner, bannerHeight, setLayoutPaddingTop]);

  const isHeaderFixed =
    (hasBanner && offset >= bannerHeight) ||
    !hasBanner ||
    (hasBanner && isMobileMenuOpen);

    const trackMixpanelNavLinkClick = (cta_link, cta_copy, category) => {
      mixpanel.track(MIXPANEL_LINK_CLICK_EVENT_NAME, {
        destination_url: cta_link,
        origin_url: window?.location.href,
        Section: "main nav",
        cta_copy: cta_copy,
        cta_type: "link",
        category: category,
      });
    };

    return (
      <>
        {hasBanner && !isMobileMenuOpen && (
          <Banner
            {...bannerData}
            hide_banner={hasBanner && offset >= bannerHeight}
          />
        )}
        <div
          className={`${styles.root} ${isMobileMenuOpen ? styles.open : ""} ${
            scrolled && !isMobileMenuOpen ? styles.scrolled : ""
          }`}
          style={{
            position: isHeaderFixed ? "fixed" : "unset",
            top: isHeaderFixed ? 0 : "unset",
          }}
        >
          <OutdatedBrowser />
          <div className={styles.logo}>
            <Link to="/">
              <LogoImg alt="Octave" />
            </Link>
          </div>
          {/* Desktop Menu */}
          <nav aria-label="Desktop Menu" className={styles.links}>
            {recursiveMenuRendering(
              NavLinksModel,
              dropdownState,
              handleDropdown,
              undefined,
              undefined,
              trackMixpanelNavLinkClick
            )}
          </nav>

          <div className={styles.logoCta}>
            {showBookNowButton && (
              <a
                ref={bookNowElem}
                href={SCHEDULE_CONSULT_URL}
                className={styles.ctaButton}
                onClick={() => {
                  mixpanel.track("Nav Button Clicked", {
                    destination_url: SCHEDULE_CONSULT_URL,
                    origin_url: window?.location.href,
                    Section: "main nav",
                    cta_copy: "Book now",
                    cta_type: "Button",
                    category: "Registration",
                  });
                }}
              >
                Book now
              </a>
            )}
          </div>

          {/* Mobile Menu */}
          <button
            className={styles.toggleIcon}
            aria-label="Toggle Icon"
            onClick={toggleMobileMenuOpen}
            ref={menuBtnRef}
            aria-expanded={isMobileMenuOpen}
            aria-controls="mobile-nav-main"
            aria-haspopup={true}
          >
            <div className={styles.toggleIconBarOne} />
            <div className={styles.toggleIconBarTwo} />
            <div className={styles.toggleIconBarThree} />
          </button>
          <FocusOn
            enabled={isMobileMenuOpen}
            onClickOutside={() => setIsMobileMenuOpen(false)}
            onEscapeKey={() => setIsMobileMenuOpen(false)}
            shards={[menuBtnRef, menuContainerRef]}
            className={styles.overlay}
            as="nav"
            aria-label="Mobile Menu"
            id="mobile-nav-main"
          >
            <div className={styles.overlayLinks} ref={menuContainerRef}>
              {recursiveMenuRendering(
                NavLinksModel,
                dropdownState,
                handleDropdown,
                dropdownRefs,
                true,
                trackMixpanelNavLinkClick,
                [menuContainerRef]
              )}
            </div>
          </FocusOn>
        </div>
      </>
    );
};

Header.propTypes = {
  hasBanner: PropTypes.bool,
  bannerData: PropTypes.object,
  setLayoutPaddingTop: PropTypes.func,
};

Header.defaultProps = {
  hasBanner: false,
  hideBookNowBtn: false,
  bannerData: null,
  setLayoutPaddingTop: () => {},
};

export default Header;

const QUERY = graphql`
  query Header {
    allPrismicHeader {
      edges {
        node {
          data {
            services_title
            services {
              label
              link {
                url
                target
              }
            }
            meet_the_team_title
            meet_the_team {
              label
              link {
                url
                uid
                target
                link_type
                type
              }
            }
            nav_links {
              label
              link {
                url
                uid
                target
                link_type
                type
              }
            }
            location_title
            all_locations_link {
              url
              target
            }
            locations {
              label
              link {
                uid
                link_type
                type
              }
            }
          }
        }
      }
    }
  }
`;
