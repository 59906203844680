import React from "react";
import PropTypes from "prop-types";
import styles from "./slide-indicators.module.scss";

const SlideIndicators = ({ currentSlide, count, updateSlide }) => (
  <div className={styles.root}>
    {[...Array(count).keys()].map(i => (
      <div
        className={`${styles.item} ${i === currentSlide ? styles.active : ""}`}
        key={i}
        role="button"
        tabIndex="0"
        aria-label="Update slide"
        onClick={() => {
          updateSlide(i);
        }}
        onKeyDown={() => {
          updateSlide(i);
        }}
      />
    ))}
  </div>
);

SlideIndicators.propTypes = {
  currentSlide: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  updateSlide: PropTypes.func.isRequired,
};

export default SlideIndicators;
