import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Reveal from "../Reveal/Reveal";
import TableSection from "../TableSection/TableSection";
import styles from "./table-simple.module.scss";

const TableSimple = props => {
  if (props.display_full_width) {
    return <TableSection {...props} />;
  }

  const { col_heading_1, col_heading_2, items } = props;

  const tableHeadings = [col_heading_1, col_heading_2];

  return (
    <div className={styles.root}>
      <Reveal className={styles.table}>
        {tableHeadings.map((th, index) => (
          <div className={`${styles.cell} ${styles.header}`} key={index}>
            <h3>{th.text}</h3>
          </div>
        ))}
        {items.map((row, itemIndex) => {
          return Object.entries(row).map((cell, rowIndex) => (
            <div className={styles.cell} key={`${itemIndex}-${rowIndex}`}>
              <p>{cell[1].text}</p>
            </div>
          ));
        })}
      </Reveal>
    </div>
  );
};

TableSimple.propTypes = {
  fields: PropTypes.array,
  primary: PropTypes.array,
};

TableSimple.defaultProps = {
  fields: [],
  primary: [],
};

export default TableSimple;

// Prismic "Simple Table" slice
export const fragment = graphql`
  fragment SliceSimpleTable on PrismicPageContainerDataBodySimpleTable {
    slice_type
    primary {
      table_title {
        text
      }
      display_full_width
      col_heading_1 {
        text
      }
      col_heading_2 {
        text
      }
    }
    items {
      col_1 {
        text
        html
      }
      col_2 {
        text
        html
      }
    }
  }
`;
