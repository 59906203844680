import React from "react";
import PropTypes from "prop-types";
import { graphql, Link, navigate } from "gatsby";
import Image from "../Image/Image";
import styles from "./grid-items.module.scss";

const GridItems = ({ items, section_title, section_intro }) => {
  const gridItems = items.map(
    ({ excerpt, link, link_label, thumbnail, title }, i) => {
      const mainContent = hasLink => (
        <>
          <Image imageObj={thumbnail} className={styles.image} />
          <div className={styles.copy}>
            <div
              dangerouslySetInnerHTML={{ __html: title.html }}
              className={styles.title}
            />
            <div
              dangerouslySetInnerHTML={{ __html: excerpt.html }}
              className={styles.excerpt}
            />
            {hasLink && (
              <span className={styles.link_container}>
                <Link to={link.url} className={styles.link}>
                  {link_label}
                </Link>
              </span>
            )}
          </div>
        </>
      );

      if (link?.url) {
        return (
          <button
            onClick={() => navigate(link.url)}
            className={styles.link_item}
            key={`gridItem-${i}`}
          >
            {mainContent(true)}
          </button>
        );
      } else {
        return (
          <div className={styles.no_link_item} key={`gridItem-${i}`}>
            {mainContent()}
          </div>
        );
      }
    }
  );
  return (
    <div className={styles.root}>
      <div className={styles.intro}>
        <div
          className={styles.intro_title}
          dangerouslySetInnerHTML={{ __html: section_title.html }}
        />
        <div
          className={styles.intro_copy}
          dangerouslySetInnerHTML={{ __html: section_intro.html }}
        />
      </div>
      <div className={styles.grid_items}>{gridItems}</div>
    </div>
  );
};

GridItems.propTypes = {
  items: PropTypes.array,
  section_title: PropTypes.object,
};

GridItems.defaultProps = {
  items: [],
  section_title: {},
};

export default GridItems;

// Prismic "Section Split" slice
export const fragment = graphql`
  fragment SliceLinkList on PrismicPageContainerDataBodySectionTwoColLinkList {
    slice_label
    slice_type
    primary {
      section_title {
        html
      }
      section_intro {
        html
      }
    }
    items {
      excerpt {
        html
        text
      }
      link {
        url
        uid
        slug
        link_type
      }
      link_label
      thumbnail {
        alt
        url
        gatsbyImageData(height: 600, layout: CONSTRAINED)
        localFile {
          childImageSharp {
            gatsbyImageData(height: 600, layout: CONSTRAINED)
          }
        }
      }
      title {
        html
      }
    }
  }
`;
