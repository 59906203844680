import { useState } from "react";

const useJobBoardFilter = () => {
  const [filter, setFilter] = useState("");

  const handleChange = value => {
    setFilter(value);
  };

  return { filter, handleChange };
};

export default useJobBoardFilter;
