import React from "react";
import { graphql } from "gatsby";
import AnimatedLine from "../AnimatedLine/AnimatedLine";
import SplitBlockDefault from "../SplitBlockDefault/SplitBlockDefault";
import SplitBlockIntro from "../SplitBlockIntro/SplitBlockIntro";
import SplitBlockLargeImage from "../SplitBlockLargeImage/SplitBlockLargeImage";

const SplitAnimatedLine = () => (
  <AnimatedLine
    linePath={
      "M1 1C10.5507 2.38298 30.964 11.9149 36.2111 38.9787C42.77 72.8085 81.088 76.9574 99.0387 67.7021C116.99 58.4468 101.11 30.3617 85.5757 43.4468C70.0414 56.5319 75.2195 91.6383 105.598 106"
    }
    lineLength={160 + 80 * 1.57}
    height={150}
    width={150}
  />
);

const SplitIntroAnimatedLine = () => (
  <AnimatedLine
    linePath={"M 0 0 Q 0 80, 80 80 Q 160 80, 160 160"}
    lineLength={160 * 1.6}
    height={162}
    width={162}
  />
);

const SplitBlock = props => {
  const { display_type, is_intro_block } = props;
  if (display_type === "Intro" || is_intro_block) {
    return <SplitBlockIntro {...props} divider={SplitIntroAnimatedLine()} />;
  } else if (display_type === "Large Image") {
    return <SplitBlockLargeImage {...props} divider={SplitAnimatedLine()} />;
  } else {
    return <SplitBlockDefault {...props} />;
  }
};

export default SplitBlock;

// Prismic "Section Split" slice
export const fragment = graphql`
  fragment SliceSplit on PrismicPageContainerDataBodySectionSplit {
    slice_type
    slice_label
    primary {
      section_title {
        text
      }
      section_text {
        html
      }
      extra_text {
        html
      }
      cta_text
      cta_link {
        url
      }
      section_footnote {
        html
      }
      bottom_title {
        text
      }
      footnote_icon
      display_type
      is_intro_block
      is_inverted
      align_background
      image_ratio
    }
    items {
      stacked_image {
        url
        gatsbyImageData(height: 800, layout: CONSTRAINED)
        localFile {
          childImageSharp {
            gatsbyImageData(height: 800, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;

export const locationTagSplitFragment = graphql`
  fragment LocationSliceSplit on PrismicStateLocationDataBodySectionSplit {
    slice_type
    slice_label
    primary {
      section_title {
        text
      }
      section_text {
        html
      }
      extra_text {
        html
      }
      cta_text
      cta_link {
        url
      }
      section_footnote {
        html
      }
      bottom_title {
        text
      }
      footnote_icon
      display_type
      is_intro_block
      is_inverted
      align_background
      image_ratio
    }
    items {
      stacked_image {
        url
        gatsbyImageData(height: 800, layout: CONSTRAINED)
        localFile {
          childImageSharp {
            gatsbyImageData(height: 800, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;
