import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import ItemsCarousel from "react-items-carousel";
import Button from "../Button/Button";
import Image from "../Image/Image";
import styles from "./carousel-interested-list.module.scss";

const CarouselInterestedList = ({ list, thumbnail }) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [numberOfCard, setNumberOfCard] = useState(3);
  const chevronWidth = 40;

  // TODO: need to get the real data from CMS
  const dummyList = [
    {
      id: "1",
      thumbnail: thumbnail,
      heading: "Balancing Emotional Eating In Times of Stress",
      subHeading: "Supportive Lifestyle",
      description:
        "<p>Imagine this… Your day is coming to an end and you begin to feel distressed. Your mind is no longer occupied with work and family responsibilities so your attention begins to drift...</p>",
      link: "/",
    },
    {
      id: "2",
      thumbnail: thumbnail,
      heading: "Actions to Take to Mitigate Stress on the Front Lines",
      subHeading: "Responding To Challenges",
      description:
        "<p>How is this stress different from the stress of the public? Sources of stress for the front line can take many different forms depending on the type of disaster. In the case of...</p>",
      link: "/",
    },
    {
      id: "3",
      thumbnail: thumbnail,
      heading: "Transition to All Virtual Services",
      subHeading: "Updates from Team Octave",
      description:
        "<p>We’re making the shift to virtual care in response to COVID-19 and also planning virtual groups and free and low-cost behavioral health services for those in need.</p>",
      link: "/",
    },
    {
      id: "4",
      thumbnail: thumbnail,
      heading: "Transition to All Virtual Services Dummy",
      subHeading: "Updates from Team Octave",
      description:
        "<p>We’re making the shift to virtual care in response to COVID-19 and also planning virtual groups and free and low-cost behavioral health services for those in need.</p>",
      link: "/",
    },
  ];

  const RightArrow = () => (
    <div className={styles.rightArrow}>
      <svg
        width="31"
        height="55"
        viewBox="0 0 31 55"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M2 53L27.5 27.5L2 2" stroke="#AF756D" strokeWidth="4" />
      </svg>
    </div>
  );

  const LeftArrow = () => (
    <div className={styles.leftArrow}>
      <svg
        width="30"
        height="55"
        viewBox="0 0 30 55"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.3"
          d="M28.5 2L3 27.5L28.5 53"
          stroke="#AF756D"
          strokeWidth="4"
        />
      </svg>
    </div>
  );

  const handleNumberofCard = useCallback(() => {
    const screenWidth = window.innerWidth;
    if (screenWidth > 1024) {
      setNumberOfCard(3);
    } else if (screenWidth > 768) {
      setNumberOfCard(2);
    } else {
      setNumberOfCard(1);
    }
  }, []);

  const onResize = useCallback(() => {
    handleNumberofCard();
  }, [handleNumberofCard]);

  useEffect(() => {
    handleNumberofCard();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [handleNumberofCard, onResize]);

  return (
    <div className={styles.root} style={{ display: "none" }}>
      <h2 className={styles.heading}>You might be interested in</h2>
      <div className={styles.list}>
        <ItemsCarousel
          showSlither={numberOfCard <= 2}
          requestToChangeActive={setActiveItemIndex}
          activeItemIndex={activeItemIndex}
          numberOfCards={numberOfCard}
          gutter={24}
          leftChevron={LeftArrow()}
          rightChevron={RightArrow()}
          outsideChevron
          chevronWidth={chevronWidth}
        >
          {dummyList.map(item => {
            const { id, thumbnail, heading, subHeading, description, link } =
              item;
            return (
              <div className={styles.cardContainer} key={id}>
                <div className={styles.cardThumbnail}>
                  <Image imageObj={thumbnail} />
                </div>
                <div className={styles.cardContent}>
                  <h3 className={styles.cardHeading}>{heading}</h3>
                  <span className={styles.cardSubHeading}>{subHeading}</span>
                  <div
                    className={styles.cardDescription}
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                  <div className={styles.ctaBtn}>
                    <Button url={link} text="Read more"></Button>
                  </div>
                </div>
              </div>
            );
          })}
        </ItemsCarousel>
      </div>
    </div>
  );
};

export default CarouselInterestedList;

CarouselInterestedList.propTypes = {
  list: PropTypes.array,
};
