import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import styles from "./banner.module.scss";

const Banner = ({
  background_color,
  banner_text,
  cta_text,
  cta_link,
  hide_banner,
}) => {
  const bgColorClass = () => {
    switch (background_color) {
      case "Green":
        return "banner_green";
      default:
        return "banner_rose";
    }
  };

  return (
    <>
      {banner_text && (
        <div
          className={`${styles.root} ${styles[bgColorClass()]} ${
            hide_banner ? styles.hide_banner : ""
          }`}
          id="banner"
        >
          <div
            className={styles.copy}
            dangerouslySetInnerHTML={{ __html: banner_text.html }}
          />
          <div className={styles.cta_container}>
            <div className={styles.line} />
            {cta_text && (
              <div className={styles.cta}>
                {cta_link.link_type === "Web" ? (
                  <a href={cta_link.url}>{cta_text}</a>
                ) : (
                  <Link to={`/${cta_link.uid}`}>{cta_text}</Link>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

Banner.propTypes = {
  banner_text: PropTypes.object.isRequired,
  cta_text: PropTypes.string,
  cta_link: PropTypes.object,
  hide_banner: PropTypes.bool,
};

export default Banner;
