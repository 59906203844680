import React from "react";
import PropTypes from "prop-types";
import Reveal from "../Reveal/Reveal";
import styles from "./topics-lists.module.scss";

const TopicSection = ({ question, answer, topic, index }) => {
  let anchorId = "";
  if (topic.primary?.topic?.text) {
    anchorId = `${topic.primary.topic.text
      .replace(" ", "_")
      .toLowerCase()}-${index}`;
  }

  return (
    <Reveal className={styles.section}>
      {question && (
        <dt className={styles.header} id={anchorId}>
          {question.text}
        </dt>
      )}
      {answer && (
        <dd>
          <div dangerouslySetInnerHTML={{ __html: answer.html }} />
        </dd>
      )}
    </Reveal>
  );
};

TopicSection.propTypes = {
  question: PropTypes.object,
  answer: PropTypes.object,
  topic: PropTypes.object,
  index: PropTypes.number,
};

TopicSection.defaultProps = {
  question: null,
  answer: null,
  topic: null,
  index: null,
};

export default TopicSection;
